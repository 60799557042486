import { SDK_API } from '@/configs/APIs'
const EVENT_CHANNEL_APIS = SDK_API.EVENT_CHANNEL

export default ($axios) => ({
	/**
	 * Get current event channel
	 */
	async current() {
		const {
			data: { code, data, message },
		} = await $axios.get(EVENT_CHANNEL_APIS.CURRENT)
		return { code, data, message }
	},
	/**
	 * Get detail event channel
	 * @param {*} id
	 */
	async detail(id) {
		const {
			data: { code, data, message },
		} = await $axios.get(`${EVENT_CHANNEL_APIS.DETAIL}/${id}`)
		return { code, data, message }
	},

	/**
	 * Buy channel ticket
	 * @param {*} param
	 */
	async buyTicket({ channelId }) {
		const {
			data: { code, data, message },
		} = await $axios.post(EVENT_CHANNEL_APIS.BUY_TICKET, {
			channelId,
		})
		return { code, data, message }
	},
})
