/* eslint-disable no-unused-vars */
import Vue from "vue";

Vue.directive("scroll-infinite", {
   bind: function(el, binding) {
      el.onScrollEvent = (event) => {
         let scrollGutter = parseFloat(binding.arg) || 20;
        if(el.scrollTop + el.clientHeight >= el.scrollHeight - scrollGutter) {
            if (typeof binding.value === "function") {
                binding.value(el);
            }
        }
      };
      el.addEventListener("scroll", el.onScrollEvent);
   },
   unbind: function(el) {
      el.removeEventListener("scroll", el.onScrollEvent);
   }
});
