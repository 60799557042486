<template>
	<div :key="keyRender" class="body-wrapper">
		<Nuxt />
		<Footer />
		<TransactionMessageModal id="trans-msg" />
	</div>
</template>

<script>
import Footer from '@/components/footer'
import TransactionMessageModal from '@/components/modals/TransactionMessageModal'
import LayoutMixins from '@/mixins/LayoutMixins'

export default {
	components: {
		Footer,
		TransactionMessageModal,
	},
	mixins: [LayoutMixins],
	middleware: ['checkIsMaintenance', 'auth'],
	data() {
		return {
			keyRender: new Date().getTime(),
		}
	},
}
</script>
<style lang="scss" scoped>
.body-wrapper {
	overflow: hidden;
}
</style>
