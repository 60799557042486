import {RTM_CONFIGS} from "@/configs/RTM_SDK";
import {STATUS_RESPONSES} from "@/configs/SDK";

export const state = () => ({
    listLikedAnimations: [],
    listRenderAnimations: [],
    currentIndexRender: -1,
    intervalUpdateRenderId: null,
    keyAnswer: null,
    keyQuestion: null,
    userChat: null,
    inLivePage: false,
    naughtyWords: [],
    listGiftReceived: [],
    listComboReceived: []
});

export const getters = {
    listLikedAnimations(state) {
        return state.listLikedAnimations;
    },
    listRenderAnimations(state) {
        return state.listRenderAnimations;
    },
    keyAnswer(state) {
        return state.keyAnswer;
    },
    keyQuestion(state) {
        return state.keyQuestion;
    },
    userChat(state) {
        return state.userChat;
    },
    naughtyWords(state) {
        return state.naughtyWords;
    },
    listGiftReceived(state) {
        return state.listGiftReceived;
    },
    listComboReceived(state) {
        return state.listComboReceived;
    },
    inLivePage(state) {
        return state.inLivePage
    }
};

export const mutations = {
    UPDATE_LIST_LIKED_ANIMATIONS(state, listLikedAnimations) {
        state.listLikedAnimations = listLikedAnimations;
        if (!state.listLikedAnimations.length && state.intervalUpdateRenderId) {
            clearInterval(state.intervalUpdateRenderId);
            state.intervalUpdateRenderId = null;
        }
    },

    ADD_NEW_LIKED_ANIMATION(state, likedAnimation) {
        if (state.listLikedAnimations.length < RTM_CONFIGS.MAXIMUM_RECEIVED_LIKES) {
            state.listLikedAnimations.push(likedAnimation);
        }

        if (!state.intervalUpdateRenderId) {
            state.intervalUpdateRenderId = setInterval(() => {
                if (state.listLikedAnimations[state.currentIndexRender + 1]) {
                    state.currentIndexRender++;
                    state.listRenderAnimations.push(state.listLikedAnimations[state.currentIndexRender]);
                }
            }, RTM_CONFIGS.DELAY_ANIMATION_LIKE)
        }
    },

    REMOVE_FIRST_LIKED_ANIMATION(state) {
        state.listLikedAnimations.splice(0, 1);
        state.listRenderAnimations.splice(0, 1);
        state.currentIndexRender--;
        if (!state.listLikedAnimations.length && state.intervalUpdateRenderId) {
            clearInterval(state.intervalUpdateRenderId);
            state.intervalUpdateRenderId = null;
        }
    },

    REQUEST_NEW_ANIMATION(state) {
        state.listRenderAnimations = state.listLikedAnimations.splice(0, 1);
    },

    UPDATE_KEY_QUESTION(state, keyQuestion) {
        state.keyQuestion = keyQuestion;
    },

    UPDATE_KEY_ANSWER(state, keyAnswer) {
        state.keyAnswer = keyAnswer;
    },

    UPDATE_USER_CHAT(state, userChat) {
        state.userChat = userChat;
    },

    UPDATE_NAUGHTY_WORDS(state, naughtyWords) {
        state.naughtyWords = [...naughtyWords];
    },

    ADD_NEW_GIFT_RECEIVED(state, giftReceived) {
        state.listGiftReceived.push(giftReceived);
    },

    ADD_NEW_COMBO_RECEIVED(state, comboReceived) {
        state.listComboReceived.push(comboReceived);
    },

    REMOVE_GIFT_RECEIVED(state, index = 0) {
        state.listGiftReceived = state.listGiftReceived.splice(index, 1);
    },

    REMOVE_COMBO_RECEIVED(state, index = 0) {
        state.listComboReceived = state.listComboReceived.splice(index, 1);
    },

    UPDATE_COMBO_RECEIVED(state, comboReceived) {
        state.listComboReceived = {...state.listGiftReceived, ...comboReceived};
    },

    UPDATE_IN_LIVE_PAGE(state, inLivePage) {
        state.inLivePage = inLivePage
    }
};

export const actions = {
    updateListLikedAnimations: ({ commit }, listLikedAnimations) => {
        commit("UPDATE_LIST_LIKED_ANIMATIONS", listLikedAnimations);
    },

    addNewLikedAnimation: ({ commit }, likedAnimation) => {
        commit("ADD_NEW_LIKED_ANIMATION", likedAnimation);
    },

    removeFirstLikeAnimation: ({ commit }) => {
        commit("REMOVE_FIRST_LIKED_ANIMATION");
    },

    requestNewAnimation: ({ commit }) => {
       commit("REQUEST_NEW_ANIMATION");
    },

    updateKeyQuestion: ({commit}, keyQuestion) => {
        commit("UPDATE_KEY_QUESTION", keyQuestion);
    },

    updateKeyAnswer: ({commit}, keyAnswer) => {
        commit("UPDATE_KEY_ANSWER", keyAnswer);
    },

    updateUserChat: ({ commit }, userChat) => {
        commit("UPDATE_USER_CHAT", userChat);
    },

    updateNaughtyWords: ({ commit }, naughtyWords) => {
        commit("UPDATE_NAUGHTY_WORDS", naughtyWords)
    },

    async getNaughtyWords({commit}) {
        const { code, data } = await this.$httpResources.repositories.channel.getNaughtyWords();
        if (code === STATUS_RESPONSES.SUCCESS) {
            commit("UPDATE_NAUGHTY_WORDS", data);
        }
    },
    
    addNewGiftReceived: ({ commit }, giftReceived) => {
        commit("ADD_NEW_GIFT_RECEIVED", giftReceived)
    },
    addNewComboReceived: ({ commit }, comboReceived) => {
        commit("ADD_NEW_COMBO_RECEIVED", comboReceived)
    },
    removeGiftReceived: ({ commit }, index = 0) => {
        commit("REMOVE_GIFT_RECEIVED", index)
    },
    removeComboReceived: ({ commit }, index = 0) => {
        commit("REMOVE_COMBO_RECEIVED", index)
    },
    updateComboReceived: ({ commit }, comboReceived) => {
        commit("UPDATE_COMBO_RECEIVED", comboReceived)
    },
    updateInLivePage: ({ commit }, inLivePage) => {
        commit("UPDATE_IN_LIVE_PAGE", inLivePage)
    }
};