import { SDK_API } from '@/configs/APIs'
const PAGE_APIS = SDK_API.PAGES

export default ($axios) => ({
  /**
   * Get data for landing page
   * @param {*} blockIndex
   */
  async getHome(blockIndex = 1) {
    const {
      data: { code, data, message },
    } = await $axios.post(PAGE_APIS.HOME, {
      blockIndex,
    })
    return { code, data, message }
  },
})
