import { SDK_API } from '@/configs/APIs'
const STREAM_APIS = SDK_API.STREAM

export default ($axios) => ({
	/**
	 * Get stream detail
	 * @param {*} slug
	 */
	async getDetail(slug) {
		const {
			data: { code, data, message },
		} = await $axios.post(STREAM_APIS.DETAIL, {
			slug,
		})
		return { code, data, message }
	},

	/**
	 * Like stream
	 * @param {*} param
	 */
	async like({ slug, count }) {
		const {
			data: { code, data, message },
		} = await $axios.post(STREAM_APIS.LIKE, {
			slug,
			count,
		})
		return { code, data, message }
	},

	/**
	 * Get statistic
	 * @param {*} slug
	 */
	async getStatistic(slug) {
		const {
			data: { code, data, message },
		} = await $axios.post(STREAM_APIS.STATISTIC, {
			slug,
		})
		return { code, data, message }
	},

	/**
	 * Get pinned message
	 * @param {*} slug
	 */
	async getPinnedMessage(slug) {
		const {
			data: { code, data, message },
		} = await $axios.post(STREAM_APIS.PINNED_MESSAGE, {
			slug,
		})
		return { code, data, message }
	},

	/**
	 * Get products in stream
	 * @param {*} slug
	 * @param nextId
	 * @param limit
	 */
	async getProducts(slug, nextId, limit) {
		const {
			data: { code, data, message },
		} = await $axios.post(STREAM_APIS.PRODUCTS, {
			slug,
			nextId,
			limit,
		})
		return { code, data, message }
	},

	/**
	 * Leave stream
	 * @param {*} param
	 */
	async leave({ streamUserId, slug }) {
		const {
			data: { code, data, message },
		} = await $axios.post(STREAM_APIS.LEAVE, {
			slug,
			streamUserId,
		})
		return { code, data, message }
	},

	/**
	 * Get cart info
	 */
	async getCartInfo({ hShopToken }) {
		const {
			data: { code, data, message },
		} = await $axios.get(STREAM_APIS.CART, { params: { hShopToken } })
		return { code, data, message }
	},

	/**
	 * Get leaderboard ecommerce
	 */
	async getLeaderboardEcommerce() {
		const {
			data: { code, data, message },
		} = await $axios.post(STREAM_APIS.LEADER_BOARD_ECOMMERCE, {})
		return { code, data, message }
	},
})
