<template>
	<div
		v-click-outside="handleCloseMenu"
		class="select"
		@click="handleOpenMenu"
	>
		<BInputWithValidation
			v-model="getSelectedText"
			mode="lazy"
			:vid="vid"
			:name="name"
			:label="label"
			:rules="rules"
			:placeholder="placeholder"
			:class="inputClass"
			readonly
		/>
		<div v-if="showMenu" class="select__angle"></div>
		<transition name="slideDown">
			<ul v-if="showMenu" class="select__menu">
				<li>
					<input
						ref="search"
						:value="inputSearch"
						:placeholder="$t('textActions.Search')"
						class="form-control"
						@input="inputSearch = $event.target.value"
					/>
				</li>
				<li v-if="getDataSource.length === 0" class="select__no-data">
					{{ $t('errorMessages.Data not found!') }}
				</li>
				<li
					v-for="(item, index) in getDataSource"
					:key="`select-${new Date().getTime()}-${index}`"
					@click="handleSelected(item)"
				>
					<slot v-if="hasTemplate" name="item" :data="item"></slot>
					<span v-else>{{ item[dataText] }}</span>
				</li>
			</ul>
		</transition>
	</div>
</template>

<script>
import BInputWithValidation from '@/components/BInputWithValidation'
export default {
	name: 'Select',
	components: { BInputWithValidation },
	props: {
		vid: {
			type: String,
		},
		rules: {
			type: String,
		},
		name: {
			type: String,
		},
		placeholder: {
			type: String,
		},
		dataSource: {
			type: Array,
		},
		dataText: {
			type: String,
		},
		dataValue: {
			type: String,
		},
		value: {
			type: [String, Number, Object],
			default: null,
		},
		hasTemplate: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: '',
		},
		inputClass: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		showMenu: false,
		selected: null,
		inputSearch: '',
	}),
	computed: {
		getDataSource() {
			return (
				this.dataSource?.filter(
					(x) =>
						x[this.dataText]
							?.trim()
							?.toLowerCase()
							.indexOf(
								this.inputSearch?.trim()?.toLowerCase()
							) === 0
				) || []
			)
		},
		getSelectedText() {
			if (!this.selected) return ''
			return this.selected[this.dataText] || ''
		},
	},
	watch: {
		selected(val) {
			this.$emit('input', val)
		},
		value(val) {
			this.selected = val || null
		},
	},
	mounted() {
		this.selected = this.value || null
	},
	methods: {
		handleOpenMenu() {
			this.showMenu = true
			this.$nextTick(() => {
				this.$refs.search.focus()
			})
		},
		handleCloseMenu() {
			this.showMenu = false
			this.inputSearch = ''
		},
		handleBlurInput() {},
		handleSelected(item) {
			this.selected = item
			this.handleCloseMenu()
		},
	},
}
</script>

<style lang="scss" scoped>
.select {
	position: relative;
	z-index: 1;

	&__input {
		&:read-only {
			background-color: $color-white;
		}
	}

	&__no-data {
		display: flex;
		justify-content: center;
		color: $color-grey-light-3 !important;
		font-weight: 300 !important;
	}

	&__angle {
		position: absolute;
		border-left: 1rem solid transparent;
		border-right: 1rem solid transparent;
		border-bottom: 1.2rem solid $color-white;
		top: 85%;
		right: 1rem;
		z-index: 3;
	}

	ul {
		list-style: none;
		margin-bottom: 0;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
		background-color: $color-white;
		position: absolute;
		left: 0;
		top: 100%;
		z-index: 2;
		border-radius: 10px;
		width: 100%;
		margin-top: 0.5rem;
		padding: 0.5rem 0;
		max-height: 24rem;
		min-height: 24rem;
		overflow-y: auto;

		li {
			cursor: pointer;
			font-family: $font-secondary;
			font-size: 1.3rem;
			font-weight: 300;
			line-height: 1.77;
			color: $color-grey-dark-4;

			&:first-child {
				padding: 0.5rem 0.5rem 0.5rem 1rem;
			}

			&:not(:first-child) {
				padding-left: 1.5rem;
				&:hover {
					background-color: rgba(74, 74, 74, 0.1);
				}
			}
		}
	}
}

@keyframes slideDown {
	from {
		transform: translateY(-15%);
	}

	to {
		transform: translateY(0%);
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.slideDown-enter-active {
	animation: slideDown 0.2s ease;
}

.slideDown-leave-active {
	animation: fadeOut 0.2s ease;
}
</style>
