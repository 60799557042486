<template>
	<div>
		<nuxt />
	</div>
</template>
<script>
import LayoutMixins from '@/mixins/LayoutMixins'

export default {
	name: "SimpleLayout",
	mixins: [LayoutMixins],
	middleware: ['checkIsMaintenance'],
}
</script>
