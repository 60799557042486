import createRepository from '@/repositories/RepositoryFactory'
import { SDK_API } from '@/configs/APIs'
import { DEFAULT_VALUES, STATUS_RESPONSES, IGNORE_ERROR_NOTIFICATION } from '~/configs/SDK'
import { COOKIE_KEYS } from '~/configs/Settings'
import { STATUS_MESSAGES, SYSTEM_MESSAGES } from '~/messages/System'
const AUTH_APIS = SDK_API.AUTH

export default function (
	{ $axios, $notification, $cookies, error: nuxtError, env, app, redirect },
	inject
) {
	// Create a custom axios instance
	const api = $axios.create({
		headers: {
			common: {
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers': '*',
				'Accept-Language': '*',
				'Content-Type': 'application/json',
			},
		},
	})

	api.setBaseURL(`${env.baseAPI}`)
	if (app.store.getters.country) {
		api.setHeader(
			'location',
			app.store.getters.country || DEFAULT_VALUES.LOCATION
		)
	}
	const langId =
		app.i18n.locales.find((lang) => lang.code === app.i18n.locale)?.id || 0
	api.setHeader('language', langId)

	const user = app.store.getters.user
	if (user?.accessToken) {
		api.setToken(user.accessToken, 'Bearer')
	}
	// inject data service into axios
	const resourceRepositories = createRepository(api)
	api.repositories = resourceRepositories

	// Set hypershop access token
	const hShopAccessToken = $cookies.get(COOKIE_KEYS.HYPERSHOP_ACCESS_TOKEN)
	api.setHeader('hShopAccessToken', hShopAccessToken)

	// Handling error on client request
	if (process.client) {
		let isHandlingUserLogged = false
		api.onResponse((response) => {
			if (response.status === 200) {
				const isIgnore = IGNORE_ERROR_NOTIFICATION.includes(response.data.code)
				if (
					response.data?.code &&
					response.data.code === STATUS_RESPONSES.ACCOUNT_DEACTIVE &&
					!isIgnore
				) {
					$cookies.remove(COOKIE_KEYS.ACCESS_TOKEN)
					$cookies.remove(COOKIE_KEYS.REFRESH_TOKEN)
					$notification.e(
						response.data.message ||
							SYSTEM_MESSAGES.ACCOUNT_DEACTIVE,
						STATUS_MESSAGES.ERROR
					)
					if (response.config.url !== AUTH_APIS.LOGIN) {
						if (process.browser) {
							window.open('/', '_self')
						}
					}
				} else if (
					response.data?.code &&
					(response.data.code === STATUS_RESPONSES.USER_NOT_VERIFY ||
						response.data.code ===
							STATUS_RESPONSES.VERIFICATION_CODE_EXPIRED) &&
					!isIgnore
				) {
					$notification.e(
						app.i18n.t(
							`errorMessages.${
								response.data?.message || response.data?.Message
							}`
						),
						STATUS_MESSAGES.ERROR
					)
				} else if (
					response.data?.code &&
					response.data.code !== STATUS_RESPONSES.SUCCESS &&
					!isIgnore
				) {
					$notification.e(
						response.data?.message ||
							response.data?.Message ||
							STATUS_MESSAGES.ERROR,
						STATUS_MESSAGES.ERROR
					)
					if (
						!isHandlingUserLogged &&
						response.data.code ===
							STATUS_RESPONSES.USER_LOGGED_ANOTHER_DEVICE
					) {
						isHandlingUserLogged = true
						$cookies.remove(COOKIE_KEYS.ACCESS_TOKEN)
						$cookies.remove(COOKIE_KEYS.REFRESH_TOKEN)
						if (process.browser) {
							window?.location?.reload()
						}
					}
				}
				return response
			} else {
				$notification.e(
					response.data?.message ||
						response.data?.Message ||
						STATUS_MESSAGES.ERROR,
					STATUS_MESSAGES.ERROR
				)
				return null
			}
		})
	}

	api.onError((error) => {
		if (error.response) {
			nuxtError({
				statusCode: error.response.status,
				message: SYSTEM_MESSAGES.ERROR
			})
		} else {
			nuxtError({
				statusCode: error.status,
				message: SYSTEM_MESSAGES.ERROR
			})
		}

		return Promise.resolve(false)
	})

	// Inject to context as $http
	inject('httpResources', api)
}
