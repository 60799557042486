import { SDK_API } from '@/configs/APIs'
const COMMON_APIS = SDK_API.COMMON

export default () => ({
	async checkMaintenance(maintenanceUrl) {
		if (maintenanceUrl) {
			const res = await fetch(
				`${maintenanceUrl}?timestamp=${new Date().getTime()}`
			)
			const data = await res.json()
			return data
		}
		throw new Error('SETTING API NOT AVAILABLE')
	},
	async getProjectSettings() {
		if (COMMON_APIS.PROJECTS_SETTINGS) {
			const res = await fetch(
				`${
					COMMON_APIS.PROJECTS_SETTINGS
				}?timestamp=${new Date().getTime()}`
			)
			const data = await res.json()
			return data
		}
		throw new Error('SETTING API NOT AVAILABLE')
	},
})
