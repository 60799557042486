import Vue from 'vue'
import VueToastr from 'vue-toastr'

Vue.use(VueToastr, {
	/* OverWrite Plugin Options if you need */
})

export default function ({ app }, inject) {
	const toastr = Vue.prototype.$toastr
	const i18n = app.i18n
	const notification = {
		e: (message, title, ...args) => {
			toastr.e(
				translationMessage(message),
				translationMessage(title),
				...args
			)
		},
		s: (message, title, ...args) => {
			toastr.s(
				translationMessage(message),
				translationMessage(title),
				...args
			)
		},
		i: (message, title, ...args) => {
			toastr.i(
				translationMessage(message),
				translationMessage(title),
				...args
			)
		},
		w: (message, title, ...args) => {
			toastr.w(
				translationMessage(message),
				translationMessage(title),
				...args
			)
		},
	}

	function translationMessage(message) {
		const messFormatted = message?.toString()?.replace('.', '')?.trim()
		let messTranslation = i18n.t(message)
		if (i18n.te(`errorMessages.${messFormatted}`)) {
			messTranslation = i18n.t(`errorMessages.${messFormatted}`)
		} else if (i18n.te(`formModals.${messFormatted}`)) {
			messTranslation = i18n.t(`formModals.${messFormatted}`)
		}

		return messTranslation
	}

	inject('notification', notification)
}
