<template>
	<b-container class="px-0 h-100 mx-0 mw-100">
		<b-button
			size="sm"
			variant="outline"
			class="login-form-btn-close"
			@click="handleClickCloseButton()"
		>
			<img class="close-icon" :src="CLOSE_BUTTON" alt="#" />
		</b-button>
		<b-row class="h-100">
			<b-col
				cols="12"
				md="6"
				class="
					left-logo
					d-md-flex d-lg-flex
					flex-column
					justify-content-center
					align-items-center
					px-0
					d-none
				"
				:style="{ backgroundImage: 'url(' + AVATAR_BACKGROUND + ')' }"
			>
				<img
					:src="loadImageSrc(LOGO)"
					class="male_avatar-icon"
					alt="male-avatar"
				/>
			</b-col>
			<b-col cols="12" md="6" class="login-form-body pt-5">
				<h1 class="login-form-body__header w-100 text-center">
					{{ $t('formModals.LETS GET HYPED') }}
				</h1>
				<div class="my-3 d-flex justify-content-center">
					<hr class="my-1 login-form-body__divider" />
				</div>
				<div class="my-5 d-flex justify-content-center text-white">
					<span>{{ $t('formModals.Dont have an account') }}</span>
					&nbsp;
					<b-link
						class="text-white register-link"
						href="javascript:void(0);"
						@click="showRegisterModal"
						>{{ $t('headers.register') }}</b-link
					>
				</div>

				<div class="login-form">
					<ValidationObserver ref="loginForm" v-slot="{ invalid }">
						<div class="login-form__wrapper">
							<label class="login-form__wrapper-label">{{
								$t('formModals.Email')
							}}</label>
							<BInputWithValidation
								ref="email"
								v-model.trim="item.email"
								autofocus
								type="text"
								:placeholder="$t(`formModals.Email address`)"
								rules="required|email"
								vid="email"
								:name="$t(`formModals.Email`)"
								class="login-form__wrapper-input-group"
							/>
						</div>
						<div class="login-form__wrapper my-5">
							<label class="login-form__wrapper-label">{{
								$t('formModals.Password')
							}}</label>
							<BInputWithValidation
								v-model="item.password"
								type="password"
								:placeholder="$t(`formModals.Password`)"
								rules="required|min:6|max:20"
								:name="$t(`formModals.Password`)"
								vid="password"
								class="login-form__wrapper-input-group"
							/>
						</div>
						<div v-if="errorText" class="w-100 error-text">
							{{ errorText }}
						</div>
						<Checkbox
							v-model="keepLoggedIn"
							variant="custom"
							label-class="checkbox__label remember-label text-white"
							class="checkbox"
							:label="$t(`formModals.Keep me logged in`)"
						/>
						<div
							class="text-center action-login"
							:class="actionClass"
						>
							<div
								class="forgot-pwd mb-3"
								@click="showForgotPasswordModal()"
							>
								{{ $t(`formModals.Forgot password?`) }}
							</div>
							<button
								type="submit"
								:disabled="invalid"
								class="btn btn-primary btn-animated w-100"
								@click="signInWithEmail()"
							>
								{{ $t(`textActions.Sign in`) }}
							</button>
						</div>
						<div class="login-social">
							<div
								class="
									login-social-description
									d-inline-flex
									w-100
									justify-content-center
									text-white
								"
							>
								<span>{{
									$t('formModals.Or connect with')
								}}</span>
							</div>
							<div
								class="
									mt-3
									login-social-icon
									d-inline-flex
									w-100
									justify-content-center
								"
							>
								<SignInWithGoogle
									class="login-social-logo"
									@onSuccess="signInWithSocialNetwork"
								>
									<img :src="GoogleIcon" alt="#" />
								</SignInWithGoogle>
								<SignInWithApple
									@onSuccess="signInWithSocialNetwork"
								>
									<img
										:src="AppleIcon"
										class="login-social-logo"
										alt="#"
									/>
								</SignInWithApple>
								<SignInWithFacebook
									@onSuccess="signInWithSocialNetwork"
								>
									<img
										:src="FacebookIcon"
										class="login-social-logo"
										alt="#"
									/>
								</SignInWithFacebook>
							</div>
						</div>
					</ValidationObserver>
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import SdkMixins from '@/mixins/SdkMixins'
import { Encrypt } from '@/utils/AESCrypto'
import {
	DEVICE_TYPES,
	DEVICE_UDID,
	SIGN_IN_TYPE,
	STATUS_RESPONSES,
} from '@/configs/SDK'
import { STATUS_MESSAGES, SYSTEM_MESSAGES } from '@/messages/System'
import Checkbox from '@/components/Checkbox'
import BInputWithValidation from '@/components/BInputWithValidation'
import AVATAR_BACKGROUND from '@/static/media/bg/register_avatar_bg.svg'
import CLOSE_BUTTON from '@/static/media/icons/close.svg'
import LOGO from '@/static/media/images/logo-footer.svg'
import GoogleIcon from '@/static/media/images/logo_google.png'
import AppleIcon from '@/static/media/images/logo_apple.png'
import FacebookIcon from '@/static/media/images/logo_facebook.png'
import { isEmpty } from 'lodash/lang'
import SignInWithFacebook from '~/components/register/SignInWithFacebook'
import SignInWithGoogle from '~/components/register/SignInWithGoogle'
import SignInWithApple from '~/components/register/SignInWithApple'

export default {
	name: 'LoginForm',
	components: {
		BInputWithValidation,
		Checkbox,
		SignInWithFacebook,
		SignInWithGoogle,
		SignInWithApple,
	},
	mixins: [SdkMixins],
	props: {
		actionClass: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		AVATAR_BACKGROUND,
		CLOSE_BUTTON,
		LOGO,
		GoogleIcon,
		AppleIcon,
		FacebookIcon,
		item: {
			email: '',
			password: '',
			deviceType: parseInt(DEVICE_TYPES.WEB),
			deviceUdid: DEVICE_UDID.WEB,
			signInType: SIGN_IN_TYPE.EMAIL,
		},
		keepLoggedIn: false,
		errorText: null,
		socialUserInfo: null,
	}),
	computed: {
		showFillOutAlert() {
			return !isEmpty(this.socialUserInfo)
		},
		isSignInWithSocial() {
			return !isEmpty(this.socialUserInfo)
		},
	},
	watch: {
		open(val) {
			if (val) this.$bvModal.show(this.modalOptions.id)
		},
	},
	methods: {
		async signInWithEmail() {
			const isValid = await this.$refs.loginForm.validate()
			if (!isValid) return
			this.$emit('start-loading')
			try {
				const { code, message } = await this.login(
					{
						beliveId: this.item.email,
						email: this.item.email,
						password: Encrypt(this.item.password),
						deviceType: this.item.deviceType,
						deviceUdid: this.item.deviceUdid,
						latitude: 0,
						longitude: 0,
						signInType: SIGN_IN_TYPE.EMAIL,
					},
					{ keepLoggedIn: this.keepLoggedIn }
				)

				this.handleLoginResponse(code, message, this.item.email)
			} catch (error) {}
			this.$emit('stop-loading')
		},
		resetForm() {
			this.item = {
				email: '',
				password: '',
			}
			this.errorText = null
			this.keepLoggedIn = false
			this.$emit('reset-form')
		},
		showForgotPasswordModal() {
			this.$emit('showForgotPasswordModal')
		},
		showRegisterModal() {
			this.$emit('showRegisterModal')
		},
		async signInWithSocialNetwork(user, signInType) {
			this.item.signInType = signInType
			this.socialUserInfo = user
			this.$emit('start-loading')
			try {
				const { code, message } = await this.login(
					{
						beliveId: this.getBeliveIdBySocialNetWork(
							user,
							signInType
						),
						deviceType: this.item.deviceType,
						deviceUdid: this.item.deviceUdid,
						latitude: 0,
						longitude: 0,
						signInType,
						clientToken: user.accessToken,
					},
					{ keepLoggedIn: false }
				)
				this.handleLoginResponse(code, message, user.email)
			} catch (error) {}
			this.$emit('stop-loading')
		},
		handleLoginResponse(code, message, userEmail) {
			if (code === STATUS_RESPONSES.SUCCESS) {
				this.$notification.s(
					SYSTEM_MESSAGES.LOGIN_SUCCESS,
					STATUS_MESSAGES.SUCCESS
				)
				this.$emit('log-in-success')
				this.resetForm()
			} else if (code === STATUS_RESPONSES.USER_NOT_VERIFY) {
				// Show resend email in case email not verify
				this.$emit('showResendVerifyEmail', userEmail)
			} else if (code === STATUS_RESPONSES.USER_NOT_FOUND) {
				this.$refs.loginForm.setErrors({
					email: [this.$t('errorMessages.User not found')],
				})
				this.errorText = null
				this.$notification.e(message, STATUS_MESSAGES.ERROR)
			} else if (code === STATUS_RESPONSES.INCORRECT_PASSWORD) {
				this.$refs.loginForm.setErrors({
					password: [this.$t('errorMessages.Incorrect Password')],
				})
				this.errorText = null
			} else if (code === STATUS_RESPONSES.INFO_INCORRECT) {
				this.errorText = message || 'Your informations are not correct!'
			} else if (code === STATUS_RESPONSES.ACCOUNT_DEACTIVE) {
				this.errorText = message
			}
		},
		handleClickCloseButton() {
			this.$emit('closeModal')
		},
	},
}
</script>
<style lang="scss" scoped>
.left-logo {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.login-form-body {
	font-family: $font-fira-sans;
	margin-bottom: 3rem;
	&__header {
		color: #fe0;
	}
	&__divider {
		width: 15%;
		height: 5px;
		border-radius: 5px;
		background-color: #f9514f;
	}
	.login-form {
		.fill-out-alert {
			font-size: 1.5rem;
			text-align: center;
		}
		&__wrapper {
			&-label {
				color: #fff;
			}
			&-input-group {
				.input-group {
					border-radius: 10px;
				}
			}
		}
	}
	.register-link {
		text-decoration: underline !important;
	}
}

.login-social {
	.login-social-description {
		span {
			font-style: italic;
			font-weight: 300;
		}
	}
	.login-social-logo {
		width: 3.5rem;
		margin: auto 1rem;
		cursor: pointer;
	}
}

.action-login {
	margin-top: 2rem;
	margin-bottom: 2rem;
	.btn-primary {
		background-color: $color-red-2;
	}
}
.forgot-pwd {
	font-family: $font-secondary;
	font-size: 1.4rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: normal;
	color: #fff;
	margin-top: 1.4rem;
	text-align: center;
	cursor: pointer;
}

.sign-up-text {
	cursor: default;
}

.login-form-btn-close {
	position: absolute;
	padding-right: 0.7rem !important;
	z-index: 2;
	right: -2.4rem;
	top: 0;
}
</style>
