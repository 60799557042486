import { STATUS_RESPONSES } from '@/configs/SDK'
import { DEMO_GIFTS } from '@/assets/data/stream'

const state = () => ({
	giftStore: [],
	myAssets: {
		bean: 0,
		gold: 0,
	},
})

const getters = {
	giftStore(state) {
		return state.giftStore
	},

	myAssets(state) {
		return state.myAssets
	},
}

const mutations = {
	UPDATE_GIFT_STORE(state, giftStore) {
		state.giftStore = [...giftStore]
	},

	UPDATE_MY_ASSETS(state, assets) {
		state.myAssets = { ...state.myAssets, ...assets }
	},
}

const actions = {
	updateGiftStore({ commit }, giftStore) {
		commit('UPDATE_GIFT_STORE', giftStore)
	},

	updateMyAssets({ commit }, assets) {
		commit('UPDATE_MY_ASSETS', assets)
	},

	/**
	 *
	 * @param {*} param0
	 * @param eventId
	 */
	async getGiftStore({ commit }, eventId) {
		const { code, data } =
			await this.$httpResources.repositories.gift.getStore(eventId)
		if (code === STATUS_RESPONSES.SUCCESS) {
			commit('UPDATE_MY_ASSETS', {
				gold: data.totalGold,
				bean: data.totalBean,
			})
			const storeGifts = data.gifts.sort((a, b) => {
				// Sort by category
				const compareCategory =
					a.giftCategoryOrderIndex - b.giftCategoryOrderIndex
				if (compareCategory > 0) {
					return 1
				}
				if (compareCategory < 0) {
					return -1
				}

				// Sort by sub category
				const compareSubCategory =
					a.giftSubCategoryOrderIndex - b.giftSubCategoryOrderIndex
				if (compareSubCategory > 0) {
					return 1
				}
				if (compareSubCategory < 0) {
					return -1
				}

				// Sort by index
				const compareOrder = a.orderIndex - b.orderIndex
				if (compareOrder < 0) {
					return -1
				}
				if (compareOrder > 0) {
					return 1
				}
				return 0
			})
			commit('UPDATE_GIFT_STORE', storeGifts)
			if (!storeGifts.length && process.env.APP_ENV === 'development') {
				commit('UPDATE_MY_ASSETS', {
					gold: 99999,
					bean: 99999,
				})
				commit('UPDATE_GIFT_STORE', DEMO_GIFTS)
			}
		}
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
