<template>
	<ValidationProvider
		v-slot="{ valid, errors }"
		tag="label"
		class="checkbox__container"
		:mode="mode"
		:vid="vid"
		:name="$attrs.name"
		:rules="rules"
	>
		<span
			v-if="!$slots.label"
			class="checkbox__label"
			:class="labelClass"
			>{{ label }}</span
		>
		<span v-else :class="labelClass"><slot name="label"></slot></span>
		<input v-model="checked" type="checkbox" />
		<span class="checkmark" :class="getClass"></span>
		<div
			v-if="!valid && errors[0] && rules && showError"
			class="checkbox__error text-danger"
		>
			{{ errors[0] }}
		</div>
	</ValidationProvider>
</template>

<script>
export default {
	name: 'Checkbox',
	props: {
		vid: {
			type: String,
		},
		rules: {
			type: String,
		},
		mode: {
			type: String,
		},
		variant: {
			type: String,
			default: 'primary',
		},
		label: {
			type: String,
		},
		labelClass: {
			type: String,
		},
		value: {
			type: Boolean,
			default: false,
		},
		showError: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		checked: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
		getClass() {
			if (this.variant === 'primary') return 'checkmark--linear'
			return ''
		},
	},
	mounted() {},
}
</script>

<style lang="scss" scoped>
.checkbox {
	&__container {
		font-family: $font-secondary;
		font-weight: normal;
		color: #353d47;
		position: relative;
		margin-bottom: 0;
		cursor: pointer;
		width: fit-content;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		display: flex;
		flex-direction: row;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}
	}

	&__error {
		font-size: 80%;
	}

	&__label {
		margin-left: 4rem;
		font-family: $font-secondary;
		font-size: 1.4rem;
		font-weight: 500;
		text-align: left;
		color: $color-grey-dark-4;
		line-height: 1.5;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
	}
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	height: 2.3rem;
	width: 2.3rem;
	left: 0;
	padding: 0;
	background-color: $color-white;
	border-radius: 2px;
	border: solid 2px $color-black;

	&--linear {
		background-image: $color-primary-linear-2;
		border: none;
	}
}

/* On mouse-over, add a grey background color */
.checkbox__container:hover input ~ .checkmark {
	box-shadow: 0 0 0 0.2rem $color-black;
}

/* When the checkbox is checked, add a blue background */
.checkbox__container input:checked ~ .checkmark {
	background-color: $color-black;
	border: none;
}

.checkbox__container input:checked ~ .checkmark--linear {
	background-image: $color-primary-linear-2;
	border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
	content: '';
	position: absolute;
	display: none;
}

/* Style the checkmark/indicator */
.checkbox__container .checkmark::after {
	left: 0.7rem;
	top: 0.2rem;
	width: 0.9rem;
	height: 1.4rem;
	border: solid #fff;
	border-width: 0 0.3rem 0.3rem 0;
	transform: rotate(45deg);
}

/* Show the checkmark when checked */
.checkbox__container input:checked ~ .checkmark::after {
	display: block;
}
</style>
