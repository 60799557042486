import { loadCSS } from 'fg-loadcss'
// import { ENV } from "~/configs/Settings"

const elLoadCss = document.getElementById('loadcss')
// loadCSS(`${ENV.ASSETS_ENDPOINT}/fonts/font-family.css`, elLoadCss)
// loadCSS(`@/assets/scss/flag-icon.min.css`, elLoadCss)
loadCSS(
	`https://fonts.googleapis.com/css?family=Lato:wght@100,200,300,400,500,600,700,800,900&display=swap`,
	elLoadCss
)
loadCSS(
	`https://fonts.googleapis.com/css?family=Montserrat:wght@100,200,300,400,500,600,700,800,900&display=swap`,
	elLoadCss
)
loadCSS(
	`https://fonts.googleapis.com/css?family=Fira+Sans:wght@100,200,300,400,500,600,700,800,900&display=swap`,
	elLoadCss
)
