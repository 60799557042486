<template>
	<BeModal
		:modal-options="modalOptions"
		:no-close-button="noCloseButton"
		:loading="loading"
		:title="registerConfirmationModal.title"
		:sub-title="registerConfirmationModal.subTitle"
		@hidden="closeModal"
	>
		<template #default>
			<div class="body">
				<div class="content">
					<div class="content__icon">
						<img
							:src="cardInfo.content.icon"
							class="card-icon"
							alt="card-icon"
						/>
					</div>
					<div
						class="content__main"
						v-html="cardInfo.content.main"
					></div>
				</div>
				<div class="info">
					{{ cardInfo.info }}
				</div>
				<div class="description">
					<div
						v-for="(sub, index) in cardInfo.descriptions"
						:key="index"
						class="description__sub"
						v-html="sub"
					></div>
				</div>
			</div>
			<div class="footer">
				<slot name="footer">
					<b-button
						class="btn btn-primary w-100 btn-animated"
						@click="handleClickGetStart"
					>
						{{ options.actionText }}
					</b-button>
				</slot>
			</div>
		</template>
	</BeModal>
</template>

<script>
import { DEFAULT_ICON_STATES } from '@/configs/Icons'
import BeModal from '@/components/modals/BeModal'
import {COOKIE_KEYS, DEFAULT_HOMEPAGE_URL} from '@/configs/Settings'
import {isEmpty} from "lodash";
export default {
	name: 'RegisterSuccessModal',
	components: {
		BeModal,
	},
	mixins: [],
	props: {
		open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			registerConfirmationModal: {
				title: '',
				subTitle: '',
			},
			modalOptions: {
				id: 'register-success-modal',
				class: 'register-success-modal-custom',
				dialogClass: 'register-success-modal',
				headerClass: 'register-success-modal__header',
				contentClass: 'register-success-modal__content',
				bodyClass: 'register-success-modal__body',
			},
			noCloseButton: false,
		}
	},
	computed: {
		cardInfo() {
			return {
				content: {
					icon: DEFAULT_ICON_STATES.yeay.activeSrc,
					main: this.$t(
						'formModals.Your HYPERLIVE _br_ Account is now activated'
					),
				},
				descriptions: [
					this.$t(
						'formModals.Hooray! You can now access full interactive features on HyperLive! Start by checking out our latest events here'
					),
					this.$t(
						'formModals.We will be sending you weekly email updates on the latest news, special offers and upcoming events'
					),
				],
			}
		},
		options() {
			return {
				actionText: this.$t('formModals.Get Started'),
			}
		},
	},
	watch: {
		open(val) {
			if (val) this.$bvModal.show(this.modalOptions.id)
		},
	},
	mounted() {
		if (this.open) this.$bvModal.show(this.modalOptions.id)
	},
	methods: {
		closeModal() {
			this.$bvModal.hide(this.modalOptions.id)
			this.$emit('hidden')
		},
		handleClickGetStart() {
			const voucherCode = this.$cookies.get(COOKIE_KEYS.VOUCHER_CODE, { parseJSON: true })
			const voucherEventId = this.$cookies.get(COOKIE_KEYS.VOUCHER_EVENT_ID)

			if (voucherEventId && !isEmpty(voucherCode)) {
				this.$router.push(`/events/${voucherEventId}?voucher=${voucherCode.value}`)
			} else if (!isEmpty(voucherCode)) {
				this.$router.push(`/redeem/${voucherCode.value}`)
			} else {
				if (this.$route.name === 'auth-verify-email') {
					this.$router.push(DEFAULT_HOMEPAGE_URL)
				}
				this.$root.$emit('required-login')
			}
			this.closeModal()
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/card-info';
</style>
