<template>
	<div class="web-to-app-mobile-banner d-flex align-items-center">
		<client-only>
			<button
				class="close-icon-container"
				@click="handleClickClose"
			>
				<img
					alt="#"
					class="close-icon"
					:src="CLOSE_ICON"
				/>
			</button>
			<div class="logo d-flex justify-content-center"
			><img
				src="@/static/media/images/logo-hyperlive.svg"
				alt="logo"
			/></div>
			<div class="slogan">
				{{ $t('headers.HYPERLIVE is the ultimate platform for entertainment and lifestyle') }}
			</div>
			<a :href="projectSettings.general.appUrl" class="install">
				{{ $t('headers.Use App') }}
			</a>
		</client-only>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import i18nMixins from '@/mixins/i18nMixins'
import SdkMixins from '@/mixins/SdkMixins'
import {DEFAULT_ICON_STATES} from "~/configs/Icons";

export default {
	name: 'WebToAppMobile',
	components: {},
	mixins: [SdkMixins, i18nMixins],
	props: {},
	data() {
		return {
			CLOSE_ICON: DEFAULT_ICON_STATES.close.activeSrc,
			isHide: false,
		}
	},
	async fetch() {},
	computed: {
		...mapGetters({
			projectSettings: 'projectSettings',
		}),
	},
	methods: {
		handleClickClose() {
			this.$emit('close-web-to-app-banner-mobile')
		}
	},
}
</script>
<style lang="scss" scoped>
.web-to-app-mobile-banner {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 6rem;
	background-image: url('~@/static/media/bg/web-to-app-mobile-bg.png');
	background-repeat: no-repeat;
	background-size: cover;

	.close-icon-container {
		padding: 1rem 1rem;
		background-color: transparent;
		border: none;

		.close-icon {
			width: 1rem;
		}
	}

	.logo {
		min-width: 5rem;
		width: 5rem;
		height: 5rem;
		background-color: white;
		border-radius: 10px;

		img {
			width: 4rem;
		}
	}

	.slogan {
		color: #fff;
		margin: 1rem;
	}

	.install {
		width: 15rem;
		margin-right: 1rem;
		padding: 1rem;
		border: 1px solid #fbb538;
		border-radius: 5px;
		background-color: transparent;
		color: #fff;
		text-align: center;
	}
}
</style>
