<template>
	<div class="transaction-signal">
		<BeModal
			:modal-options="modalOptions"
			:no-close-button="noCloseButton"
			:logo="transactionModal.logo"
			:title="transactionModal.title"
			:sub-title="transactionModal.subTitle"
		>
			<template #default>
				<div
					v-if="state.status === statusMessage.PURCHASED"
					class="transaction-msg__body-content transaction-purchased"
				>
					<img
						:src="SUCCESS_ICON"
						alt="thank-you-icon"
						class="thank-you-icon icon-modal"
					/>
					<p class="main">{{ $t('formModals.Purchase Success') }}</p>
					<p class="sub">
						{{
							$t(
								'formModals.Check out your purchases in the Profile Page'
							)
						}}
					</p>
					<div class="btn-transaction">
						<button
							class="
								btn btn-primary btn-animated
								w-100
								text-uppercase
							"
							@click="close"
						>
							{{ $t('textActions.OK') }}
						</button>
					</div>
				</div>
				<div
					v-else-if="state.status === statusMessage.COMPLETED"
					class="transaction-msg__body-content transaction-completed"
				>
					<img
						:src="SUCCESS_ICON"
						alt="thank-you-icon"
						class="thank-you-icon icon-modal"
					/>
					<p class="main">{{ $t('formModals.Purchase Success') }}</p>
					<p class="sub">
						{{
							$t(
								'formModals.Congratulations, your transaction is successful'
							)
						}}
					</p>
					<p
						class="info"
						v-html="
							$t('formModals.You have purchased _xxx_ hypes', {
								hypes: state.dimonds.toLocaleString('en-US'),
							})
						"
					></p>
					<div class="btn-transaction">
						<button
							class="
								btn btn-primary btn-animated
								w-100
								text-uppercase
							"
							@click="close"
						>
							{{ $t('textActions.OK') }}
						</button>
					</div>
				</div>
				<div
					v-else
					class="transaction-msg__body-content transaction-failed"
				>
					<img
						:src="OPPS_ICON"
						alt="transaction-failed"
						class="transaction-failed-icon icon-modal"
					/>
					<p class="main">{{ $t('formModals.Sorry') }}</p>
					<p class="sub">
						{{ $t('formModals.Please try a different payment method') }}
					</p>
					<div class="btn-transaction">
						<button
							class="
								btn btn-primary btn-animated
								w-100
								text-capitalize
							"
							@click="retryPayment"
						>
							{{ $t('textActions.Try Again') }}
						</button>
					</div>
				</div>
			</template>
			<template
				v-if="state.status === statusMessage.COMPLETED"
				#header-custom
			>
				<div class="header">
					<div class="header__sub">
						{{ $t('formModals.Transaction') }}
					</div>
					<div class="header__primary">
						{{ state.transactionId }}
					</div>
				</div>
				<div class="close-button" @click="close">
					<img class="close-icon" :src="CLOSE_ICON" />
				</div>
			</template>
			<template
				v-else-if="
					state.status === statusMessage.NONE ||
					state.status === statusMessage.ERROR
				"
				#header-custom
			>
				<div class="header">
					<div class="header__sub">
						{{ $t('formModals.Transaction Failed') }}
					</div>
				</div>
				<div class="close-button" @click="close">
					<img class="close-icon" :src="CLOSE_ICON" />
				</div>
			</template>
		</BeModal>
		<SignalRtm
			v-if="userChat && !inLivePage"
			ref="signalRtm"
			:server="server"
			:license="license"
			:user="userChat"
		/>
	</div>
</template>

<script>
import {
	PAYMENT_HUB,
	STRIPE_SIGNAL_RESPONSE,
	TRANSACTION_STATUS,
} from '@/configs/SDK'
import { mapGetters, mapActions } from 'vuex'
import SignalRService from '@/utils/SignalrService'
import { DEFAULT_ICON_STATES } from '@/configs/Icons'
import BeModal from '@/components/modals/BeModal'
import SignalRtm from '@/components/SignalRtm'
import { COOKIE_KEYS } from '@/configs/Settings'

export default {
	name: 'TransactionMessageModal',
	components: { BeModal, SignalRtm },
	mixins: [],
	props: {
		status: {
			type: String,
			default: TRANSACTION_STATUS.NONE,
		},
		transactionId: {
			type: String,
			default: '',
		},
		dimonds: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			server: process.env.chatServer,
			license: process.env.chatKey,
			transactionModal: {
				title: '',
				subTitle: '',
				logo: '',
			},
			noCloseButton: true,
			state: {
				status: TRANSACTION_STATUS.NONE,
				transactionId: '',
				dimonds: 0,
				message: '',
			},
			upcommingState: null,
			statusMessage: TRANSACTION_STATUS,
			connection: null,
			paymentHub: null,
			open: false,
			OPPS_ICON: DEFAULT_ICON_STATES.opps.activeSrc,
			SUCCESS_ICON: DEFAULT_ICON_STATES.success.activeSrc,
			CLOSE_ICON: DEFAULT_ICON_STATES.close.activeSrc,
		}
	},
	watch: {
		user: {
			async handler(val) {
				if (val) {
					try {
						if (this.isLoggedIn) {
							await this.updateUserChat(this.user.userInfo)
						} else {
							const chatDisplayName =
								this.$cookies.get(
									COOKIE_KEYS.CHAT_DISPLAY_NAME
								) || ''
							this.updateUserChat(
								await this.createUser('+guest', chatDisplayName)
							)
						}
					} catch (e) {
						console.log(e)
					}
				}
			},
			deep: true,
		},
		open(val) {
			if (val) this.$bvModal.show(this.modalOptions.id)
			else this.$bvModal.hide(this.modalOptions.id)
		},
		// isLoggedIn(val) {
		// 	if (!val) {
		// 		this.paymentHub.destroy()
		// 	} else {
		// 		this.paymentHub.token = val.accessToken
		// 		this.paymentHub.connect()
		// 	}
		// },
		transactionState({ status, data }) {
			if (
				[
					TRANSACTION_STATUS.ERROR,
					TRANSACTION_STATUS.COMPLETED,
					TRANSACTION_STATUS.PURCHASED,
				].includes(status)
			) {
				const newState = {
					status,
					transactionId: data?.transactionId || null,
					dimonds: data?.bean || 0,
					message: data?.message,
				}
				if (!this.open) {
					this.state = { ...newState }
					this.open = true
				} else {
					this.upcommingState = { ...newState }
				}
			}
			// if (status === TRANSACTION_STATUS.COMPLETED) {
			//    this.getProfile(this.user.userInfo.userId, this.user.userInfo.userName)
			// }
		},
	},
	beforeDestroy() {
		// this.paymentHub?.destroy()
	},
	async mounted() {
		// this.init()
		if (this.isLoggedIn) {
			this.updateUserChat(this.user.userInfo)
		} else {
			const chatDisplayName =
				this.$cookies.get(COOKIE_KEYS.CHAT_DISPLAY_NAME) || ''
			this.updateUserChat(
				await this.createUser('+guest', chatDisplayName)
			)
		}

		if (this.open) this.$bvModal.show(this.modalOptions.id)
	},
	computed: {
		...mapGetters({
			user: 'user',
			isLoggedIn: 'isLoggedIn',
			transactionState: 'transaction/transactionState',
			userChat: 'channel/userChat',
			inLivePage: 'channel/inLivePage',
		}),
		getDimonds() {
			return this.state.dimonds?.toLocaleString('en-US') || 0
		},
		modalOptions() {
			return {
				id: `transaction-modal`,
				class: `transaction-${this.state.status.toLowerCase()}-modal__class`,
				dialogClass: `transaction-${this.state.status.toLowerCase()}-modal__dialog`,
				headerClass: `transaction-${this.state.status.toLowerCase()}-modal__header`,
				contentClass: `transaction-${this.state.status.toLowerCase()}-modal__content`,
				bodyClass: `transaction-${this.state.status.toLowerCase()}-modal__body`,
			}
		},
	},
	methods: {
		...mapActions({
			updateTransaction: 'transaction/updateTransaction',
			resetTransaction: 'transaction/resetTransaction',
			updateUserChat: 'channel/updateUserChat',
		}),
		init() {
			this.paymentHub = new SignalRService({
				server: PAYMENT_HUB,
				channel: 'joinPaymentHubGroup',
				on: {
					BroadcastMessage: this.handleReceiveMsg,
				},
			})

			if (this.isLoggedIn) {
				this.paymentHub.token = this.user.accessToken
				this.paymentHub.connect()
			}
		},
		close() {
			this.open = false
			if (this.upcommingState) {
				this.state = { ...this.upcommingState }
				this.upcommingState = null
				setTimeout(() => {
					this.open = true
				}, 500)
			}
			this.$emit('afterClose')
		},
		retryPayment() {
			// this.$root.$emit("retryPayment");
			this.close()
			this.$router.push('/recharge/credit-card')
		},
		handleReceiveMsg(message) {
			if (!message) return
			console.log('Message from SignalR', message)
			const msgJson = JSON.parse(message)
			if (msgJson.status === STRIPE_SIGNAL_RESPONSE.PAYMENT_SUCCESS) {
				this.updateTransaction({
					status: TRANSACTION_STATUS.COMPLETED,
					data: msgJson,
				})
				// eslint-disable-next-line no-undef
				// const gtagFunc = gtag
				// if (gtagFunc)
				//    gtagFunc("event", "purchase", {
				//       affiliation: "Web",
				//       currency: CURRENCY,
				//       items: [
				//          {
				//             item_id: msgJson.topUpId,
				//             item_name: msgJson.name,
				//             affiliation: "Web",
				//             item_brand: "Knock2live",
				//             item_category: "Hypes Package",
				//             price: msgJson.priceUsd,
				//             currency: CURRENCY,
				//             quantity: 1,
				//             //coupon: "SUMMER_FUN",
				//             //discount: 2.22,
				//             //item_variant: "gray",
				//          },
				//       ],
				//       transaction_id: msgJson.transactionId,
				//       value: msgJson.topUpId,
				//    })
			} else {
				this.updateTransaction({
					status: TRANSACTION_STATUS.ERROR,
					data: { message: msgJson.ErrorMessage },
				})
			}
			console.log('invoke SignalR')
			this.paymentHub
				.invoke('paymentIpnSignal', this.user.accessToken, true)
				.catch(function (err) {
					return console.error(err.toString())
				})
		},
	},
}
</script>
