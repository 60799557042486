import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1dcb0af0 = () => interopDefault(import('../src/pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _c083daba = () => interopDefault(import('../src/pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _6e813105 = () => interopDefault(import('../src/pages/browse/index.vue' /* webpackChunkName: "pages/browse/index" */))
const _4da4526a = () => interopDefault(import('../src/pages/channels/index.vue' /* webpackChunkName: "pages/channels/index" */))
const _08b024c1 = () => interopDefault(import('../src/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _7192c4d4 = () => interopDefault(import('../src/pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _79335a5e = () => interopDefault(import('../src/pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _702eb382 = () => interopDefault(import('../src/pages/lives/index.vue' /* webpackChunkName: "pages/lives/index" */))
const _a7e4fae4 = () => interopDefault(import('../src/pages/maintenance/index.vue' /* webpackChunkName: "pages/maintenance/index" */))
const _a68cf3e4 = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _7b7cd5e8 = () => interopDefault(import('../src/pages/order-status/index.vue' /* webpackChunkName: "pages/order-status/index" */))
const _2d7ad19a = () => interopDefault(import('../src/pages/past-streams/index.vue' /* webpackChunkName: "pages/past-streams/index" */))
const _d0bd34b8 = () => interopDefault(import('../src/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _50741d1d = () => interopDefault(import('../src/pages/recordings/index.vue' /* webpackChunkName: "pages/recordings/index" */))
const _3547731e = () => interopDefault(import('../src/pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _7192453c = () => interopDefault(import('../src/pages/tickets/index.vue' /* webpackChunkName: "pages/tickets/index" */))
const _097a04c0 = () => interopDefault(import('../src/pages/transaction-history/index.vue' /* webpackChunkName: "pages/transaction-history/index" */))
const _4376641d = () => interopDefault(import('../src/pages/welcome/index.vue' /* webpackChunkName: "pages/welcome/index" */))
const _78991467 = () => interopDefault(import('../src/pages/welcome-tmp/index.vue' /* webpackChunkName: "pages/welcome-tmp/index" */))
const _30657150 = () => interopDefault(import('../src/pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _52adc587 = () => interopDefault(import('../src/pages/auth/verify-email.vue' /* webpackChunkName: "pages/auth/verify-email" */))
const _59e4a3aa = () => interopDefault(import('../src/pages/faq/privacy-policy/index.vue' /* webpackChunkName: "pages/faq/privacy-policy/index" */))
const _30deb4eb = () => interopDefault(import('../src/pages/faq/terms-of-use/index.vue' /* webpackChunkName: "pages/faq/terms-of-use/index" */))
const _70a640e6 = () => interopDefault(import('../src/pages/recharge/backup._type.vue' /* webpackChunkName: "pages/recharge/backup._type" */))
const _5ff8844e = () => interopDefault(import('../src/pages/channels/on-goings/_id/index.vue' /* webpackChunkName: "pages/channels/on-goings/_id/index" */))
const _4453dc88 = () => interopDefault(import('../src/pages/channels/pasts/_id/index.vue' /* webpackChunkName: "pages/channels/pasts/_id/index" */))
const _774ed794 = () => interopDefault(import('../src/pages/channels/_id/index.vue' /* webpackChunkName: "pages/channels/_id/index" */))
const _b5d55482 = () => interopDefault(import('../src/pages/events/_id/index.vue' /* webpackChunkName: "pages/events/_id/index" */))
const _ffb62c0e = () => interopDefault(import('../src/pages/news/_id/index.vue' /* webpackChunkName: "pages/news/_id/index" */))
const _7a5cf4a9 = () => interopDefault(import('../src/pages/recharge/_type.vue' /* webpackChunkName: "pages/recharge/_type" */))
const _8a1472de = () => interopDefault(import('../src/pages/redeem/_code.vue' /* webpackChunkName: "pages/redeem/_code" */))
const _f1c70b6c = () => interopDefault(import('../src/pages/voting/_id.vue' /* webpackChunkName: "pages/voting/_id" */))
const _3b7a768a = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _1dcb0af0,
    name: "about"
  }, {
    path: "/auth",
    component: _c083daba,
    name: "auth"
  }, {
    path: "/browse",
    component: _6e813105,
    name: "browse"
  }, {
    path: "/channels",
    component: _4da4526a,
    name: "channels"
  }, {
    path: "/checkout",
    component: _08b024c1,
    name: "checkout"
  }, {
    path: "/events",
    component: _7192c4d4,
    name: "events"
  }, {
    path: "/faq",
    component: _79335a5e,
    name: "faq"
  }, {
    path: "/lives",
    component: _702eb382,
    name: "lives"
  }, {
    path: "/maintenance",
    component: _a7e4fae4,
    name: "maintenance"
  }, {
    path: "/news",
    component: _a68cf3e4,
    name: "news"
  }, {
    path: "/order-status",
    component: _7b7cd5e8,
    name: "order-status"
  }, {
    path: "/past-streams",
    component: _2d7ad19a,
    name: "past-streams"
  }, {
    path: "/profile",
    component: _d0bd34b8,
    name: "profile"
  }, {
    path: "/recordings",
    component: _50741d1d,
    name: "recordings"
  }, {
    path: "/shop",
    component: _3547731e,
    name: "shop"
  }, {
    path: "/tickets",
    component: _7192453c,
    name: "tickets"
  }, {
    path: "/transaction-history",
    component: _097a04c0,
    name: "transaction-history"
  }, {
    path: "/welcome",
    component: _4376641d,
    name: "welcome"
  }, {
    path: "/welcome-tmp",
    component: _78991467,
    name: "welcome-tmp"
  }, {
    path: "/auth/reset-password",
    component: _30657150,
    name: "auth-reset-password"
  }, {
    path: "/auth/verify-email",
    component: _52adc587,
    name: "auth-verify-email"
  }, {
    path: "/faq/privacy-policy",
    component: _59e4a3aa,
    name: "faq-privacy-policy"
  }, {
    path: "/faq/terms-of-use",
    component: _30deb4eb,
    name: "faq-terms-of-use"
  }, {
    path: "/recharge/backup._type",
    component: _70a640e6,
    name: "recharge-backup._type"
  }, {
    path: "/channels/on-goings/:id",
    component: _5ff8844e,
    name: "channels-on-goings-id"
  }, {
    path: "/channels/pasts/:id",
    component: _4453dc88,
    name: "channels-pasts-id"
  }, {
    path: "/channels/:id",
    component: _774ed794,
    name: "channels-id"
  }, {
    path: "/events/:id",
    component: _b5d55482,
    name: "events-id"
  }, {
    path: "/news/:id",
    component: _ffb62c0e,
    name: "news-id"
  }, {
    path: "/recharge/:type?",
    component: _7a5cf4a9,
    name: "recharge-type"
  }, {
    path: "/redeem/:code?",
    component: _8a1472de,
    name: "redeem-code"
  }, {
    path: "/voting/:id?",
    component: _f1c70b6c,
    name: "voting-id"
  }, {
    path: "/",
    component: _3b7a768a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
