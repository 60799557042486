import {
	BROWSER,
	MOBILE_OS,
	DEVICE_TYPES,
	STATUS_RESPONSES,
	DEFAULT_VALUES,
} from '@/configs/SDK'
import { ENV_CONFIGS, COOKIE_KEYS } from '@/configs/Settings'
export const strict = false

export const state = () => ({
	user: null,
	userDetail: null,
	country: null,
	isMaintenance: false,
	webLoaded: false,
	previousPath: null,
	isMobileLayout: false,
	categories: [],
	browser: BROWSER.UNKNOWN,
	mobileOs: MOBILE_OS.UNKNOWN,
	screen: {
		innerWidth: 0,
		innerHeight: 0,
	},
	isInAppBrowser: false,
	isLoggedIn: false,
	isBottom: false,
	locale: 'en',
	projectSettings: {
		// General:
		general: {
			showAgeConfirm: false,
			ageConfirmOnChannels: [],
		},

		// Config for using IVS or Wowza/IVS
		stream: {
			ALWAYS_USE_IVS: true,
		},
	},
	tokenIndex: 0,
})

export const getters = {
	user(state) {
		return state.user
	},
	userDetail(state) {
		return state.userDetail
	},
	screen(state) {
		return state.screen
	},
	isLoggedIn(state) {
		return !!(
			state.user?.userInfo?.userName &&
			!state.user.userInfo.userName.includes('+guest_')
		)
	},
	country(state) {
		return state.country
	},
	webLoaded(state) {
		return state.webLoaded
	},
	previousPath(state) {
		return state.previousPath
	},
	categories(state) {
		return state.categories
	},
	streamCategories(state) {
		return state.categories.filter((x) => x.tagId >= 0)
	},
	isMobileLayout(state) {
		return state.isMobileLayout
	},
	isBottom(state) {
		return state.isBottom
	},
	browser(state) {
		return state.browser
	},
	mobileOs(state) {
		return state.mobileOs
	},
	isMaintenance(state) {
		return state.isMaintenance
	},
	isInAppBrowser(state) {
		return state.isInAppBrowser
	},
	locale(state) {
		return state.locale
	},
	projectSettings(state) {
		return state.projectSettings
	},
	tokenIndex(state) {
		return state.tokenIndex
	},
}

export const mutations = {
	UPDATE_USER_INFO(state, userInfo) {
		if (!userInfo) {
			state.user.userInfo = {}
			return
		}
		state.user.userInfo = { ...state.user.userInfo, ...userInfo }
	},
	USER_LOGIN(state, user) {
		// Guest:
		if (!user) {
			state.user = {}
			return
		}
		state.user = { ...user }
		state.user.guestId = user && user.guestId ? user.guestId : 0
		state.user.guestName = user && user.guestName ? user.guestName : null
		state.user.displayName =
			user && user.displayName ? user.displayName : null
	},
	UPDATE_USER(state, user) {
		state.user = {
			...state.user,
			...user,
			userInfo: { ...state.userInfo, ...user.userInfo },
		}
	},
	USER_LOGOUT(state, user) {
		state.user.displayName = null
		state.user.guestId = user && user.guestId ? user.guestId : 0
		state.user.guestName = user && user.guestName ? user.guestName : null
	},
	SET_COUNTRY(state, country) {
		state.country = country
	},
	SET_WEB_LOADED(state, loaded) {
		state.webLoaded = loaded
	},
	SET_PREVIOUS_PATH(state, path) {
		state.previousPath = path
	},
	UPDATE_CATEGORIES(state, categories) {
		state.categories = categories
	},
	UPDATE_MOBILE_BROWSER_LAYOUT(state, isMobileLayout) {
		state.isMobileLayout = isMobileLayout
	},
	UPDATE_IS_BOTTOM(state, isBottom) {
		state.isBottom = isBottom
	},
	UPDATE_BROWSER(state, browser) {
		state.browser = browser
	},
	UPDATE_MOBILE_OS(state, mobileOs) {
		state.mobileOs = mobileOs
	},
	UPDATE_MAINTENANCE(state, isMaintenance) {
		state.isMaintenance = isMaintenance
	},
	UPDATE_SCREEN(state, screen) {
		state.screen = screen
			? { ...state.screen, ...screen }
			: { innerWidth: 0, innerHeight: 0 }
	},
	UPDATE_IS_IN_APP_BROWSER(state, isInAppBrowser) {
		state.isInAppBrowser = isInAppBrowser
	},
	UPDATE_LOCALE(state, locale) {
		state.locale = locale
	},
	SET_PROJECT_SETTINGS(state, projectSettings) {
		state.projectSettings = { ...projectSettings }
	},
	UPDATE_TOKEN_INDEX(state, tokenIndex) {
		state.tokenIndex = tokenIndex
	},
	UPDATE_USER_DETAIL(state, userDetail) {
		state.userDetail = { ...userDetail }
	},
}

export const actions = {
	updateIsInAppBrowser({ commit }, isInAppBrowser) {
		commit('UPDATE_IS_IN_APP_BROWSER', isInAppBrowser)
	},
	updateScreen: ({ commit }, screen) => {
		commit('UPDATE_SCREEN', screen)
	},
	updateMaintenance: ({ commit }, isMaintenance) => {
		commit('UPDATE_MAINTENANCE', isMaintenance)
	},
	updateUserInfo: ({ commit }, user) => {
		commit('UPDATE_USER_INFO', user)
	},
	userLogin: ({ commit }, user) => {
		commit('USER_LOGIN', user)
	},
	userLogout: ({ commit }, user) => {
		commit('USER_LOGOUT', user)
	},
	updateUser({ commit }, user) {
		commit('UPDATE_USER', user)
	},
	setWebLoaded: ({ commit }, loaded) => {
		commit('SET_WEB_LOADED', loaded)
	},
	setPreviousPath: ({ commit }, path) => {
		commit('SET_PREVIOUS_PATH', path)
	},
	updateIsBottom({ commit }, isBottom) {
		commit('UPDATE_IS_BOTTOM', isBottom)
	},
	updateCategories({ commit }, categories) {
		commit('UPDATE_CATEGORIES', categories)
	},
	updateMobileBrowserLayout: ({ commit }, isMobileLayout) => {
		commit('UPDATE_MOBILE_BROWSER_LAYOUT', isMobileLayout)
	},
	updateLocale({ commit }, locale) {
		commit('UPDATE_LOCALE', locale)
	},
	updateUserDetail({ commit }, userDetail) {
		commit('UPDATE_USER_DETAIL', userDetail)
	},

	// If using SSR
	nuxtServerInit(
		{ commit, dispatch },
		{ $http, route, query, req, res, env }
	) {
		console.log('hello Nuxt')
	},

	async getCountryCodeAPI({ commit, dispatch, state }) {
		try {
			const ipInfo = await fetch(
				`https://ipinfo.io/json?token=${
					state.projectSettings.ipInfoTokens[state.tokenIndex]
				}`
			).then((res) => res.json())

			if (ipInfo.country) {
				const countryCode = ipInfo?.country || DEFAULT_VALUES.LOCATION
				this.$cookies.set(COOKIE_KEYS.COUNTRY_CODE, countryCode, {
					path: '/',
					maxAge: 60 * 60 * 24 * 365, // 1 year
				})
				commit('SET_COUNTRY', countryCode)
				this.$httpResources.setHeader('countryCode', countryCode)
			} else {
				dispatch('refreshNewIpInfoToken')
			}
		} catch (error) {
			console.error('Error getCountryCodeAPI', error.toString())
			dispatch('refreshNewIpInfoToken')
		}
	},

	async refreshNewIpInfoToken({ commit, dispatch, state }) {
		await commit('UPDATE_TOKEN_INDEX', state.tokenIndex + 1)
		if (
			process.env.APP_ENV === 'production' &&
			state.tokenIndex < state.projectSettings.ipInfoTokens.length
		) {
			await dispatch('getCountryCodeAPI')
		} else {
			this.$httpResources.setHeader(
				'countryCode',
				DEFAULT_VALUES.LOCATION
			)
			this.$cookies.remove(COOKIE_KEYS.COUNTRY_CODE)
		}
	},

	/** Detect location of user */
	async detectLocation({ commit, dispatch }) {
		if (!ENV_CONFIGS.IS_DETECT_LOCATION) {
			this.$httpResources.setHeader(
				'countryCode',
				DEFAULT_VALUES.LOCATION
			)
			commit('SET_COUNTRY', DEFAULT_VALUES.LOCATION)
		} else {
			const countryCode =
				this.$cookies.get(COOKIE_KEYS.COUNTRY_CODE) || null
			if (countryCode) {
				commit('SET_COUNTRY', countryCode)
				this.$httpResources.setHeader('countryCode', countryCode)
			} else {
				await dispatch('getCountryCodeAPI')
			}
		}
	},

	/** Check login */
	/**
	 *
	 * @param {*} param0
	 * @param {*} fetchUser
	 */
	async checkLogin({ commit, dispatch }, fetchUser = true) {
		await dispatch('detectLocation')
		// Check if cookie has token
		let isValid = false
		const accessToken = this.$cookies.get(COOKIE_KEYS.ACCESS_TOKEN) || null
		const guestToken = this.$cookies.get(COOKIE_KEYS.GUEST_TOKEN) || null
		const hShopAccessToken = this.$cookies.get(
			COOKIE_KEYS.HYPERSHOP_ACCESS_TOKEN
		)
		const token = accessToken || guestToken
		if (token) {
			this.$httpResources.setToken(token, 'Bearer')
			try {
				const { code, data } =
					await this.$httpResources.repositories.auth.checkLogin()
				if (code === STATUS_RESPONSES.SUCCESS) {
					isValid = true
					commit('USER_LOGIN', { ...data, accessToken: token })
					this.$httpResources.setHeader(
						'hShopAccessToken',
						hShopAccessToken
					)
					if (fetchUser) {
						await dispatch('getProfile', {
							userId: data.userInfo.userId,
							userName: data.userInfo.userName,
						})
					}
				}
			} catch (err) {}
		}
		if (!isValid) await dispatch('logout')
		return isValid
	},

	/** Guest login */
	async guestLogin({ commit }) {
		this.$cookies.remove(COOKIE_KEYS.GUEST_TOKEN)
		let randomId = parseInt(Math.random()*10000);
		let randomUserId = parseInt(Math.random()*10000);
		const { code, data } =
			await this.$httpResources.repositories.auth.guestLogin({
				deviceUdid: `web-client-${randomId}${randomUserId}`,
				deviceType: parseInt(DEVICE_TYPES.WEB),
			})
		if (code === STATUS_RESPONSES.SUCCESS && data.accessToken) {
			commit('USER_LOGIN', data)
			const token = data.accessToken
			this.$cookies.set(COOKIE_KEYS.GUEST_TOKEN, token)
			this.$httpResources.setToken(token, 'Bearer')
			commit('giftStore/UPDATE_MY_ASSETS', {
				gold: 0,
				bean: 0,
			})
		}
	},

	/** Logout user */
	async logout({ commit, dispatch }) {
		this.$httpResources.setToken(false)
		await this.$cookies.remove(COOKIE_KEYS.GUEST_TOKEN)
		await this.$cookies.remove(COOKIE_KEYS.ACCESS_TOKEN)
		await this.$cookies.remove(COOKIE_KEYS.REFRESH_TOKEN)
		await this.$cookies.remove(COOKIE_KEYS.HYPERSHOP_ACCESS_TOKEN)
		await commit('USER_LOGIN', null)
		await dispatch('guestLogin')
		// if (routes.some((x) => x.meta?.requiresAuth && x.name === this.$route.name)) this.$router.push({ name: "Home" });
	},

	/**
	 *
	 * @param {*} param0
	 */
	async getProfile({ commit, dispatch }, { userId, userName }) {
		const { code, data } =
			await this.$httpResources.repositories.user.detail({
				userId,
				userName,
			})
		if (code === STATUS_RESPONSES.SUCCESS) {
			commit('UPDATE_USER_INFO', {
				email: data.user.email,
			})
			await dispatch('giftStore/updateMyAssets', {
				bean: data.user.totalBean,
			})
		}
	},
	async checkMaintenance({ commit, state }) {
		const data =
			await this.$httpResources.repositories.common.checkMaintenance(
				state.projectSettings.maintenance.url
			)
		const isMaintenance =
			data?.maintenance_mode === state.projectSettings.maintenance.value
		if (this.isMaintenance !== isMaintenance) {
			await commit('UPDATE_MAINTENANCE', isMaintenance)
		}
	},
	async getProjectSettings({ commit }) {
		try {
			const data =
				await this.$httpResources.repositories.common.getProjectSettings()
			commit('SET_PROJECT_SETTINGS', data)
		} catch (error) {
			console.error(error)
			const envSetting =
				process.env.APP_ENV === 'production'
					? 'production'
					: 'development'
			const res = await import(
				`@/static/settings/settings-${envSetting}.json`
			)
			commit('SET_PROJECT_SETTINGS', res.default)
		}
	},
}
