<template>
	<BeModal
		:modal-options="modalOptions"
		:no-close-button="false"
		@close="$bvModal.hide(modalOptions.id)"
		@hidden="resetModal"
	>
		<template #header-custom>
			<div v-show="false"></div>
		</template>
		<template #default>
			<iframe
				:key="keyRender"
				ref="productIframe"
				class="h-shop-container"
				:src="renderUrl"
				title="description"
			></iframe>
		</template>
	</BeModal>
</template>

<script>
import BeModal from '@/components/modals/BeModal'
import { mapGetters } from 'vuex'
import { COOKIE_KEYS } from '~/configs/Settings'
import HelperMixins from '~/mixins/HelperMixins'
import { hShopLangCode } from '~/assets/data/h-shop-language-code'

export default {
	name: 'ShopModal',
	components: {
		BeModal,
	},
	mixins: [HelperMixins],
	props: {
		open: {
			type: Boolean,
			default: false,
		},
		url: {
			type: String,
			default: '#',
		},
		modalOptions: {
			type: Object,
			default: () => {
				return {
					id: 'hshop-modal',
					class: 'hshop-modal-custom',
					dialogClass: 'hshop-modal',
					headerClass: 'hshop-modal__header',
					contentClass: 'hshop-modal__content',
					bodyClass: 'hshop-modal__body',
				}
			},
		},
	},
	data() {
		return {
			keyRender: Date.now(),
			hShopUrl: '',
			hShopAccessToken: '',
			renderUrl: '',
		}
	},
	computed: {
		...mapGetters({
			isLoggedIn: 'isLoggedIn',
			locale: 'locale',
		}),
	},
	watch: {
		url() {
			this.hShopUrl = this.url
		},
		open(val) {
			if (val) this.$bvModal.show(this.modalOptions.id)
			if (val && this.hShopUrl) {
				this.renderIframe()
			}
		},
		renderUrl() {
			this.keyRender = Date.now()
		},
		isLoggedIn() {
			this.hShopAccessToken = this.$cookies.get(
				COOKIE_KEYS.HYPERSHOP_ACCESS_TOKEN
			)
		},
	},
	created() {},
	mounted() {
		if (this.open) this.$bvModal.show(this.modalOptions.id)
		this.hShopAccessToken = this.$cookies.get(
			COOKIE_KEYS.HYPERSHOP_ACCESS_TOKEN
		)
	},
	methods: {
		resetModal() {
			this.$bvModal.hide(this.modalOptions.id)
			this.$emit('hidden')
		},
		renderIframe() {
			if (this.hShopUrl) {
				this.renderUrl =
					this.hShopUrl +
					'&' +
					this.encodeQueryData({
						t: this.hShopAccessToken,
						lang_code: hShopLangCode[this.locale],
					})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.action-modal {
	margin-top: 4rem;
	margin-bottom: 2rem;
}

.h-shop-container {
	width: 100%;
	height: 80vh;
	border: none;
	@include respond(mobile) {
		height: 40vh;
	}
	@include respond(mobile-land) {
		height: 80vh;
	}
}
</style>
