<template>
	<BeModal
		:modal-options="modalOptions"
		:loading="loading"
		:title="verifyModal.title"
		:sub-title="verifyModal.subTitle"
		:sub-title-type="verifyModal.subTitleType"
		:logo="DEFAULT_LOGO.activeSrc"
		@hidden="resetForm"
	>
		<template #default>
			<VerifyEmailForm
				v-if="!emailVerified"
				ref="verifyEmailFormComponent"
				@show-register="$emit('show-register')"
				@show-invalid-email="showInvalidEmail"
				@verify-email-success="verifyEmailSuccess"
			/>
			<div v-else class="text-center guide-part reset-link-part">
				<div class="guide-part__text">
					{{
						$t(
							'formModals.A verification link has been sent to your email'
						)
					}}
				</div>
				<div class="guide-part__email">
					{{ emailVerified }}
				</div>
				<div v-if="limitSMS > 0" class="resend-wrapper text-center">
					<span class="hint hint--info">{{
						$t('formModals.Didnt receive a link')
					}}</span>
					<span
						class="hint hint--info hint--clickable ml-2"
						:class="countDownResendOTP > 0 ? 'disabled' : ''"
						@click="resendLink"
						>{{ $t('formModals.Resend') }}</span
					>
					<span
						v-if="countDownResendOTP > 0"
						class="hint hint--info hint--countdown"
						>&nbsp;({{ countDownResendOTP }}s)</span
					>
				</div>
			</div>
		</template>
	</BeModal>
</template>

<script>
import BeModal from '@/components/modals/BeModal'
import VerifyEmailForm from '@/components/forms/VerifyEmailForm'
import SdkMixins from '@/mixins/SdkMixins'
import { DEFAULT_ICON_STATES } from '@/configs/Icons'
import { STATUS_RESPONSES } from '@/configs/SDK'
import { mapGetters } from 'vuex'

const CURRENT_STATES = {
	VERIFY: 2,
	INVALID: 0,
	SUCCESS: 1,
}

export default {
	name: 'ForgotPasswordModal',
	components: {
		BeModal,
		VerifyEmailForm,
	},
	mixins: [SdkMixins],
	props: {
		open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			modalOptions: {
				id: 'reset-password-modal',
				class: 'reset-password-modal-custom',
				dialogClass: 'reset-password-modal',
				headerClass: 'reset-password-modal__header',
				contentClass: 'reset-password-modal__content',
				bodyClass: 'reset-password-modal__body',
			},
			DEFAULT_LOGO: DEFAULT_ICON_STATES.checkEmail,
			emailVerified: '',
			limitSMS: 5,
			countDownResendOTP: 0,
			currentState: CURRENT_STATES.VERIFY, // 0 - verify, 1 - invalid, 2 - success
		}
	},
	computed: {
		...mapGetters({
			projectSettings: 'projectSettings',
		}),
		verifyModal() {
			if (this.currentState === CURRENT_STATES.VERIFY) {
				return {
					title: this.$t('formModals.Enter your email'),
					subTitle: this.$t(
						'formModals.Enter the email using to login your account'
					),
				}
			}
			if (this.currentState === CURRENT_STATES.INVALID) {
				return {
					title: this.$t('formModals.Enter your email'),
					subTitle: this.$t(
						'errorMessages.Email does not exist Please try again'
					),
					subTitleType: 'error',
				}
			}
			if (this.currentState === CURRENT_STATES.SUCCESS) {
				return {
					title: this.$t('formModals.Verify your email'),
					subTitle: '',
				}
			}

			return { title: '', subTitle: '' }
		},
	},
	watch: {
		open(val) {
			if (val) this.$bvModal.show(this.modalOptions.id)
		},
	},
	mounted() {
		this.limitSMS = this.projectSettings.resendVerify.limit
		if (this.open) this.$bvModal.show(this.modalOptions.id)
	},
	methods: {
		resetForm() {
			this.currentState = CURRENT_STATES.VERIFY
			this.limitSMS = this.projectSettings.resendVerify.limit
			this.emailVerified = ''
			this.$emit('hide')
			this.$emit('hidden')
			this.$bvModal.hide(this.modalOptions.id)
		},
		startLoading() {
			this.loading = true
		},
		stopLoading() {
			this.loading = false
		},
		showResetPasswordModal() {
			this.$emit('showResetPasswordModal')
			this.resetForm()
		},
		showInvalidEmail() {
			this.currentState = CURRENT_STATES.INVALID
		},
		verifyEmailSuccess(email) {
			this.currentState = CURRENT_STATES.SUCCESS
			this.emailVerified = email
			this.limitSMS -= 1
			this.countDownResendOTP =
				this.projectSettings.resendVerify.delayTime
			this.countDownTimer()
		},
		async resendLink() {
			if (this.countDownResendOTP > 0) return

			const { code } =
				await this.$httpResources.repositories.auth.verifyEmailReset(
					this.emailVerified
				)
			if (code === STATUS_RESPONSES.SUCCESS) {
				this.countDownResendOTP =
					this.projectSettings.resendVerify.delayTime
				this.limitSMS -= 1
				this.countDownTimer()
			}
		},
		countDownTimer() {
			if (this.countDownResendOTP > 0) {
				setTimeout(() => {
					this.countDownResendOTP -= 1
					this.countDownTimer()
				}, 1000)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.guide-part {
	&.reset-link-part {
		margin-top: 0;
	}
}
</style>
