<template>
	<b-container class="px-0 h-100 mx-0 mw-100">
		<b-button
			size="sm"
			variant="outline"
			class="register-form-btn-close"
			@click="handleClickCloseButton()"
		>
			<img class="close-icon" :src="CLOSE_BUTTON" alt="#" />
		</b-button>
		<b-row class="h-100">
			<b-col
				cols="12"
				md="6"
				class="
					upload-avatar
					d-md-flex d-lg-flex
					flex-column
					justify-content-center
					align-items-center
					d-none
				"
				:style="{ backgroundImage: 'url(' + AVATAR_BACKGROUND + ')' }"
			>
				<div class="mb-5">
					<img
						:src="loadImageSrc(previewImage)"
						class="male_avatar-icon"
						alt="male-avatar"
						@click="$refs.profileImg.click()"
					/>
					<img
						:src="UploadIcon"
						class="upload-avatar-btn"
						alt="upload-avatar"
						@error="loadImageError"
						@click="$refs.profileImg.click()"
					/>
					<input
						id="image-file"
						ref="profileImg"
						style="display: none"
						accept="image/*"
						type="file"
						@change="handleEditImage"
					/>
				</div>
				<div class="d-inline-flex justify-content-center">
					<b-button
						class="gender-button male-button"
						:class="{ active: item.gender === 'male' }"
						@click="handleClickGenderButton('male')"
						>{{ $t('formModals.Male') }}</b-button
					>
					<b-button
						class="gender-button female-button"
						:class="{ active: item.gender === 'female' }"
						@click="handleClickGenderButton('female')"
						>{{ $t('formModals.Female') }}</b-button
					>
				</div>
				<div class="d-inline-flex justify-content-center mt-3">
					<b-button
						class="gender-button unselect-button"
						:class="{ active: item.gender === 'na' }"
						@click="handleClickGenderButton('na')"
						>{{ $t('formModals.Prefer not to say') }}</b-button
					>
				</div>
			</b-col>
			<b-col cols="12" md="6" class="register-form-body pt-5">
				<h1 class="register-form-body__header w-100 text-center">
					{{ $t('formModals.LETS GET HYPED') }}
				</h1>
				<div class="my-1 d-flex justify-content-center">
					<hr class="my-1 register-form-body__divider" />
				</div>
				<div class="my-1 d-flex justify-content-center text-white">
					<span>{{ $t('formModals.Already have an account') }}</span>
					&nbsp;
					<b-link
						class="text-white login-link"
						href="javascript:void(0);"
						@click="showLoginModal"
						>{{ $t('headers.login') }}</b-link
					>
				</div>

				<div class="register-form">
					<ValidationObserver ref="signUpForm" v-slot="{ invalid }">
						<b-alert
							:show="showFillOutAlert"
							variant="primary"
							class="fill-out-alert"
							>{{
								$t(
									'formModals.we re sorry for the __ press sign up'
								)
							}}</b-alert
						>
						<div class="register-form__wrapper">
							<label class="register-form__wrapper-label">{{
								$t('formModals.Full Name')
							}}</label>
							<BInputWithValidation
								v-model="item.fullName"
								type="text"
								:placeholder="$t(`formModals.Full Name`)"
								rules="required|min:3|max:100"
								:name="$t(`formModals.Full Name`)"
								vid="fullName"
								class="register-form__wrapper-input-group"
							/>
						</div>
						<div class="register-form__wrapper">
							<label class="register-form__wrapper-label">{{
								$t('formModals.Display Name')
							}}</label>
							<BInputWithValidation
								v-model="item.displayName"
								type="text"
								:placeholder="$t(`formModals.Display Name`)"
								rules="required|min:3|max:100"
								:name="$t(`formModals.Display Name`)"
								vid="displayName"
								class="register-form__wrapper-input-group"
							/>
						</div>
						<div class="register-form__wrapper">
							<label class="register-form__wrapper-label">{{
								$t('formModals.Email')
							}}</label>
							<BInputWithValidation
								ref="email"
								v-model.trim="item.email"
								autofocus
								type="text"
								:placeholder="$t(`formModals.Email address`)"
								rules="required|email"
								vid="email"
								:name="$t(`formModals.Email`)"
								class="register-form__wrapper-input-group"
							/>
						</div>
						<div
							v-if="!isSignUpWithSocial"
							class="register-form__wrapper"
						>
							<label class="register-form__wrapper-label">{{
								$t('formModals.Password')
							}}</label>
							<BInputWithValidation
								v-model="item.password"
								type="password"
								:placeholder="$t(`formModals.Password`)"
								rules="required|min:6|max:20"
								:name="$t(`formModals.Password`)"
								vid="password"
								:is-password-input-group="true"
								class="register-form__wrapper-input-group"
							/>
						</div>
						<div
							v-if="!isSignUpWithSocial"
							class="register-form__wrapper"
						>
							<label class="register-form__wrapper-label">{{
								$t('formModals.Confirm Password')
							}}</label>
							<BInputWithValidation
								v-model="item.confirmPassword"
								type="password"
								:placeholder="$t('formModals.Confirm Password')"
								rules="required|confirmed:password"
								:name="$t('formModals.Confirm Password')"
								vid="confirmPassword"
								:is-password-input-group="true"
								class="register-form__wrapper-input-group"
							/>
						</div>
						<div
							v-if="!isSignUpWithSocial"
							class="register-form__wrapper"
						>
							<label class="register-form__wrapper-label">{{
								$t('formModals.Date of Birth')
							}}</label>
							<b-input-group class="mb-1 dob-input-group">
								<BInputWithValidation
									v-model="item.doB"
									vid="doB"
									type="text"
									placeholder="DD-MM-YYYY"
									rules="required|date"
									autocomplete="off"
									:name="$t('formModals.Date of Birth')"
									class="register-form__wrapper-input-group"
								/>
								<b-input-group-append>
									<b-form-datepicker
										v-model="datePickerValue"
										button-only
										:show-decade-nav="true"
										right
										no-flip
										hide-header
										:locale="locale"
										class="
											register-form__wrapper-input-group
											dob-field
										"
									></b-form-datepicker>
								</b-input-group-append>
							</b-input-group>
						</div>
						<div
							v-if="!isSignUpWithSocial"
							class="register-form__wrapper"
						>
							<label class="register-form__wrapper-label">{{
								$t('formModals.Country Region')
							}}</label>
							<Select
								v-model="selected"
								:placeholder="$t(`formModals.Country Region`)"
								class="mb-4"
								:data-source="allCountries"
								data-text="name"
								data-value="dialCode"
								input-class="register-form__wrapper-input-group"
							/>
						</div>
						<div
							v-if="!isSignUpWithSocial"
							class="register-form__wrapper"
						>
							<label class="register-form__wrapper-label">{{
								$t('formModals.Contact number')
							}}</label>
							<BInputWithValidation
								ref="phoneNumber"
								v-model="item.phoneNumber"
								type="phone"
								:placeholder="$t(`formModals.Contact number`)"
								:prepend-text="getDialCode"
								:country-code="getCountryCode"
								class="mb-4 register-form__wrapper-input-group"
								:rules="`required|phone_number:${getCountryCode}`"
								:name="$t(`formModals.Contact number`)"
								vid="phone-number"
							/>
						</div>
						<Checkbox
							v-model="item.checkMarketing"
							variant="custom"
							label-class="checkbox__label policy-label"
							class="checkbox"
						>
							<template slot="label">
								<div
									class="register-policy-content text-white"
									v-html="
										$t(`formModals.registerPolicyContent`)
									"
								></div>
							</template>
						</Checkbox>
						<div
							class="text-center action-register"
							:class="actionClass"
						>
							<button
								type="button"
								:disabled="invalid || !item.checkMarketing"
								class="btn btn-primary btn-animated w-100"
								@click="
									isSignUpWithSocial
										? signUpWithSocialNetwork()
										: signUpWithEmail()
								"
							>
								{{ $t(`formModals.Sign Up`) }}
							</button>
						</div>
					</ValidationObserver>
				</div>
				<div class="register-social">
					<div
						class="
							register-social-description
							d-inline-flex
							w-100
							justify-content-center
							text-white
						"
					>
						<hr />
						<span>{{ $t('formModals.Or sign up with') }}</span>
						<hr />
					</div>
					<div
						class="
							mt-3
							register-social-icon
							d-inline-flex
							w-100
							justify-content-center
						"
					>
						<SignInWithGoogle
							class="register-social-logo"
							@onSuccess="signUpWithSocialNetwork"
						>
							<img :src="GoogleIcon" alt="#" />
						</SignInWithGoogle>
						<SignInWithApple @onSuccess="signUpWithSocialNetwork">
							<img
								:src="AppleIcon"
								class="register-social-logo"
								alt="#"
							/>
						</SignInWithApple>
						<SignInWithFacebook
							@onSuccess="signUpWithSocialNetwork"
						>
							<img
								:src="FacebookIcon"
								class="register-social-logo"
								alt="#"
							/>
						</SignInWithFacebook>
					</div>
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import BInputWithValidation from '@/components/BInputWithValidation'
import Checkbox from '@/components/Checkbox'
import SdkMixins from '@/mixins/SdkMixins'
import Select from '@/components/Select'
import { allCountries } from '@/assets/data/countries'
import AVATAR_BACKGROUND from '@/static/media/bg/register_avatar_bg.svg'
import MALE_AVATAR from '@/static/media/images/male_avatar.png'
import FEMALE_AVATAR from '@/static/media/images/female_avatar.png'
import UploadIcon from '@/static/media/icons/upload.svg'
import GoogleIcon from '@/static/media/images/logo_google.png'
import AppleIcon from '@/static/media/images/logo_apple.png'
import FacebookIcon from '@/static/media/images/logo_facebook.png'
import CLOSE_BUTTON from '@/static/media/icons/close.svg'

import {
	DEVICE_TYPES,
	DEVICE_UDID,
	SIGN_IN_TYPE,
	STATUS_RESPONSES,
} from '@/configs/SDK'
import { Encrypt } from '@/utils/AESCrypto'
import { STATUS_MESSAGES, SYSTEM_MESSAGES } from '@/messages/System'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash/lang'
import moment from 'moment'
import SignInWithFacebook from '~/components/register/SignInWithFacebook'
import SignInWithGoogle from '~/components/register/SignInWithGoogle'
import SignInWithApple from '~/components/register/SignInWithApple'

function defaultItem() {
	return {
		displayName: '',
		email: '',
		password: '',
		fullName: '',
		phoneNumber: '',
		checkMarketing: false,
		image: null,
		deviceUdid: DEVICE_UDID.WEB,
		deviceType: DEVICE_TYPES.WEB,
		gender: 'NA',
		clientToken: '',
		signInType: SIGN_IN_TYPE.EMAIL,
		doB: '',
	}
}

export default {
	name: 'RegisterForm',
	components: {
		BInputWithValidation,
		Checkbox,
		Select,
		SignInWithFacebook,
		SignInWithGoogle,
		SignInWithApple,
	},
	mixins: [SdkMixins],
	props: {
		actionClass: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		item: defaultItem(),
		selected: null,
		AVATAR_BACKGROUND,
		MALE_AVATAR,
		CLOSE_BUTTON,
		UploadIcon,
		previewImage: MALE_AVATAR,
		GoogleIcon,
		AppleIcon,
		FacebookIcon,
		socialUserInfo: {},
		step: 0,
		datePickerValue: '',
	}),
	computed: {
		...mapGetters(['country', 'locale']),
		allCountries() {
			return allCountries(this.$i18n.locale)
		},
		getDialCode() {
			return `${this.selected?.iso2 || 'SG'} +${
				this.selected?.dialCode || '65'
			}`
		},
		getCountryCode() {
			return `${this.selected?.iso2 || 'SG'}`
		},
		showFillOutAlert() {
			return this.step === 1
		},
		isSignUpWithSocial() {
			return this.step === 1 && !isEmpty(this.socialUserInfo)
		},
	},
	watch: {
		country(val) {
			this.selected =
				this.allCountries.find((x) => x.iso2 === val) || null
		},
		datePickerValue() {
			this.item.doB = moment(this.datePickerValue).format('DD-MM-YYYY')
		},
		item: {
			deep: true,
			handler() {
				this.item.doB = this.formatDate(this.item.doB)
			},
		},
	},
	mounted() {
		this.selected =
			this.allCountries.find((x) => x.iso2 === this.country) || null
	},
	methods: {
		async signUpWithEmail() {
			const isValid = await this.$refs.signUpForm.validate()
			if (!isValid) return
			this.loading = true
			const defaultParams = {
				beliveId: this.item.email,
				email: this.item.email,
				password: Encrypt(this.item.password),
				displayName: this.item.displayName,
				fullName: this.item.fullName,
				phoneCountryPrefix: this.selected.dialCode,
				phoneNumber: this.item.phoneNumber,
				deviceUdid: this.item.deviceUdid,
				checkMarketing: this.item.checkMarketing,
				deviceType: parseInt(this.item.deviceType),
				image: this.item.image,
				gender: this.item.gender,
				signInType: SIGN_IN_TYPE.EMAIL,
				doB: this.item.doB,
			}
			const { code, codeDetails, message } = await this.register(
				this.convertJSONToFormData(defaultParams)
			)
			if (code === STATUS_RESPONSES.SUCCESS) {
				this.$notification.s(
					SYSTEM_MESSAGES.REGISTER_SUCCESS,
					STATUS_MESSAGES.SUCCESS
				)
				this.$emit('show-register-confirmation', this.item.email)
			} else if (code === STATUS_RESPONSES.USER_NOT_VERIFY) {
				this.$emit('showResendVerifyEmail', this.item.email)
			} else {
				this.handleFormError(code, codeDetails, message)
			}
			this.loading = false
		},
		handleFormError(code, codeDetails, message) {
			if (code === STATUS_RESPONSES.INVALID_MODEL && codeDetails) {
				codeDetails.forEach((codeDetail) => {
					if (
						codeDetail.code ===
						STATUS_RESPONSES.DISPLAY_NAME_REQUIRED
					) {
						this.$refs.signUpForm.setErrors({
							displayName: [message],
						})
					}
				})
			}
		},
		handleFinishSignUp() {
			this.resetForm()
			this.$emit('finish-register')
		},
		resetForm() {
			this.item = defaultItem()
			this.resetValidate()
		},
		resetValidate() {
			requestAnimationFrame(() => {
				this.$refs.signUpForm?.reset()
			})
		},
		handleEditImage(e) {
			const files = e.target.files || e.dataTransfer.files
			if (!files.length) return
			this.createImage(files[0])
			// await this.$refs.imageProvider.validate(files[0]);
		},
		createImage(file) {
			const reader = new FileReader()
			const _this = this
			reader.onload = (e) => {
				_this.previewImage = e.target.result
			}
			_this.item.image = file
			reader.readAsDataURL(file)
		},
		handleClickGenderButton(val) {
			this.item.gender = val
			if (
				this.previewImage === MALE_AVATAR ||
				this.previewImage === FEMALE_AVATAR
			) {
				this.previewImage =
					val === 'female' ? FEMALE_AVATAR : MALE_AVATAR
			}
		},
		async signUpWithSocialNetwork(user, signInType) {
			if (this.step === 0) {
				const response = await this.tryToLoginWithSNSInfo(
					user,
					signInType
				)
				if (response.code === STATUS_RESPONSES.SUCCESS) {
					this.$notification.s(
						SYSTEM_MESSAGES.LOGIN_SUCCESS,
						STATUS_MESSAGES.SUCCESS
					)
					this.$emit('closeModal')
				} else {
					this.socialUserInfo = user
					this.item.signInType = signInType

					if (user.email && user.name) {
						this.loading = true
						const defaultParams = {
							beliveId: this.getBeliveIdBySocialNetWork(
								user,
								signInType
							),
							email: user.email,
							displayName: user.name,
							fullName: user.name,
							deviceUdid: this.item.deviceUdid,
							checkMarketing: true,
							deviceType: parseInt(this.item.deviceType),
							signInType,
							clientToken: user.accessToken,
						}
						await this.handleSignUpWithSNS(defaultParams)
					} else {
						this.item.email = user.email
						this.item.displayName = user.name
						this.item.fullName = user.name
						this.item.clientToken = user.accessToken
						this.item.beliveId = this.getBeliveIdBySocialNetWork(
							user,
							signInType
						)
						this.step = 1
					}
				}
			} else {
				const isValid = await this.$refs.signUpForm.validate()
				if (!isValid) return
				this.loading = true
				const defaultParams = {
					beliveId: this.item.beliveId ?? this.item.email,
					email: this.item.email,
					displayName: this.item.displayName,
					fullName: this.item.fullName,
					deviceUdid: this.item.deviceUdid,
					checkMarketing: true,
					deviceType: parseInt(this.item.deviceType),
					signInType: this.item.signInType,
					clientToken: this.item.clientToken,
					image: this.item.image,
					gender: this.item.gender,
				}
				await this.handleSignUpWithSNS(defaultParams)
			}
		},
		showLoginModal() {
			this.$emit('showLoginModal')
		},
		handleClickCloseButton() {
			this.$emit('closeModal')
		},
		async handleSignUpWithSNS(request) {
			const { code, codeDetails, message } = await this.register(
				this.convertJSONToFormData(request)
			)

			if (code === STATUS_RESPONSES.SUCCESS) {
				this.$notification.s(
					SYSTEM_MESSAGES.REGISTER_SUCCESS,
					STATUS_MESSAGES.SUCCESS
				)
				await this.login(request, {
					keepLoggedIn: this.keepLoggedIn,
				})
				this.$emit('closeModal')
			} else if (code === STATUS_RESPONSES.USER_NOT_VERIFY) {
				this.$emit('showResendVerifyEmail', request.email)
			} else if (
				code === STATUS_RESPONSES.EMAIL_EXISTED ||
				code === STATUS_RESPONSES.SIGNUP_HYPERSHOP_FAILED ||
				code === STATUS_RESPONSES.CANNOT_CREATE_USER ||
				code === STATUS_RESPONSES.ACCOUNT_HAS_ALREADY_BEEN_REGISTERED
			) {
				this.$emit('closeModal')
			} else {
				this.handleFormError(code, codeDetails, message)
			}
			this.loading = false
		},
		async tryToLoginWithSNSInfo(user, signInType) {
			this.$emit('start-loading')
			try {
				const { code, message } = await this.login(
					{
						beliveId: this.getBeliveIdBySocialNetWork(
							user,
							signInType
						),
						deviceType: parseInt(DEVICE_TYPES.WEB),
						deviceUdid: this.item.deviceUdid,
						latitude: 0,
						longitude: 0,
						signInType,
						clientToken: user.accessToken,
					},
					{ keepLoggedIn: false }
				)
				return { code, message }
			} catch (error) {}
			this.$emit('stop-loading')
		},
		formatDate(str) {
			const cleaned = `${str}`.replace(/\D/g, '')
			const match = cleaned.match(/^(\d{2})(\d{2})(\d{4})$/)

			if (match) {
				return `${match[1]}-${match[2]}-${match[3]}`
			}

			return str
		},
	},
}
</script>

<style lang="scss" scoped>
.upload-avatar {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	.male_avatar-icon {
		width: 8rem;
		height: 8rem;
		border-radius: 50%;
		background-color: #fff;
		border: 1px solid $color-red-2;
		cursor: pointer;
	}
	.upload-avatar-btn {
		position: absolute;
		width: 3rem;
		bottom: 55%;
		left: 54%;
		cursor: pointer;
	}
	.gender-button {
		width: 10rem;
		border-radius: 10px;
		border: solid 3px #fba100;
		background-color: #000;
		margin: 0 1rem;
		&.male-button.active {
			background-color: #fba101;
		}
		&.female-button.active {
			background-color: $color-red-2;
		}
		&.unselect-button {
			width: 22rem;
			&.active {
				background-color: #151618;
			}
		}
	}
}

.register-form-body {
	font-family: $font-fira-sans;
	margin-bottom: 3rem;
	@include respond(mobile) {
		margin-bottom: 1rem;
	}
	&__header {
		color: #fe0;
	}
	&__divider {
		width: 15%;
		height: 5px;
		border-radius: 5px;
		background-color: #f9514f;
	}
	.register-form {
		.fill-out-alert {
			font-size: 1.5rem;
			text-align: center;
		}
		&__wrapper {
			&-label {
				color: #fff;
			}
			&-input-group {
				&.dob-field::v-deep {
					height: 3rem;
					align-items: center;
					border: none;
					border-radius: 4px;
					background-color: #efefef;
					margin-bottom: 1rem;
					.form-control {
						font-size: 14px;
						font-weight: 500;
						@include respond(mobile) {
							font-size: 12px;
						}
					}
					.bi-calendar,
					.bi-calendar-fill {
						font-size: 14px;
					}
					button {
						margin-top: 5px;
						padding-top: 7px;
					}
					.b-calendar {
						touch-action: manipulation;
						.b-calendar-grid-weekdays {
							&.row {
								margin-right: 0;
								margin-left: 0;
							}
							&.no-gutters {
								.col {
									padding-right: 0;
									padding-left: 0;
								}
							}
						}
						.b-calendar-grid-body {
							.no-gutters {
								&.row {
									margin-right: 0;
									margin-left: 0;
								}
							}
							.col[data-date] .btn {
								width: 32px;
								text-align: center;
							}
						}
					}
				}
				.input-group {
					border-radius: 10px;
				}
			}
			.dob-input-group {
				.register-form__wrapper-input-group {
					width: 100%;
				}
				.input-group-append {
					position: absolute;
					right: 0;
					z-index: 3;
				}
			}
		}
	}
	.login-link {
		text-decoration: underline !important;
	}
}

.register-social {
	.register-social-description {
		hr {
			width: 20%;
			background-color: #fff;
			margin: auto 1rem;
		}
		span {
			font-style: italic;
		}
	}
	.register-social-logo {
		width: 3.5rem;
		margin: auto 1rem;
		cursor: pointer;
	}
}

.action-register {
	margin-top: 1rem;
	margin-bottom: 1rem;
	.btn {
		background-color: #de006a;
	}
}

.register-policy-content::v-deep {
	color: #fff;
	p {
		margin-bottom: 0;
	}
	a {
		color: #fe0;
	}
}

.register-form-btn-close {
	position: absolute;
	padding-right: 0.7rem !important;
	z-index: 2;
	right: -2.4rem;
	top: 0;
}
</style>
