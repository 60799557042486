<template>
	<BeModal
		:modal-options="modalOptions"
		:no-close-button="noCloseButton"
		:loading="loading"
		:title="loginModal.title"
		:sub-title="loginModal.subTitle"
		:logo="loginModal.logo"
		:full-size-content="!emailNeedVerify"
		@hidden="resetForm"
	>
		<template v-if="!emailNeedVerify" #default>
			<LoginForm
				ref="loginFormComponent"
				@closeModal="resetForm"
				@showForgotPasswordModal="showForgotPasswordModal"
				@log-in-success="loginSuccess"
				@reset-form="resetForm"
				@start-loading="startLoading"
				@stop-loading="stopLoading"
				@showResendVerifyEmail="showResendVerifyEmail"
				@showRegisterModal="showRegisterModal"
			/>
		</template>
		<div v-else class="text-center guide-part reset-link-part">
			<div
				class="guide-part__text"
				v-html="
					$t(
						'formModals.Please check your email _xxx_ for verification link',
						{ email: emailNeedVerify }
					)
				"
			></div>
			<div v-if="limitSMS > 0" class="resend-wrapper text-center">
				<span class="hint hint--info">{{
					$t('formModals.Didnt receive a link')
				}}</span>
				<span
					class="hint hint--info hint--clickable ml-2"
					:class="countDownResendOTP > 0 ? 'disabled' : ''"
					@click="resendLink"
					>{{ $t('formModals.Resend') }}</span
				>
				<span
					v-if="countDownResendOTP > 0"
					class="hint hint--info hint--countdown"
					>&nbsp;({{ countDownResendOTP }}s)</span
				>
			</div>
		</div>
	</BeModal>
</template>

<script>
import LoginForm from '@/components/forms/LoginForm'
import BeModal from '@/components/modals/BeModal'
import { DEFAULT_ICON_STATES } from '@/configs/Icons'
import { STATUS_RESPONSES } from '@/configs/SDK'
import { mapGetters } from 'vuex'

const VERIFY_MODAL_OPTIONS = {
	id: 'login-modal',
	class: 'reset-password-modal-custom',
	dialogClass: 'reset-password-modal',
	headerClass: 'reset-password-modal__header',
	contentClass: 'reset-password-modal__content',
	bodyClass: 'reset-password-modal__body',
}

export const LOGIN_MODAL_OPTIONS = {
	id: 'login-modal',
	class: 'login-modal-custom',
	dialogClass: 'login-modal',
	headerClass: 'login-modal__header',
	contentClass: 'login-modal__content',
	bodyClass: 'login-modal__body',
}

export default {
	name: 'LoginModal',
	components: {
		BeModal,
		LoginForm,
	},
	mixins: [],
	props: {
		open: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		DEFAULT_LOGO: DEFAULT_ICON_STATES.logo.activeSrc,
		CHECK_EMAIL_ICON: DEFAULT_ICON_STATES.checkEmail.activeSrc,
		loading: false,
		loginModal: {
			title: '',
			subTitle: '',
			logo: '',
		},
		modalOptions: { ...LOGIN_MODAL_OPTIONS },
		noCloseButton: false,
		limitSMS: 5,
		countDownResendOTP: 0,
		emailNeedVerify: '',
	}),
	computed: {
		...mapGetters({
			projectSettings: 'projectSettings',
		}),
	},
	watch: {
		open(val) {
			if (val) this.$bvModal.show(this.modalOptions.id)
		},
	},
	mounted() {
		this.limitSMS = this.projectSettings.resendVerify.limit
		if (this.open) this.$bvModal.show(this.modalOptions.id)
	},
	methods: {
		resetForm() {
			this.loginModal = {
				...{
					title: '',
					subTitle: '',
					logo: this.DEFAULT_LOGO,
				},
			}
			this.modalOptions = { ...LOGIN_MODAL_OPTIONS }
			this.emailNeedVerify = ''
			this.limitSMS = this.projectSettings.resendVerify.limit
			this.$emit('hide')
			this.$emit('hidden')
			this.$bvModal.hide(this.modalOptions.id)
		},
		startLoading() {
			this.loading = true
		},
		stopLoading() {
			this.loading = false
		},
		loginSuccess() {
			this.resetForm()
		},
		showForgotPasswordModal() {
			this.$emit('showForgotPasswordModal')
			this.resetForm()
		},
		showResendVerifyEmail(email) {
			this.loginModal = {
				...{
					title: this.$t('formModals.Your account is not verified'),
					subTitle: '',
					logo: this.CHECK_EMAIL_ICON,
				},
			}
			this.modalOptions = { ...VERIFY_MODAL_OPTIONS }
			this.emailNeedVerify = email
			this.showLoginForm = false
		},
		async resendLink() {
			if (this.countDownResendOTP > 0) return

			const { code } =
				await this.$httpResources.repositories.auth.resendVerifyEmail(
					this.emailNeedVerify
				)
			if (code === STATUS_RESPONSES.SUCCESS) {
				this.countDownResendOTP =
					this.projectSettings.resendVerify.delayTime
				this.limitSMS -= 1
				this.countDownTimer()
			}
		},
		countDownTimer() {
			if (this.countDownResendOTP > 0) {
				setTimeout(() => {
					this.countDownResendOTP -= 1
					this.countDownTimer()
				}, 1000)
			}
		},
		showRegisterModal() {
			this.$emit('handleShowRegisterModal')
		},
	},
}
</script>

<style lang="scss" scoped>
.checkbox {
	font-size: 1.3rem;
	color: $color-grey-light-3;
	padding-top: 0.2rem;
	margin-left: 0.5rem;
	margin-top: 1.5rem;
}
.forgot-pwd {
	color: $color-primary !important;
	font-family: $font-primary;
	font-size: 1.6rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	&:hover {
		text-decoration: underline !important;
	}
}
.action-login {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.error-text {
	font-size: 80%;
	color: #dc3545;
}
.guide-part {
	margin-top: 0;
	&__email {
		font-weight: bold;
	}
}
</style>
