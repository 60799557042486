/* eslint-disable no-unused-vars */
import Vue from "vue";

Vue.directive("touch-start", {
    bind: function(el, binding, vnode) {
        el.touchstart = (event) => {
            let t2 = event.timeStamp
                , t1 = el.dataset.lastTouch || t2
                , dt = t2 - t1
                , fingers = event.touches.length;
            el.dataset.lastTouch = t2;
            if (!dt || dt > 500 || fingers > 1) {
                if (typeof binding.value === "function") {
                    binding.value(el);
                }
            } // not double-tap
            else {
                console.log("double tap");
                event.preventDefault(); // double tap - prevent the zoom
            }
        };
        el.addEventListener("touchstart", el.touchstart);
    },
    unbind: function(el) {
        el.removeEventListener("touchstart", el.touchstart);
    }
});

Vue.directive("touch-end", {
    bind: function(el, binding, vnode) {
        el.touchend = (event) => {
            if (typeof binding.value === "function") {
                binding.value(el);
            }
        };
        el.addEventListener("touchend", el.touchend);
    },
    unbind: function(el) {
        el.removeEventListener("touchend", el.touchend);
    }
});