<template>
	<ValidationProvider
		ref="provider"
		v-slot="{ valid, errors }"
		tag="div"
		class="form-group"
		:mode="mode"
		:vid="vid"
		:name="$attrs.name"
		:rules="rules"
	>
		<label v-if="label" :for="vid">{{ label }}</label>
		<slot name="prepend"></slot>
		<div v-if="!isPasswordInputGroup" class="input-group phone-number">
			<div
				v-if="prependText"
				class="input-group-prepend phone-number__prepend"
			>
				<div
					class="input-group-text phone-number__prepend-text"
					:class="{
						'phone-number__prepend-text--focus': inputFocus,
						'phone-number__prepend-text--invalid':
							rules && errors[0],
						'phone-number__prepend-text--valid':
							rules && !errors[0] && valid,
					}"
				>
					<span>{{ prependText }}</span>
				</div>
			</div>
			<textarea
				v-if="multiline"
				v-bind="$attrs"
				:id="vid"
				ref="input"
				v-model="localValue"
				style="resize: none"
				:type="getInputType"
				:placeholder="placeholder"
				class="form-control"
				:class="{
					'phone-number__input': prependText,
					'is-invalid': rules && errors[0],
					'is-valid': showValidBorder && rules && !errors[0] && valid,
					[inputClass]: true,
				}"
				@focus="handleInputFocus"
				@blur="handleInputBlur"
			>
			</textarea>
			<input
				v-else
				v-bind="$attrs"
				:id="vid"
				ref="input"
				v-model="localValue"
				:type="getInputType"
				:placeholder="placeholder"
				class="form-control"
				:class="{
					'phone-number__input': prependText,
					'is-invalid': rules && errors[0],
					'is-valid': showValidBorder && rules && !errors[0] && valid,
					[inputClass]: true,
				}"
				:disabled="disabled"
				@focus="handleInputFocus"
				@blur="handleInputBlur"
			/>
			<transition name="slideInDown">
				<div
					v-if="errors[0]"
					:class="`${valid ? 'valid' : 'invalid'}-feedback`"
				>
					{{ errors[0] }}
				</div>
			</transition>
		</div>
		<div v-else class="input-group password-group">
			<div
				class="be-password-field"
				:class="{
					'is-invalid': rules && errors[0],
					'is-valid': showValidBorder && rules && !errors[0] && valid,
				}"
			>
				<input
					v-bind="$attrs"
					:id="vid"
					ref="input"
					v-model="localValue"
					class="form-control"
					:type="passwordFieldType"
					:placeholder="placeholder"
					@focus="handleInputFocus"
					@blur="handleInputBlur"
				/>
				<div
					class="input-group-append append-icon-show"
					@click="showHidePassword"
				>
					<img
						class="password-show-hide"
						:src="iconShowHide[passwordFieldType]"
						alt="show-hide-password"
					/>
				</div>
			</div>
			<transition name="slideInDown">
				<div
					v-if="errors[0]"
					:class="`${valid ? 'valid' : 'invalid'}-feedback`"
				>
					{{ errors[0] }}
				</div>
			</transition>
		</div>
		<slot name="append"></slot>
	</ValidationProvider>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { AsYouType, parsePhoneNumber, ParseError } from 'libphonenumber-js'
import { DEFAULT_ICON_STATES } from '@/configs/Icons'

export default {
	name: 'BInputWithValidation',
	props: {
		isPasswordInputGroup: {
			type: Boolean,
			default: false,
		},
		showValidBorder: {
			type: Boolean,
			default: true,
		},
		mode: {
			type: String,
		},
		type: {
			type: String,
		},
		prependText: {
			type: String,
		},
		value: {
			type: String,
			default: '',
		},
		countryCode: {
			type: String,
		},
		placeholder: {
			type: String,
		},
		label: {
			type: String,
			default: '',
		},
		vid: {
			type: String,
			default: '',
		},
		rules: {
			type: String,
			default: '',
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		multiline: {
			type: Boolean,
			default: false,
		},
		inputClass: {
			type: String,
			default: '',
		},
		iconShowHide: {
			type: Object,
			default() {
				return {
					text: DEFAULT_ICON_STATES.eyeOff.activeSrc,
					password: DEFAULT_ICON_STATES.eyeOn.activeSrc,
				}
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		inputFocus: false,
		passwordFieldType: 'password',
	}),
	computed: {
		getInputType() {
			if (['phone', 'number'].includes(this.type)) return 'text'
			return this.type
		},
		localValue: {
			get() {
				return this.type === 'phone'
					? new AsYouType(this.countryCode).input(this.value)
					: this.value
			},
			set(val) {
				let newVal = ''
				if (this.type === 'phone') {
					try {
						const phoneNumber = parsePhoneNumber(
							val,
							this.countryCode
						)
						newVal = phoneNumber.nationalNumber
					} catch (error) {
						newVal = val
					}
				} else {
					newVal = val
				}
				this.$emit('input', newVal)
			},
		},
	},
	watch: {},
	methods: {
		focus(e) {
			this.$emit('focus', e)
			this.$refs.input.focus()
		},
		handleInputFocus() {
			this.inputFocus = true
		},
		handleInputBlur(e) {
			this.$emit('blur', e)
			this.inputFocus = false
			if (this.mode === 'passive' && this.type === 'datepicker') {
				setTimeout(
					async () => await this.$refs.provider.validate(),
					100
				)
				this.$refs.provider.setFlags({ dirty: true })
			}
		},
		showHidePassword() {
			if (this.passwordFieldType === 'password')
				this.passwordFieldType = 'text'
			else this.passwordFieldType = 'password'
		},
	},
}
</script>

<style lang="scss" scoped>
.form-group {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: none;
	border-radius: 4px;
	background-color: #efefef;
	margin-bottom: 1rem;
	.input-group {
		background: white;
		.form-control {
			background-color: #efefef;
			border: none;
			border-radius: 4px;
			@include respond(mobile) {
				font-size: 16px;
			}

			&:disabled {
				background-color: #e9ecef;
			}

			&::-webkit-input-placeholder {
				/* Edge */
				font-family: $font-secondary;
				font-size: 1.4rem;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
			}

			&:-ms-input-placeholder {
				/* Internet Explorer */
				font-family: $font-secondary;
				font-size: 1.4rem;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
			}

			&::placeholder {
				font-family: $font-secondary;
				font-size: 1.4rem;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
			}
		}
	}
	&.register-form__wrapper-input-group {
		.input-group {
			border-radius: 10px;
		}
	}
	&.login-form__wrapper-input-group {
		.input-group {
			border-radius: 10px;
		}
	}
}

.phone-number {
	&__input {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		border-left: none;
		padding-left: 1rem;

		&:focus {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
			border-left: none;
		}
	}

	&__prepend {
		margin-right: 0;
	}

	&__prepend-text {
		font-family: $font-secondary;
		background-color: #efefef;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border: none;
		border-right: none;
		color: $color-grey-dark-4;
		font-size: 1.3rem;
		padding: 0 1.5rem;
		padding-right: 0.5px;
		padding-top: 0.1rem;
		outline: none;

		&--focus {
			border: solid 1px $color-primary;
			border-right: none;
		}

		&--invalid {
			border: none;
			border-right: none;
			span {
				&::after {
					border-right: 1px solid $danger !important;
				}
			}
		}

		&--valid {
			border: none;
			border-right: none;
			span {
				&::after {
					border-right: 1px solid $success !important;
				}
			}
		}

		span {
			display: flex;
			align-items: center;
			align-self: stretch;
			outline: none;

			&::after {
				content: ' ';
				width: 1rem;
				height: 1.5rem;
				border-right: 1px solid $color-primary;
				display: inline-block;
			}
		}
	}
}

.password-group {
	.be-password-field {
		width: 100%;
		display: flex;
		align-items: center;
		background: white;
		border-radius: 1rem;
		input {
			background: transparent;
			border: none;
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
		.input-group-append {
			cursor: pointer;
			height: 100%;
			margin-left: auto;
			align-items: center;
			position: relative;
			padding: 0 1rem;
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;
			background-color: #efefef;
			img {
				width: 100%;
				height: 100%;
				max-width: 1.8rem;
				max-height: 1.8rem;
			}
		}
	}
}

.slideInDown-enter-active {
	animation: slideInDown 0.2s;
}
.slideInDown-leave-active {
	animation: slideInDown 0.2s reverse;
}

.label {
	font-family: $font-primary;
	font-size: 1.8rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #231f20;
}
</style>
