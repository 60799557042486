import { SDK_API } from '@/configs/APIs'
const POLL_APIS = SDK_API.POLL;

export default ($axios) => ({
    /**
     * Get current poll
     * multipart/form-data
     * @param {streamId} dataParams -> multipart/form-data
     */
    async getCurrent(dataParams) {
        const {
            data: { code, data, message },
        } = await $axios.post(POLL_APIS.CURRENT, dataParams, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        return { code, data, message }
    },

    /**
     * Answer poll
     * @param {*} param
     */
    async answer({ streamId, pollId, answer }) {
        const {
            data: { code, data, message },
        } = await $axios.post(POLL_APIS.ANSWER, {
            answer: answer,
            streamId: streamId,
            pollId: pollId
        })
        return { code, data, message }
    },

    /**
     * Get the result of poll
     * multipart/form-data
     * @param {pollId} dataParams -> multipart/form-data
     */
    async result(dataParams) {
        const {
            data: { code, data, message },
        } = await $axios.post(POLL_APIS.RESULT, dataParams, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        return { code, data, message }
    },

    /**
     * Get all the polls in REC
     * multipart/form-data
     * @param {streamId} dataParams -> multipart/form-data
     */
    async all(dataParams) {
        const {
            data: { code, data, message },
        } = await $axios.post(POLL_APIS.ALL, dataParams, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        return { code, data, message }
    },
})
