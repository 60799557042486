import { SDK_API } from '@/configs/APIs'
const EVENT_APIS = SDK_API.EVENT
const BANNER_APIS = SDK_API.BANNERS
const VOUCHER_APIS = SDK_API.VOUCHER

export default ($axios) => ({
	/**
	 * Get all upcoming event
	 * @param {*} nextId
	 * @param {*} limit
	 */
	async allUpcoming(nextId = 0, limit = 10) {
		const {
			data: { code, data, message },
		} = await $axios.post(EVENT_APIS.ALL_UPCOMING, {
			nextId,
			limit,
		})
		return { code, data, message }
	},

	/**
	 * Get all upcoming event
	 * @param {*} nextId
	 * @param {*} limit
	 */
	async allRecs(nextId = 0, limit = 10) {
		const {
			data: { code, data, message },
		} = await $axios.post(EVENT_APIS.ALL_REC, {
			nextId,
			limit,
		})
		return { code, data, message }
	},

	/**
	 * Get event detail
	 * @param {*} param
	 */
	async detail({ eventId }) {
		const {
			data: { code, data, message },
		} = await $axios.post(EVENT_APIS.DETAIL, {
			eventId,
		})
		return { code, data, message }
	},

	/**
	 * Buy event ticket
	 * @param {*} param
	 */
	async buyTicket({ eventId }) {
		const {
			data: { code, data, message },
		} = await $axios.post(EVENT_APIS.BUY_TICKET, {
			eventId,
		})
		return { code, data, message }
	},

	/**
	 * Get current banners
	 */
	async currentBanners() {
		const {
			data: { code, data, message },
		} = await $axios.get(BANNER_APIS.CURRENT)
		return { code, data, message }
	},

	/**
	 * Voucher
	 */
	async applyVoucher({ voucher, type, eventId }) {
		const {
			data: { code, data, message },
		} = await $axios.post(VOUCHER_APIS.APPLY, {
			code: voucher,
			type,
			relatedId: eventId,
		})
		return { code, data, message }
	},

	/**
	 * Detail voucher
	 */
	async detailVoucher({ voucher }) {
		const {
			data: { code, data, message },
		} = await $axios.post(VOUCHER_APIS.DETAIL, {
			code: voucher,
		})
		return { code, data, message }
	},
})
