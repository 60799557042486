import { AUTH_ROUTES, COOKIE_KEYS } from '@/configs/Settings'
import { HEADER_KEYS, SIGN_IN_TYPE, STATUS_RESPONSES } from '@/configs/SDK'
import { mapActions } from 'vuex'

export default {
	name: 'SdkMixin',
	mixins: [],
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions({
			userLogin: 'userLogin',
			updateUserInfo: 'updateUserInfo',
			guestLogin: 'guestLogin',
			getProfile: 'getProfile',
		}),
		async logout() {
			this.$httpResources.setToken(false)
			this.$httpResources.setHeader(
				HEADER_KEYS.HYPERSHOP_ACCESS_TOKEN,
				''
			)
			await this.$cookies.remove(COOKIE_KEYS.GUEST_TOKEN)
			await this.$cookies.remove(COOKIE_KEYS.ACCESS_TOKEN)
			await this.$cookies.remove(COOKIE_KEYS.HYPERSHOP_ACCESS_TOKEN)
			await this.userLogin(null)
			await this.guestLogin()
			this.$root.$emit('guest-change')
			this.$root.$emit('guest-change-event')
			try {
				this.$nextTick(() => {
					if (AUTH_ROUTES.includes(this.$route.name)) {
						this.$router.push('/') // Go home
					}
				})
			} catch (error) {
				console.log('Guest login error', error.toString())
			}
		},

		/**
		 *
		 * @param {*} dataParams
		 * @param {*} option
		 */
		async login(dataParams, option) {
			if (!dataParams) return
			const {
				code,
				data: userData,
				message,
			} = await this.$httpResources.repositories.auth.login(dataParams)
			if (code === STATUS_RESPONSES.SUCCESS && userData) {
				this.$httpResources.setToken(userData.accessToken, 'Bearer')
				this.$httpResources.setHeader(
					HEADER_KEYS.HYPERSHOP_ACCESS_TOKEN,
					userData.hShopAccessToken
				)

				this.$cookies.remove(COOKIE_KEYS.GUEST_TOKEN)

				const cookieOption = option.keepLoggedIn
					? {
							path: '/',
							maxAge: 60 * 60 * 24 * 365, // 1 year
					  }
					: {
							path: '/',
					  }

				this.$cookies.set(
					COOKIE_KEYS.ACCESS_TOKEN,
					userData.accessToken,
					cookieOption
				)
				this.$cookies.set(
					COOKIE_KEYS.HYPERSHOP_ACCESS_TOKEN,
					userData.hShopAccessToken,
					cookieOption
				)
				await this.userLogin(userData)
				this.getProfile({
					userId: userData.userInfo.userId,
					userName: userData.userInfo.userName,
				})
				this.$nextTick(() => {
					this.$root.$emit('user-change')
					this.$root.$emit('user-change-event')
				})
			}
			return { code, data: userData, message }
		},

		/**
		 *
		 * @param {*} dataParams
		 */
		async register(dataParams) {
			if (!dataParams) return
			// var formData = this.convertJSONToFormData(dataParams)
			const { code, message, codeDetails } =
				await this.$httpResources.repositories.auth.register(dataParams)
			return { code, message, codeDetails }
		},

		getBeliveIdBySocialNetWork(user, signInType) {
			switch (signInType) {
				case SIGN_IN_TYPE.FACEBOOK:
					return user.userID
				case SIGN_IN_TYPE.GOOGLE:
					return user.id
				case SIGN_IN_TYPE.APPLE:
					return user.userId
				default:
					return user.email
			}
		},
	},
}
