<template>
	<div class="main-header base">
		<client-only>
			<b-navbar
				toggleable="sm"
				type="dark"
				variant="navbar"
				class="bg-navbar pt-sm-3 pt-md-3 pt-lg-3 pb-0 d-none d-md-block"
			>
				<b-container>
					<!-- Content here -->
					<b-navbar-brand
						href="javascript:window.location.replace('/')"
					>
						<img src="@/static/media/generals/default-logo.svg" />
					</b-navbar-brand>

					<b-navbar-toggle
						target="nav-collapse"
						class="p-2"
					></b-navbar-toggle>

					<b-collapse
						id="nav-collapse"
						is-nav
						class="ml-auto justify-content-end"
					>
						<!-- Right aligned nav items -->
						<b-navbar-nav class="ml-xs-5 mr-0" align="right">
							<b-nav-item
								href="#"
								class="
									mr-3
									web-to-app-banner
								"
								link-classes="p-0"
							>
								<b-nav-item-dropdown
									right
									menu-class="shadow"
									class="text-center"
								>
									<!-- Using 'button-content' slot -->
									<template #button-content class="m-0">
										<p
											class="
												text-white
												mb-0
												text-transform-none
												header-display-name
											"
										>
											{{ $t('headers.Download Now') }}
										</p>
									</template>

									<b-list-group class="p-4">
										<b-list-group-item
											class="
												d-flex
												align-items-center
												border-0
												text-capitalize
											"
											@click="handleClickUrl(projectSettings.general.iosAppUrl)"
										>
											<b-img
												:src="DEFAULT_ICON_STATES.appleIcon.activeSrc"
												class="mr-3 bg-transparent app-store-logo"
											></b-img>
											<span class="mr-auto">{{
													$t('headers.App Store')
												}}</span>
										</b-list-group-item>
										<b-list-group-item
											class="
												d-flex
												align-items-center
												border-0
												text-capitalize
											"
											@click="handleClickUrl(projectSettings.general.androidAppUrl)"
										>
											<b-img
												:src="DEFAULT_ICON_STATES.googlePlayIcon.activeSrc"
												class="mr-3 bg-transparent app-store-logo"
												width="14px"
											></b-img>
											<span class="mr-auto">{{
													$t('headers.Google Play')
												}}</span>
										</b-list-group-item>
										<b-list-group-item
											class="
												d-flex
												flex-column
												align-items-center
												border-right-0
												border-left-0
												border-top
												border-bottom-0
												text-transform-none
											"
										>
											<b-img
												alt=""
												:src="projectSettings.general.qrCodeUrl"
												class="w-100"
											/>
											<span class="mr-auto">{{
													$t('headers.Scan to Download')
												}}</span>
										</b-list-group-item>
									</b-list-group>
								</b-nav-item-dropdown>
							</b-nav-item>
							<b-nav-item-dropdown
								v-if="selectedLang"
								class="btn nav-item-dropdown nav-lang-dropdown"
								right
							>
								<template #button-content>
									<div class="nav-lang">
										<div
											class="
												nav-lang--country
												iti-flag
												flag-item
											"
											:class="[selectedLang.class]"
										></div>
										<div class="nav-lang--code">
											{{ selectedLang.shortCode }}
										</div>
									</div>
								</template>
								<b-dropdown-item
									v-for="lang in availableLocales"
									:key="`lang-${lang.code}`"
									href="javascript:void(0)"
									class="
										d-flex
										align-items-center
										dropdown__item
										mr-4
									"
									@click.prevent.stop="
										handleSelectedLang(lang)
									"
								>
									<div class="nav-lang-dropdown__item">
										<div
											:class="`nav-lang-dropdown--country iti-flag flag-item ${lang.class}  shadow`"
										></div>
										{{ lang.label }}
									</div>
								</b-dropdown-item>
							</b-nav-item-dropdown>

							<b-button
								v-if="!isLoggedIn"
								href="javascript:void(0)"
								class="btn bg-d-color w-100 px-4 my-3 mr-4"
								block
								@click="handleShowRegisterModal"
								>{{ $t('headers.register') }}</b-button
							>
							<b-button
								v-if="!isLoggedIn"
								href="javascript:void(0)"
								class="btn bg-w-color w-100 px-4 my-3"
								@click="showLoginModal = true"
								>{{ $t('headers.login') }}</b-button
							>
							<b-nav-item
								v-if="isLoggedIn"
								href="#"
								class="
									user-logged-menu
									ml-3
									about-us-account-fullname
								"
								link-classes="p-0"
							>
								<b-nav-item-dropdown
									right
									menu-class="shadow"
									no-caret
									class="text-center"
								>
									<!-- Using 'button-content' slot -->
									<template #button-content class="m-0">
										<p
											class="
												text-white
												mb-0
												text-underscore
												text-transform-none
												header-display-name
											"
										>
											{{ user.userInfo.displayName }}
										</p>
									</template>

									<b-list-group class="p-4">
										<b-list-group-item
											class="
												d-flex
												align-items-center
												border-0
											"
										>
											<b-avatar
												:src="
													loadImageSrc(
														user.userInfo.userImage
													)
												"
												size="3rem"
												class="mr-3"
												:to="'/profile'"
												@error="loadImageError"
											></b-avatar>
											<p
												class="
													mr-auto
													w-100
													h-100
													d-flex
													flex-column
												"
											>
												<span
													class="
														full-name
														text-transform-none
														pb-3
														font-weight-bolder
													"
													>{{
														user.userInfo
															.displayName
													}}</span
												>
												<span
													class="
														user-email
														text-lowercase
														text-secondary
														font-weight-normal
													"
													>{{
														user.userInfo.email
													}}</span
												>
											</p>
										</b-list-group-item>
										<b-list-group-item
											class="
												d-flex
												align-items-center
												border-0
											"
										>
											<b-avatar
												variant="info"
												src="@/static/media/icons/token.svg"
												class="mr-3 bg-transparent"
												rounded="lg"
											></b-avatar>
											<h2
												class="
													mr-auto
													d-flex
													text-right
													w-100
													h-100
													justify-content-end
												"
											>
												{{
													giftStore.bean
														.toString()
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															','
														)
												}}
												<b-badge
													class="
														ml-3
														align-self-center
														p-2
														bg-transparent
													"
													href="javascript:void(0)"
													@click="handleOpenShopModal"
													>{{
														$t('headers.topUp')
													}}</b-badge
												>
											</h2>
										</b-list-group-item>
										<!-- <b-list-group-item class="d-flex align-items-center border-0">
						<b-avatar
						  variant="info"
						  src="@/static/media/icons/ticket.svg"
						  class="mr-3 bg-transparent"
						  rounded="lg"
						></b-avatar>

						<h2
						  class="
							mr-auto
							d-flex
							text-right
							w-100
							h-100
							justify-content-end
						  "
						>
						  3
						</h2>
					  </b-list-group-item> -->
										<b-list-group-item
											class="
												d-flex
												align-items-center
												border-right-0
												border-left-0
												border-top
												border-bottom-0
												text-capitalize
											"
											:to="'/profile'"
										>
											<b-avatar
												variant="info"
												src="@/static/media/icons/profile.svg"
												class="mr-3 bg-transparent"
											></b-avatar>
											<span class="mr-auto">{{
												$t('headers.profile')
											}}</span>
										</b-list-group-item>
										<b-list-group-item
											class="
												d-flex
												align-items-center
												border-right-0
												border-left-0
												border-top
												border-bottom-0
												text-capitalize
											"
											@click="
												showLogoutModal =
													!showLogoutModal
											"
										>
											<b-avatar
												variant="success"
												src="@/static/media/icons/logout.svg"
												class="
													mr-3
													bg-transparent
													icon-logout
												"
												rounded="lg"
												style="width: 30px"
											></b-avatar>
											<span class="mr-auto">{{
												$t('headers.logout')
											}}</span>
										</b-list-group-item>
									</b-list-group>
								</b-nav-item-dropdown>
							</b-nav-item>

							<b-nav-item
								v-if="isLoggedIn"
								href="javascript:void(0)"
								class="top-up-menu ml-4 about-us-account-token"
								link-classes="d-flex text-capitalize align-items-center text-underscore p-0 text-nowrap"
								@click="handleOpenShopModal"
							>
								<img
									class="mr-3"
									src="@/static/media/icons/token.svg"
								/>
								{{ giftStore.bean.toLocaleString('en-US') }}
								{{ $t('headers.HYPER') }}
							</b-nav-item>
						</b-navbar-nav>
					</b-collapse>
				</b-container>
			</b-navbar>

			<header id="mobile" class="header d-block d-md-none">
				<WebToAppMobile
					v-show="isHomepage && isShowingWebToAppBanner"
					@close-web-to-app-banner-mobile="isShowingWebToAppBanner = false"
				/>

				<b-navbar
					toggleable="lg"
					type="dark"
					variant="info"
					class="fixed-top navbar-header"
					:class="(isShowingWebToAppBanner && isHomepage) ? 'top-5' : ''"
				>
					<b-container>
						<b-navbar-brand
							href="javascript:window.location.replace('/')"
							class="w-50 mx-0"
						>
							<img
								src="@/static/media/generals/default-logo.svg"
								class="w-50"
							/>
						</b-navbar-brand>
						<div
							class="
								d-flex
								align-items-center
								w-50
								justify-content-end
							"
						>
							<b-nav-item-dropdown
								v-if="selectedLang"
								class="
									h-100
									btn
									nav-item-dropdown nav-lang-dropdown
								"
								variant="outline-light"
							>
								<template #button-content>
									<div class="nav-lang">
										<div
											class="
												nav-lang--country
												iti-flag
												flag-item
											"
											:class="[selectedLang.class]"
										></div>
										<div class="nav-lang--code">
											{{ selectedLang.shortCode }}
										</div>
									</div>
								</template>
								<b-dropdown-item
									v-for="lang in availableLocales"
									:key="`lang-${lang.code}`"
									href="javascript:void(0)"
									class="
										d-flex
										align-items-center
										dropdown__item
									"
									@click.prevent.stop="
										handleSelectedLang(lang)
									"
								>
									<div class="nav-lang-dropdown__item">
										<div
											:class="`nav-lang-dropdown--country iti-flag flag-item ${lang.class}  shadow`"
										></div>
										{{ lang.label }}
									</div>
								</b-dropdown-item>
							</b-nav-item-dropdown>

							<b-nav-item
								v-if="isLoggedIn"
								href="javascript:void(0)"
								class="
									top-up-menu
									about-us-account-token
									d-flex
									align-items-center
								"
								link-classes="d-flex text-capitalize align-items-center text-underscore p-0 text-nowrap"
								@click="handleOpenShopModal"
							>
								<b-avatar
									variant="info"
									src="@/static/media/icons/token.svg"
									class="mx-4"
									size="2rem"
									href="javascript:void(0)"
									@click="handleOpenShopModal"
								></b-avatar>
								{{ giftStore.bean.toLocaleString('en-US') }}
								{{ $t('headers.HYPER') }}
							</b-nav-item>

							<b-navbar-toggle
								v-b-toggle.sidebar-left
								target="nav-collapse"
								class="ml-4 p-2"
							></b-navbar-toggle>

							<b-sidebar
								id="sidebar-left"
								bg-variant="dark"
								shadow
								backdrop
								width="20em"
								lazy
								header-class
								no-header
							>
								<template #default>
									<div class="px-3 py-2">
										<div v-if="isLoggedIn">
											<nav class="mb-3">
												<b-nav
													vertical
													class="top-mobile-header"
												>
													<b-nav-item>
														<div
															class="
																avatar-container
															"
														>
															<b-avatar
																:src="
																	loadImageSrc(
																		user
																			.userInfo
																			.userImage
																	)
																"
																size="5rem"
																class="
																	bg-transparent
																"
																:to="'/profile'"
																@error="
																	loadImageError
																"
															></b-avatar>
															<b-link
																class="
																	text-center
																	mt-2
																"
																to="/profile"
															>
																{{
																	$t(
																		'headers.EDIT'
																	)
																}}
															</b-link>
														</div>
														<p
															class="ml-3"
															@click="
																showLogoutModal =
																	!showLogoutModal
															"
														>
															{{
																$t(
																	'headers.logout'
																)
															}}
														</p>
													</b-nav-item>
												</b-nav>
											</nav>
										</div>
										<div v-if="!isLoggedIn">
											<nav class="mb-3">
												<b-nav
													vertical
													class="top-mobile-header"
												>
													<b-nav-item>
														<b-avatar
															v-b-toggle.sidebar-left
															src="@/static/media/icons/close.svg"
															size="2rem"
															class="
																mr-3
																bg-transparent
															"
														></b-avatar>
													</b-nav-item>
												</b-nav>
												<b-nav
													vertical
													class="top-mobile-header"
												>
													<b-nav-item>
														<b-avatar
															src="@/static/media/images/black_avatar.png"
															size="5rem"
															class="
																mr-3
																bg-transparent
															"
														></b-avatar>
														<p>
															<a
																href="javascript:void(0)"
																@click="
																	showLoginModal = true
																"
																>{{
																	$t(
																		'headers.login'
																	)
																}}</a
															>
														</p>
													</b-nav-item>
												</b-nav>
											</nav>
										</div>
										<nav class="mb-3">
											<b-nav
												vertical
												class="bottom-mobile-header"
											>
												<b-button
													v-if="!isLoggedIn"
													variant="primary"
													block
													class="bg-w-color"
													href="javascript:void(0)"
													@click="handleOpenShopModal"
													>{{
														$t('headers.hyper')
													}}</b-button
												>
												<a
													v-else
													href="javascript:void(0)"
													class="
														bg-w-color
														recharge-href
													"
													@click="handleOpenShopModal"
												>
													{{
														giftStore.bean.toLocaleString(
															'en-US'
														)
													}}
													{{ $t('headers.HYPER') }}
												</a>
												<hr class="border" />
												<b-nav-item
													class="none-t-tranf"
													to="/faq/privacy-policy"
													>{{
														$t(
															'headers.privacyPolicy'
														)
													}}</b-nav-item
												>
												<b-nav-item
													class="none-t-tranf"
													to="/faq/terms-of-use"
													>{{
														$t(
															'headers.termsConditions'
														)
													}}</b-nav-item
												>
												<b-nav-item
													class="none-t-tranf"
													to="/faq"
													>{{
														$t('headers.faq')
													}}</b-nav-item
												>
											</b-nav>
										</nav>
									</div>
								</template>
							</b-sidebar>
						</div>
					</b-container>
				</b-navbar>
			</header>
		</client-only>

		<LoginModal
			v-if="showLoginModal"
			:open="showLoginModal"
			@hidden="showLoginModal = false"
			@showForgotPasswordModal="
				showLoginModal = false
				showForgotPasswordModal = true
			"
			@openSideBar="openSidebar"
			@handleShowRegisterModal="handleShowRegisterModal"
		/>
		<SignUpModal
			v-if="showRegisterModal"
			:open="showRegisterModal"
			@hidden="showRegisterModal = false"
			@show-register-confirmation="showRegisterConfirm"
			@handleShowLoginModal="handleShowLoginModal"
		/>
		<RegisterConfirmationModal
			v-if="showRegisterConfirmationModal"
			:email-confirm="emailConfirm"
			:open="showRegisterConfirmationModal"
			@hidden="showRegisterConfirmationModal = false"
		/>
		<RegisterSuccessModal
			:open="showRegisterSuccessModal"
			@hidden="
				showRegisterSuccessModal = false
			"
		/>
		<ForgotPasswordModal
			:open="showForgotPasswordModal"
			@hidden="showForgotPasswordModal = false"
			@show-register="
				showForgotPasswordModal = false
				showRegisterModal = true
			"
		/>
		<LogOutModal
			:open="showLogoutModal"
			@hidden="showLogoutModal = false"
		/>
		<ShopModal
			:modal-options="hypershopModalOptions"
			:url="shopUrl"
			:open="isShowTopUpShopModal"
			@hidden="isShowTopUpShopModal = false"
		/>
	</div>
</template>

<script>
import LoginModal from '@/components/modals/LoginModal'
import LogOutModal from '@/components/modals/LogoutModal'
import SignUpModal from '@/components/modals/SignUpModal'
import RegisterConfirmationModal from '@/components/modals/RegisterConfirmationModal'
import RegisterSuccessModal from '@/components/modals/RegisterSuccessModal'
import ForgotPasswordModal from '@/components/modals/ForgotPasswordModal'
import { mapGetters, mapActions } from 'vuex'
import i18nMixins from '@/mixins/i18nMixins'
import SdkMixins from '@/mixins/SdkMixins'
import ShopModal from '@/components/modals/ShopModal'
import { SDK_API } from '@/configs/APIs'
import {DEFAULT_ICON_STATES} from "@/configs/Icons";
import WebToAppMobile from "@/components/banners/WebToAppMobile";

const HYPERSHOP_APIS = SDK_API.HYPERSHOP

const hypershopModalOptions = {
	id: 'topup-hshop-modal',
	class: 'hshop-modal-custom',
	dialogClass: 'hshop-modal',
	headerClass: 'hshop-modal__header',
	contentClass: 'hshop-modal__content',
	bodyClass: 'hshop-modal__body',
}

export default {
	name: 'Header',
	components: {
		SignUpModal,
		LoginModal,
		LogOutModal,
		RegisterConfirmationModal,
		RegisterSuccessModal,
		ForgotPasswordModal,
		ShopModal,
		WebToAppMobile
	},
	mixins: [SdkMixins, i18nMixins],
	props: {},
	data() {
		return {
			showLoginModal: false,
			showRegisterModal: false,
			showRegisterConfirmationModal: false,
			showRegisterSuccessModal: false,
			showForgotPasswordModal: false,
			emailConfirm: '',
			showLogoutModal: false,

			// Hypershop
			hypershopModalOptions,
			shopUrl: '',
			isShowTopUpShopModal: false,
			DEFAULT_ICON_STATES,

			// Web to app banner mobile
			isShowingWebToAppBanner: true,

			voucher: '',
		}
	},
	async fetch() {},
	computed: {
		...mapGetters({
			user: 'user',
			isLoggedIn: 'isLoggedIn',
			giftStore: 'giftStore/myAssets',
			projectSettings: 'projectSettings',
		}),
		isHomepage() {
			if (process.browser) {
				return window.location.pathname === '/'
			}

			return false
		},
	},
	watch: {},
	created() {},
	mounted() {
		this.handleEventGlobals()
		this.voucher = this.$route.query.voucher
	},

	methods: {
		...mapActions({
			updateMyAssets: 'giftStore/updateMyAssets',
			getProfile: 'getProfile',
		}),
		handleEventGlobals() {
			this.$root.$on('required-login', () => {
				this.showLoginModal = true
			})

			this.$root.$on('show-register-confirmation', () => {
				this.showRegisterConfirmationModal = true
			})

			this.$root.$on('show-register-success', () => {
				this.showRegisterSuccessModal = true
			})

			this.$root.$on('show-logout-modal', () => {
				this.showLogoutModal = true
			})

			this.$root.$on('show-topup-modal', () => {
				this.handleOpenShopModal()
			})
		},
		showRegisterConfirm(email) {
			this.emailConfirm = email
			this.showRegisterConfirmationModal = true
		},
		onUserLogout() {
			this.logout()
		},
		openSidebar() {
			this.$root.$emit('bv::toggle::collapse', 'sidebar-left')
		},
		handleShowRegisterModal() {
			if (this.voucher) {
				this.$root.$emit('user-register-when-redeem-voucher')
			}
			this.showLoginModal = false
			this.showRegisterModal = true
		},
		handleShowLoginModal() {
			this.showLoginModal = true
			this.showRegisterModal = false
		},
		handleOpenShopModal() {
			if (!this.isLoggedIn) {
				this.showLoginModal = true
				return
			}
			this.shopUrl = `${process.env.HYPERSHOP_URL}${HYPERSHOP_APIS.TOPUP_PAGE}?`
			this.isShowTopUpShopModal = true
		},
		handleClickUrl(url) {
			if (process.browser) {
				window.location.href = url
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/pages/header.scss';

.main-header {
	nav {
		z-index: 99;
	}
}

.top-mobile-header {
	.nav-item {
		a {
			display: inline-flex;
			align-items: center;
			p {
				text-transform: none;
				text-decoration: underline;
				color: $color-coral-pink;
				a {
					color: $color-coral-pink;
				}
			}
		}
	}
	.nav-link {
		.text-center {
			font-size: 1rem;
		}
	}
	.avatar-container {
		display: grid;
		a {
			justify-content: center;
			color: $color-coral-pink;
			text-decoration: underline !important;
		}
	}
}

.bottom-mobile-header {
	.btn {
		width: 40%;
		margin-left: 2rem;
		margin-bottom: 1rem;
	}
	.nav-item {
		.nav-link {
			color: $color-coral-pink;
		}
		&.none-t-tranf {
			a {
				text-transform: none;
			}
		}
	}
	.border {
		display: block;
		height: 1px;
		border: 0 !important;
		border-top: 1px solid #707070 !important;
		margin: 2rem;
		padding: 0;
	}
	.recharge-href {
		color: $color-coral-pink;
		font-weight: bold;
		padding-left: 2rem;
	}
}

.navbar {
	min-height: $navbar-height;
}
.nav-lang {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	&--country {
		margin-right: 0.5rem;
	}
}
.nav-lang-dropdown {
	&__item {
		display: flex;
	}
	&--country {
		margin-right: 0.5rem;
	}
}

.nav-lang-dropdown::v-deep {
	.nav-link {
		color: #fff;
	}
}

.navbar-nav {
	.about-us {
		&-account-fullname.user-logged-menu {
			width: 100%;
		}
		&-account-token.nav-link {
			padding: 0 !important;
			width: 100%;
		}
		&-account-token.nav-item {
			padding: 0 !important;
			width: 100%;
		}
	}
}

.text-underscore {
	text-decoration: underline !important;
}

.text-transform-none {
	text-transform: none;
}

.header-display-name {
	max-width: 15rem;
	line-height: 2;
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.full-name {
	line-height: 1.5;
}

#mobile {
	.navbar {
		height: $navbar-height;
	}
}

.top-5 {
	top: 6rem
}
</style>
