export const STATUS_RESPONSES = {
	USER_BLOCKED_BY_HOST: 1309,
	USER_BANNED_BY_ADMIN: 1120,
	USERNAME_HAS_BEEN_TAKEN: 759,
	USER_NOT_FOUND: 652,
	SUCCESS: 1,
	PRIVATE_ROOM: 798,
	USER_LOGGED_ANOTHER_DEVICE: 1341,
	INFO_INCORRECT: 966,
	INCORRECT_PASSWORD: 966,
	INVALID_MODEL: 750,
	DISPLAY_NAME_REQUIRED: 779,
	NOT_ENOUGH_MONEY: 1049,
	USER_NOT_VERIFY: 1426,
	EMAIL_EXISTED: 0,
	NOT_BUY_TICKET: 1994,
	ACCOUNT_DEACTIVE: 603,
	VERIFICATION_CODE_EXPIRED: 964,
	GIFT_NOT_AVAILABLE: 604,
	SIGNUP_HYPERSHOP_FAILED: 2010,
	CANNOT_CREATE_USER: 801,
	ACCOUNT_HAS_ALREADY_BEEN_REGISTERED: 2011,
	DATA_NOT_FOUND: 404,
	UPDATE_DOB_REQUIRED: 2024,
	DOB_NOT_ALLOW_ACCESS_EVENT: 2025,
	YOU_NEED_TO_LOGIN_FIRST: 2029,
	FETCH_CART_INFO_FAILED: 2020,
	VOUCHER_ALREADY_REDEEMED: 2013
}

export const STATUS_VIDEO = {
	LIVE: 1,
	RECORDED: 2,
}

export const DEVICE_TYPES = {
	ANDROID: '0',
	IOS: '1',
	WEB: '2',
}

export const DEVICE_UDID = {
	WEB: 'web',
}

export const MOBILE_OS = {
	ANDROID: 'ANDROID',
	iOS: 'iOS',
	WINDOW_PHONE: 'Windows Phone',
	UN_KNOWN: 'unknown',
}

export const BROWSER = {
	OPERA: 'OPERA',
	SAFARI: 'SAFARI',
	CHROME: 'CHROME',
	FIREFOX: 'FIREFOX',
	EDGE: 'EDGE',
	IE: 'IE',
	EDGE_CHROMIUM: 'EDGE_CHROMIUM',
	UNKNOWN: 'UNKNOWN',
}

export const DEFAULT_VALUES = {
	LOCATION: 'SG',
}

export const TRANSACTION_STATUS = {
	NONE: 'NONE',
	PROCESSING: 'PROCESSING',
	COMPLETED: 'COMPLETED',
	PURCHASED: 'PURCHASED',
	ERROR: 'ERROR',
}

export const PAYMENT_HUB = `${process.env.API_HUB_ENDPOINT}/paymentIpnHub`
export const CURRENCY = 'SGD'

export const STRIPE_SIGNAL_RESPONSE = {
	TOPUP_MISSING: -1,
	USER_MISSING: -2,
	MISSING_TRANSACTION_ID: -3,
	TRANSACTION_EXIST: -4,
	CANNOT_CREATE_INTERNAL_TRANSACTION: -5,
	CANNOT_CREATE_ORDER: -6,
	INSERT_FAIL_BUT_PAYMENT_STORE_SUCCESS: -7,
	UNKNOWN_ERROR: -99,
	PAYMENT_SUCCESS: 1,
}

export const CURRENCY_TEXT = {
	SGD: 'SGD',
	TWD: 'TWD',
	MYR: 'MYR',
}

export const EVENT_STATUS = {
	DEACTIVE: 1,
	ACTIVE: 2,
	STARTED: 3,
	ENDED: 4,
}

export const SIGN_IN_TYPE = {
	BELIVE_ID: 0,
	EMAIL: 1,
	FACEBOOK: 2,
	APPLE: 3,
	GOOGLE: 4,
}

export const VOUCHER_TYPE = {
	EVENT: 'event',
}

export const IGNORE_ERROR_NOTIFICATION = [
	STATUS_RESPONSES.NOT_ENOUGH_MONEY,
	STATUS_RESPONSES.USER_NOT_FOUND,
	STATUS_RESPONSES.DATA_NOT_FOUND,
	STATUS_RESPONSES.UPDATE_DOB_REQUIRED,
	STATUS_RESPONSES.DOB_NOT_ALLOW_ACCESS_EVENT,
	STATUS_RESPONSES.YOU_NEED_TO_LOGIN_FIRST,
	STATUS_RESPONSES.FETCH_CART_INFO_FAILED
]

export const STITCHING_VIDEO_TYPES = {
	IVS: "IVS",
	PK: "PK"
}

export const HEADER_KEYS = {
	HYPERSHOP_ACCESS_TOKEN: 'hShopAccessToken'
}
