<template>
	<div id="google-sign-in-button">
		<!-- <slot></slot> -->
	</div>
</template>

<script>
import HelperMixins from '@/mixins/HelperMixins'
import { SIGN_IN_TYPE } from '~/configs/SDK'
import { PUBLIC_SDK_SCRIPT } from '~/configs/Settings'
import { decodeJwt } from 'jose'

export default {
	name: 'SignInWithGoogle',
	mixins: [HelperMixins],
	data: () => ({
		auth2: null,
	}),
	mounted() {
		if (process.browser) {
			this.initScript(
				document,
				'script',
				{
					src: PUBLIC_SDK_SCRIPT.GOOGLE_GSI,
					id: 'gg-js',
					async: true,
					defer: true,
				},
				this.setLoaded
			)
		}
	},
	methods: {
		setLoaded() {
			const _this = this
			if (process.browser) {
				const google = window.google
				google.accounts.id.initialize({
					client_id: process.env.GOOGLE_CLIENT_ID,
					callback: this.handleCredentialResponse,
				})
				google.accounts.id.renderButton(
					document.getElementById('google-sign-in-button'),
					{
						theme: 'outline',
						type: 'icon',
						size: 'medium',
						shape: 'circle',
					} // customization attributes
				)
				//google.accounts.id.prompt() // also display the One Tap dialog
			}
		},
		handleCredentialResponse(response) {
			// to decode the credential response.
			const responsePayload = decodeJwt(response.credential)
			console.log('ID: ' + responsePayload.sub)
			console.log('Full Name: ' + responsePayload.name)
			console.log('Given Name: ' + responsePayload.given_name)
			console.log('Family Name: ' + responsePayload.family_name)
			console.log('Image URL: ' + responsePayload.picture)
			console.log('Email: ' + responsePayload.email)
			this.$emit(
				'onSuccess',
				{
					id: responsePayload.sub,
					accessToken: response.credential,
					name: responsePayload.name,
					given_name: responsePayload.given_name,
					family_name: responsePayload.family_names,
					image_url: responsePayload.picture,
					email: responsePayload.email,
				},
				SIGN_IN_TYPE.GOOGLE
			)
		},
	},
}
</script>
