import { cloneDeep } from 'lodash'

export async function loadLanguageAsync(lang) {
	try {
		if (process.env.LANGUAGE_PATH) {
			const res = await fetch(`${process.env.LANGUAGE_PATH}/${lang}.json`)
			const data = await res.json()
			return data
		}
		throw new Error('Language path is not available')
	} catch (error) {
		console.error(error)
		return import(`@/messages/lang/${lang}.json`).then((res) => res.default)
	}
}

export function buildHead({ title, description, image, url }) {
	let meta = []
	let link = []
	if (title) {
		meta = meta.concat([
			{ hid: 'og:site_name', property: 'og:site_name', content: title },
			{ hid: 'og:title', property: 'og:title', content: title },
			{ hid: 'twitter:title', name: 'twitter:title', content: title },
		])
	}
	if (description) {
		meta = meta.concat([
			{ hid: 'description', name: 'description', content: description },
			{
				hid: 'og:description',
				property: 'og:description',
				content: description,
			},
			{
				hid: 'twitter:description',
				name: 'twitter:description',
				content: description,
			},
		])
	}
	if (image) {
		meta = meta.concat([
			{ hid: 'og:image', property: 'og:image', content: image },
			{ hid: 'twitter:image', name: 'twitter:image', content: image },
		])
	}
	if (url) {
		meta = meta.concat([
			{ hid: 'og:url', property: 'og:url', content: url },
			{ hid: 'twitter:url', name: 'twitter:url', content: url },
		])
		link = link.concat([{ hid: 'canonical', rel: 'canonical', href: url }])
	}

	return cloneDeep({
		title,
		meta,
		link,
	})
}
