export const STATUS_MESSAGES = {
	ERROR: 'Error',
	SUCCESS: 'Success',
	WARNING: 'Warning',
}

export const SYSTEM_MESSAGES = {
	DATA_NOT_FOUND: 'Data not found!',
	USER_UPDATE_PROFILE_SUCCESS: 'Update profile successfully!',
	USER_UPDATE_PROFILE_FAILED: 'Failed to update profile!',
	CHANGE_PASSWORD_SUCCESS: 'Password changed successfully!',
	INVALID_DATE: 'Invalid Date!',
	MAX_LENGTH_CHAT: 'You have exceeded the chat characters limit',
	NOT_ENOUGHT_CASH: 'Insufficient Hypes in your account',
	VERIFY_FAILED: 'The code is not correct!',
	ANSWERED: 'Answered',
	LOGIN_SUCCESS: 'Login Successfully!',
	REGISTER_SUCCESS: 'Registered Successfully!',
	REGISTER_FAIL: 'Register fail!',
	RESET_PASSWORD_SUCCESS: 'Reset password Successfully!',
	FAILED: 'Failed',
	EMAIL_NOT_EXIST: 'Email not exist!',
	BUY_TICKET_SUCCESS: 'Ticket is purchased successfully!',
	ACCOUNT_DEACTIVE: 'Account has been deactivated!',
	ACCOUNT_EXIST: 'Account already exists!',
	APPLY_VOUCHER_SUCCESS: 'Voucher is applied successfully',
	USER_LOGGED_IN_ANOTHER_ACCOUNT:
		'Your account is logged in from another device',
	ERROR: 'Error',
	UNABLE_GET_HSHOP_API: 'Unable to get a response from HyperShop API',
	VOTE_SUCCESS: 'SUCCESS! Check out the leaderboard for latest rankings',
}

export const ROOM_CHANNEL_MESSAGES = {
	USER_WAS_KICKED_ROOM: 'You was kicked room by Host!',
	NOT_CONNECTED_CHAT_SERVER:
		"You didn't connect chat server, please refresh to connect or contact admin",
	RECONNECT_CHAT_SUCCESS: 'Reconnected chat server!',
	MUTED_BY_ADMIN: 'You have been muted by Admin',
	NAUGHTY_WARNING:
		'Sorry, your comment needs to be appropriate and should not contain any URL Please try again',
	REACH_LIMIT_SENDER:
		'You are typing quite fast! We need time to read. Please hold for 1 minute',
}

export const RTM_MESSAGES = {
	START_SHARE_SCREEN: 'START SHARE SCREEN',
	STOP_SHARE_SCREEN: 'STOP SHARE SCREEN',
	HOST_STREAM_ENDED: 'Stream Ended',
	LIKE_STREAM: 'LIKE STREAM',
}
