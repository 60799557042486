import { SDK_API } from '@/configs/APIs'
const GIFT_APIS = SDK_API.GIFTS

export default ($axios) => ({
	/**
	 * Get all gifts of store
	 * @param eventId
	 */
	async getStore(eventId) {
		let urlStore = `${GIFT_APIS.STORE}`
		if (eventId) urlStore += `?eventId=${eventId}`

		const {
			data: { code, data, message },
		} = await $axios.post(urlStore)
		return { code, data, message }
	},

	/**
	 * Send gift
	 * @param {*} param0
	 */
	async send({ giftId, receiverUserId, streamId, isInPk = false }) {
		const {
			data: { code, data, message },
		} = await $axios.post(GIFT_APIS.SEND, {
			giftId,
			receiverUserId,
			streamId,
			isInPk,
		})
		return { code, data, message }
	},

	/**
	 * Get leaderboard gift data
	 * @param {*} param0
	 */
	async getGiftLeaderboard(slug) {
		const {
			data: { code, data, message },
		} = await $axios.post(GIFT_APIS.LEADER_BOARD_GIFT, { slug })
		return { code, data, message }
	},

})
