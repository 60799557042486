import { SDK_API } from '@/configs/APIs'
const EMAIL_APIS = SDK_API.EMAIL

export default ($axios) => ({
  async subscriber(email) {
    const {
      data: { code, data, message },
    } = await $axios.get(EMAIL_APIS.SUBSCRIBERS + email)
    return { code, data, message }
    },
    async getTotalSubscriberEmail() {
        const {
          data: { code, data, message },
        } = await $axios.get(EMAIL_APIS.TOTAL_SUBSCRIBERS)
        return { code, data, message }
    }
})
