import DEFAULT_THUMB from "@/static/media/images/no-thumb.jpg";
export default {
    name: 'ImageMixins',
    mixins: [],
    props: {},
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        loadImageSrc(src) {
            return src || DEFAULT_THUMB;
        },
        loadImageError(event) {
            let img = event.srcElement;
            img.src = DEFAULT_THUMB;
            event.target.src = DEFAULT_THUMB;
            img.onerror = null; 
        }
    },
}