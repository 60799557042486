<template>
	<div :key="keyRender" class="body-wrapper">
		<client-only>
			<Header v-if="showHeader" />
		</client-only>
		<Nuxt />
		<Footer />
		<TransactionMessageModal id="trans-msg" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '@/components/headers/Header.vue'
import Footer from '@/components/footer'
import TransactionMessageModal from '@/components/modals/TransactionMessageModal'
import LayoutMixins from '@/mixins/LayoutMixins'
export default {
	name: 'LayoutDefault',
	components: {
		Header,
		Footer,
		TransactionMessageModal,
	},
	mixins: [LayoutMixins],
	middleware: ['checkIsMaintenance', 'auth'],
	data() {
		return {
			keyRender: new Date().getTime(),
		}
	},
	computed: {
		...mapGetters({
			showHeader: 'general/showHeader',
		}),
	},
}
</script>
<style lang="scss" scoped>
.body-wrapper {
	overflow: hidden;
}
</style>
