const middleware = {}

middleware['auth'] = require('../src/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['availableRouters'] = require('../src/middleware/availableRouters.js')
middleware['availableRouters'] = middleware['availableRouters'].default || middleware['availableRouters']

middleware['checkIsMaintenance'] = require('../src/middleware/checkIsMaintenance.js')
middleware['checkIsMaintenance'] = middleware['checkIsMaintenance'].default || middleware['checkIsMaintenance']

middleware['checkLocation'] = require('../src/middleware/checkLocation.js')
middleware['checkLocation'] = middleware['checkLocation'].default || middleware['checkLocation']

middleware['projectSettings'] = require('../src/middleware/projectSettings.js')
middleware['projectSettings'] = middleware['projectSettings'].default || middleware['projectSettings']

export default middleware
