<template>
	<div id="landing">
		<Header />
		<Nuxt />
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/headers/HeaderLanding.vue'
import Footer from '@/components/footer/FooterLanding.vue'
import LayoutMixins from '@/mixins/LayoutMixins'

export default {
	components: {
		Header,
		Footer,
	},
	mixins: [LayoutMixins],
	middleware: ['checkIsMaintenance', 'auth'],
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

#landing {
	font-family: $font-fira-sans;
	.container {
		margin: 0 auto;
	}
}
</style>
