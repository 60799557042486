import locale77427e78 from '../../src/messages/lang/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","silentTranslationWarn":true,"silentFallbackWarn":true},
  vueI18nLoader: false,
  locales: [{"id":0,"label":"English","code":"en","file":"en.js","class":"us","shortCode":"EN","localeCode":"english"},{"id":4,"label":"简体","code":"zh-cn","file":"cn.js","class":"cn","shortCode":"简体","localeCode":"china"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: true,
  langDir: "/home/ubuntu/projects/HyperLive-DEV/HyperLive/src/messages/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"lang","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":true,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"id":0,"label":"English","code":"en","file":"en.js","class":"us","shortCode":"EN","localeCode":"english"},{"id":4,"label":"简体","code":"zh-cn","file":"cn.js","class":"cn","shortCode":"简体","localeCode":"china"}],
  localeCodes: ["en","zh-cn"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'cn.js': () => import('../../src/messages/lang/cn.js' /* webpackChunkName: "lang-cn.js" */),
}
