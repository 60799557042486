import { SDK_API } from '@/configs/APIs'
const VOTING_APIS = SDK_API.VOTING

export default ($axios) => ({
  /**
   * Get data for voting
   */
  async getVotes() {
    const {
      data: { code, data, message },
    } = await $axios.get(VOTING_APIS.ALL)
    return { code, data, message }
  },

	/**
	 * Get voting detail
	 * @param {*} id
	 */
	async getVote(id) {
		const {
			data: { code, data, message },
		} = await $axios.get(VOTING_APIS.DETAIL.replace('{id}', id))
		return { code, data, message }
	},

	/**
	 * Fetch leader board data
	 * @param {*} id
	 */
	async fetchLeaderboard(votingId) {
		const {
			data: { code, data, message },
		} = await $axios.post(VOTING_APIS.LEADERBOARD, {
			votingId
		})
		return { code, data, message }
	},

	/**
	 * Submit vote
	 * @param {*} giftId
	 * @param {*} votingId
	 * @param {*} quantity
	 */
	async submitVote(giftId, votingId, quantity) {
		const {
			data: { code, data, message },
		} = await $axios.post(VOTING_APIS.SUBMIT_VOTE, {giftId, votingId, quantity})
		return { code, data, message }
	},
})
