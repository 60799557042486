<template>
	<div>
		<header class="py-3 d-none d-md-block faq__header">
			<b-navbar toggleable="lg" variant="faded" type="light" class="">
				<b-container class="mx-auto">
					<b-row
						class="text-center w-100 justify-content-center"
						align-v="center"
					>
						<b-col cols="2">
							<b-navbar-brand href="#" @click="reloadPage">
								<b-img
									src="@/static/media/images/logo-footer.svg"
									fluid
									alt="Fluid image"
								></b-img>
							</b-navbar-brand>
						</b-col>

						<b-col cols="8" class="d-flex justify-content-center">
							<b-nav align="center">
								<b-nav-item
									link-classes="px-sm-3 px-md-4 px-lg-4 regular-text"
									@click="goToAnchor('register-section')"
									>{{ $t('pages.landing.Pre-Registration') }}
								</b-nav-item>
								<b-nav-item
									link-classes="px-sm-3 px-md-4 px-lg-4 regular-text"
									@click="goToAnchor('why-section')"
									>{{ $t('pages.landing.Live Features') }}
								</b-nav-item>
								<b-nav-item
									class="nav-item"
									link-classes="px-sm-3 px-md-4 px-lg-4"
									@click="goToAnchor('event-section')"
									>{{ $t('pages.landing.Upcoming Events') }}
								</b-nav-item>
							</b-nav>
						</b-col>
						<b-col cols="2" class="d-flex justify-content-end">
							<b-nav align="center">
								<b-dropdown
									v-if="selectedLang"
									id="dropdown-1"
									toggle-class="d-flex align-items-center flex-row bg-transparent border text-uppercase font-weight-bold"
								>
									<template #button-content>
										<span
											class="
												d-flex
												iti-flag
												flag-item
												mr-3
											"
											:class="[selectedLang.class]"
										></span>
										<span class="mr-2 text-white text-lang">
											{{ selectedLang.shortCode }}
										</span>
									</template>
									<b-dropdown-item
										v-for="lang in availableLocales"
										:key="`lang-${lang.code}`"
										href="javascript:void(0)"
										class="d-flex align-items-center py-2"
										@click.prevent.stop="
											handleSelectedLang(lang)
										"
									>
										<div class="d-flex align-items-center">
											<div
												:class="`iti-flag flag-item mr-1 ${lang.class}  shadow`"
											></div>
											{{ lang.label }}
										</div>
									</b-dropdown-item>
								</b-dropdown>
							</b-nav>
						</b-col>
					</b-row>
				</b-container>
			</b-navbar>
		</header>
		<header class="d-block d-md-none">
			<b-navbar toggleable="lg" variant="faded" type="dark" class="p-4">
				<b-navbar-brand href="#" @click="reloadPage">
					<b-img
						src="@/static/media/images/logo-footer.svg"
						fluid
						alt="Fluid image"
					></b-img>
				</b-navbar-brand>

				<div class="d-flex">
					<b-dropdown
						v-if="selectedLang"
						id="dropdown-1"
						class="mr-4"
						toggle-class="d-flex align-items-center flex-row bg-transparent border text-uppercase font-weight-bold"
					>
						<template #button-content>
							<span
								class="d-flex iti-flag flag-item mr-3"
								:class="[selectedLang.class]"
							></span>
							<span class="mr-2 text-white text-lang">
								{{ selectedLang.shortCode }}
							</span>
						</template>
						<b-dropdown-item
							v-for="lang in availableLocales"
							:key="`lang-${lang.code}`"
							href="javascript:void(0)"
							class="d-flex align-items-center py-2"
							@click.prevent.stop="handleSelectedLang(lang)"
						>
							<div class="d-flex align-items-center">
								<div
									:class="`iti-flag flag-item mr-1 ${lang.class}  shadow`"
								></div>
								{{ lang.label }}
							</div>
						</b-dropdown-item>
					</b-dropdown>
					<b-navbar-toggle
						target="nav-collapse"
						class="p-2"
					></b-navbar-toggle>
				</div>

				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav class="text-right pt-3">
						<b-nav-item
							class="mb-3"
							@click="goToAnchor('register-section')"
							>{{ $t('pages.landing.Pre-Registration') }}
						</b-nav-item>
						<b-nav-item
							class="mb-3"
							@click="goToAnchor('why-section')"
							>{{ $t('pages.landing.Live Features') }}
						</b-nav-item>
						<b-nav-item
							class="mb-3"
							@click="goToAnchor('event-section')"
							>{{ $t('pages.landing.Upcoming Events') }}
						</b-nav-item>
					</b-navbar-nav>
				</b-collapse>
			</b-navbar>
		</header>
		<SignUpModal
			:open="showRegisterModal"
			@hidden="showRegisterModal = false"
			@show-register-confirmation="showRegisterConfirm"
		/>
		<RegisterConfirmationModal
			:email-confirm="emailConfirm"
			:open="showRegisterConfirmationModal"
			@hidden="showRegisterConfirmationModal = false"
		/>
		<RegisterSuccessModal
			:open="showRegisterSuccessModal"
			@hidden="closeRegisterSuccessModal"
		/>
	</div>
</template>

<script>
import i18nMixins from '@/mixins/i18nMixins'
import SdkMixins from '@/mixins/SdkMixins'
import SignUpModal from '@/components/modals/SignUpModal'
import RegisterConfirmationModal from '@/components/modals/RegisterConfirmationModal'
import RegisterSuccessModal from '@/components/modals/RegisterSuccessModal'
import { REDIRECT_URL } from '~/configs/Settings'
import { STATUS_RESPONSES } from '~/configs/SDK'

export default {
	name: 'HeaderLanding',
	components: {
		SignUpModal,
		RegisterConfirmationModal,
		RegisterSuccessModal,
	},
	mixins: [SdkMixins, i18nMixins],
	props: {},
	data() {
		return {
			showRegisterModal: false,
			showRegisterConfirmationModal: false,
			showRegisterSuccessModal: false,
			emailConfirm: '',
			action: this.$route.query.action,
			code: this.$route.query.code,
		}
	},
	async fetch() {},
	computed: {},
	watch: {},
	created() {},
	mounted() {
		this.handleEventGlobals()
		if (this.action === REDIRECT_URL.AUTH_VERIFY_EMAIL.action) {
			this.verifyEmailConfirmation()
		}
	},

	methods: {
		handleEventGlobals() {
			this.$root.$on('show-register-modal', () => {
				this.showRegisterModal = true
			})

			this.$root.$on('show-register-confirmation', () => {
				this.showRegisterConfirmationModal = true
			})

			this.$root.$on('show-register-success', () => {
				this.showRegisterSuccessModal = true
			})
		},
		showRegisterConfirm(email) {
			this.emailConfirm = email
			this.showRegisterConfirmationModal = true
			this.$root.$emit('refresh-data')
		},
		closeRegisterSuccessModal() {
			this.showRegisterSuccessModal = false
			// this.$router.push("/welcome")
		},
		reloadPage() {
			if (process.browser) {
				window?.location?.reload()
			}
		},
		goToAnchor(anchor) {
			setTimeout(
				() =>
					document.querySelector(`#${anchor}`).scrollIntoView({
						behavior: 'smooth',
					}),
				300
			)
		},
		async verifyEmailConfirmation() {
			const { code } =
				await this.$httpResources.repositories.auth.verifyEmailConfirmation(
					this.code
				)

			if (code === STATUS_RESPONSES.SUCCESS) {
				this.showRegisterSuccessModal = true
			}

			await this.$router.replace({ path: this.$route.path, query: {} })
			// else {
			//     this.$notification.e(SYSTEM_MESSAGES.VERIFY_FAILED, STATUS_MESSAGES.ERROR);
			// }
		},
	},
}
</script>

<style lang="scss" scoped>
.faq__header {
	background-color: #292a2c;
}

header {
	.navbar {
		background-color: #292a2c;

		.nav-link {
			color: #fff;
			font-size: 1.5rem;
			font-weight: 700;
			font-family: $font-fira-sans;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.81;
			letter-spacing: normal;
			text-align: center;

			&.regular-text {
				font-weight: 500;
			}
		}

		.nav-item {
			margin: 0 1rem 0 1rem;
			line-height: 1.7;
			display: flex;
			align-items: center;

			a {
				width: 100%;
				text-align: right;
			}

			&__active {
				color: #ff6d6a;
			}

			.nav-link {
				padding: 0;
			}
		}

		.navbar-brand {
			display: flex;
			align-items: center;

			img {
				width: 10rem;
			}
		}
	}
	.text-lang {
		line-height: 1.8;
	}
}
</style>
