export default async function ({ route, store, redirect }) {
	let isMaintenance = false
	if (store.getters?.projectSettings?.maintenance?.url) {
		await store.dispatch('checkMaintenance')
		isMaintenance = store.getters.isMaintenance
	}
	if (isMaintenance && route.name !== 'maintenance')
		return redirect('/maintenance')
	else if (!isMaintenance && route.name === 'maintenance') {
		return redirect(store.getters?.projectSettings?.general?.homeUrl || '')
	}
}
