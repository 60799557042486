<template>
	<div :key="keyRender">
		<div
			id="view-route-partial"
			class="main main-page belive-main-page"
			role="main"
		>
			<Nuxt />
		</div>
		<LoginModal :open="showLoginModal" @hidden="showLoginModal = false" />
		<SignUpModal
			:open="showRegisterModal"
			@hidden="showRegisterModal = false"
			@show-register-confirmation="showRegisterSuccessModal = true"
		/>
		<RegisterConfirmationModal
			:open="showRegisterConfirmationModal"
			@hidden="showRegisterConfirmationModal = false"
		/>
		<RegisterSuccessModal
			:open="showRegisterSuccessModal"
			@hidden="
				showRegisterSuccessModal = false
				showLoginModal = true
			"
		/>
		<TransactionMessageModal id="trans-msg" />
	</div>
</template>

<script>
import LoginModal from '@/components/modals/LoginModal'
import SignUpModal from '@/components/modals/SignUpModal'
import RegisterConfirmationModal from '@/components/modals/RegisterConfirmationModal'
import RegisterSuccessModal from '@/components/modals/RegisterSuccessModal'
import TransactionMessageModal from '@/components/modals/TransactionMessageModal'
import LayoutMixins from '@/mixins/LayoutMixins'

export default {
	components: {
		SignUpModal,
		LoginModal,
		RegisterConfirmationModal,
		RegisterSuccessModal,
		TransactionMessageModal,
	},
	mixins: [LayoutMixins],
	middleware: ['checkIsMaintenance', 'auth'],
	data() {
		return {
			showLoginModal: false,
			showRegisterModal: false,
			showRegisterConfirmationModal: false,
			showRegisterSuccessModal: false,
			keyRender: new Date().getTime(),
		}
	},
	computed: {},
	methods: {
		handleEventGlobals() {
			this.$root.$on('required-login', () => {
				this.showLoginModal = true
			})

			this.$root.$on('show-register-success', () => {
				this.showRegisterSuccessModal = true
			})
		},
	},
}
</script>

<style lang="scss">
.app-content,
#view-route-partial {
	height: calc(100vh);
	height: calc((var(--vh, 1vh) * 100));
	// @include respond(phone) {
	// 	height: calc((var(--vh, 1vh) * 100));
	// }
	// @include respond(mobile-land) {
	// 	height: calc((var(--vh, 1vh) * 100));
	// }
}

.fadeIn-enter-active {
	animation: fadeIn 0.2s;
}
.fadeIn-leave-active {
	animation: fadeIn 0.2s reverse;
}
</style>
