import { mapActions, mapGetters } from 'vuex'
import { RTM_MESSAGE_TYPES, GIFT_ANIMATION_TYPES, PK_STATUS } from '@/configs/RTM_SDK'
import { STATUS_RESPONSES, STATUS_VIDEO, TRANSACTION_STATUS } from '@/configs/SDK'
import {
	STATUS_MESSAGES,
	ROOM_CHANNEL_MESSAGES,
	RTM_MESSAGES,
} from '@/messages/System'
import { REGEXS } from '@/configs/Settings'
import { DEFAULT_ICON_STATES } from '@/configs/Icons'
import {EVENTS} from "~/configs/EVENTS";

export default {
	name: 'ChatMixins',
	components: {},
	mixins: [],
	props: {},
	data() {
		return {
			RTM_MESSAGE_TYPES,
			isAtBottom: true,
			isWaitingChat: false,
			isWaitingLike: false,
			isShowingWarningSpam: false,
			numberLikeInSecond: 0,
			timeOutCheckSpamId: null,
			isKicked: false,
			listUserCurrentJoin: [],
			listTotalUserJoin: [],
			currentUserAppearIndex: 0,
			indexUserJoined: 0,
			isAnimation: false,
			isUsePascalCase: true,
			indexLikeMessage: 0,
			timeoutCheckStatisticId: null,
			rsaWorker: null,

			/**
			 * Gift combo
			 */
			comboSent: {},
			comboTime: 3000, // milliseconds
			GIFT_ANIMATION_TYPES,

			/**
			 * Receive Gift Messages
			 */
			jobUpdateGiftMessagesId: null,
			tmpUpdateGiftMessages: [],
			jobUpdateGiftMessageTime: 1,

			remaingMutingTime: 0,

			/**
			 * Like
			 */
			likeCountLocal: 0,
			shouldShowLikeMessage: true,
			syncLikeInterval: 5000,
			syncLineIntervalId: null,

			chatBoxKeyRender: Date.now(),
			shouldPreventChatInput: false
		}
	},
	computed: {
		...mapGetters({
			streamDetail: 'stream/streamDetail',
			dataStreamStatistic: 'stream/streamStatistic',
			isLoggedIn: 'isLoggedIn',
			pinnedMessage: 'stream/pinnedMessage',
			hostInfo: 'stream/hostInfo',
			coHostInfo: 'stream/coHostInfo',
			naughtyWords: 'channel/naughtyWords',
			userChat: 'channel/userChat',
			projectSettings: 'projectSettings',
			myAssets: "giftStore/myAssets"
		}),
		options() {
			return {
				messageProp: 'camel',
				allowRandomColor: this.projectSettings.chat.allowRandomColor,
				allowGuestJoinedMessage:
					this.projectSettings.chat.allowGuestSendJoinMessage,
				addToTextMessages: [],
				pushMessageToMainThread: [
					RTM_MESSAGE_TYPES.LIKE,
					RTM_MESSAGE_TYPES.GIFT,
				],
			}
		},
		reaminingTimeToChatText() {
			return this.$t(
				`pages.stream.You are typing quite fast! We need time to read Please hold for ___time___ seconds`,
				{
					time: this.remaingMutingTime,
				}
			)
		},
	},
	created() {},
	mounted() {
		this.$root.$on('clear-statistic-job', () => {
			clearTimeout(this.timeoutCheckStatisticId)
			this.timeoutCheckStatisticId = null
		})
		this.syncLineIntervalId = setInterval(() => {
			this.syncLikeToServer()
		}, this.syncLikeInterval)

		this.$root.$on('show-switching-banner', () => {
			this.shouldPreventChatInput = true
		})

		this.$root.$on('hide-switching-banner', () => {
			this.shouldPreventChatInput = false
		})
	},
	beforeDestroy() {
		clearInterval(this.syncLineIntervalId)
	},
	watch: {
		listTotalUserJoin(newValue) {
			if (!this.isAnimation) {
				const newMsg = newValue[this.currentUserAppearIndex]
				if (!newMsg) return
				this.addUserJoin(newMsg)
			}
		},
		isAnimation(newValue) {
			if (newValue) return
			const newMsg = this.listTotalUserJoin[this.currentUserAppearIndex]
			if (!newMsg) return
			this.addUserJoin(newMsg)
		},
		isSwitchingPKToNormalStream() {
			this.chatBoxKeyRender = Date.now()
		}
	},
	methods: {
		...mapActions({
			updateStreamDetail: 'stream/updateStreamDetail',
			updateStreamStatistic: 'stream/updateStreamStatistic',
			addNewLikedAnimation: 'channel/addNewLikedAnimation',
			updatePinnedMessage: 'stream/updatePinnedMessage',
			removeFirstLikeAnimation: 'channel/removeFirstLikeAnimation',
			updateNaughtyWords: 'channel/updateNaughtyWords',
			updateUserChat: 'channel/updateUserChat',
			updateHostInfo: 'stream/updateHostInfo',
			updateCoHostInfo: 'stream/updateCoHostInfo',
			updatePkStatistic: 'stream/updatePkStatistic',
			updatePkInfo: 'stream/updatePkInfo',
			updatePkConfig: 'stream/updatePkConfig',
			updateTransaction: 'transaction/updateTransaction',
			updateMyAssets: 'giftStore/updateMyAssets',
		}),

		sendLikeStream() {
			this.likeCountLocal++
			this.updateStreamStatistic({
				likeCount: this.dataStreamStatistic.likeCount + 1
			})
			const msgObject = {
				message: RTM_MESSAGES.LIKE_STREAM,
				shouldShowLikeMessage: this.shouldShowLikeMessage,
			}
			this.shouldShowLikeMessage = false
			this.sendLikeRtm(msgObject)
		},
		async syncLikeToServer() {
			if (this.likeCountLocal === 0) {
				return
			}
			const { code, data } =
				await this.$httpResources.repositories.stream.like({
					slug: this.streamDetail.slug,
					count: this.likeCountLocal,
				})

			if (code === STATUS_RESPONSES.SUCCESS) {
				this.likeCountLocal = 0
				// Update data statistic stream: this data will show on the stream UI
				this.updateStreamStatistic({
					likeCount: parseInt(data.likeCount)
						? parseInt(data.likeCount)
						: 0,
				})
			}
		},
		sendLikeRtm(msgObject) {
			this.checkAntiSpamLike(msgObject)

			if (!this.timeOutCheckSpamId) {
				this.timeOutCheckSpamId = setTimeout(() => {
					this.numberLikeInSecond = 0
					this.timeOutCheckSpamId = null
				}, this.projectSettings.chat.likeInterval)
			}
		},
		checkAntiSpamLike(msgObject) {
			if (
				this.numberLikeInSecond <
					this.projectSettings.chat.maxLikePerSecond &&
				(!this.streamDetail.isTrivia || msgObject.shouldShowLikeMessage)
			) {
				this.numberLikeInSecond++
				this.$refs.chatBox.$refs.rtmSdk.sendMessage(
					'like',
					RTM_MESSAGE_TYPES.LIKE,
					msgObject
				)
			} else {
				this.indexLikeMessage++
				const transition = {
					bgClassAnimation:
						'bg-animation-' + Math.floor(Math.random() * 3 + 1),
					animationClass:
						'animation-style-' + Math.floor(Math.random() * 3 + 1),
					index: `local-like-${this.indexLikeMessage}`,
				}
				this.addNewLikedAnimation({ ...msgObject, ...transition })
			}
		},

		/**
		 *
		 * @param message
		 * @param type
		 * @param otherInfo
		 * @returns {{other_data: string, is_vip: *, sender_level: *, message_type: number, message: *, receiver_type: number, receiver_username: string}}
		 */
		async formatMessageChannelBeforeSend(
			message,
			type = RTM_MESSAGE_TYPES.MESSAGE,
			otherInfo = {}
		) {
			if (!type) type = this.RTM_MESSAGE_TYPES.MESSAGE

			if (
				this.remaingMutingTime &&
				this.projectSettings?.rtm.limitSenderOptions.limitOnMessageTypes.includes(
					type
				)
			) {
				this.$notification.w(
					ROOM_CHANNEL_MESSAGES.REACH_LIMIT_SENDER,
					STATUS_MESSAGES.WARNING
				)
				return
			}

			message = message.trim()

			let messageChannel = Object.assign(
				{
					giftCount: 1,
					isCompleted: false,
					channelId: this.streamDetail.slug
				},
				otherInfo,
				{
					message_type: type,
					receiver_type: 1,
					receiver_username: '',
					other_data: '',
					message,
					sender_level: this.userChat.level,
					sender_display_name: this.userChat.displayName,
					sender_user_id: this.userChat.userId,
					sender_user_name: this.userChat.userName,
					sender_avatar:
						this.userChat.senderAvatar ||
						DEFAULT_ICON_STATES.logo.activeSrc,
				}
			)

			// Format message keys:
			if (this.isUsePascalCase) {
				messageChannel = await this.convertSnakeToPascal(messageChannel)
			}

			switch (type) {
				case RTM_MESSAGE_TYPES.MESSAGE: {
					// Check naughty:
					// let isNaughty = false
					// const arrayWords = message.trim().split(' ')
					// arrayWords.map((messageWord) => {
					// 	return this.naughtyWords.map((naughtyWord) => {
					// 		if (
					// 			naughtyWord.trim().toLowerCase() ===
					// 			messageWord.trim().toLowerCase()
					// 		) {
					// 			// isNaughty = true
					// 			message = message.replace(messageWord, '***')
					// 		}
					// 		return message
					// 	})
					// })
					// console.log(isNaughty)

					this.naughtyWords.forEach((naughtyWord) => {
						const pattern = new RegExp(
							'\\b' + naughtyWord.replaceAll('*', '\\*') + '\\b',
							'gi'
						)
						message = message.replace(pattern, '***')
					})

					messageChannel = Object.assign(messageChannel, {
						message,
					})
					// if (isNaughty) {
					//     this.$root.$emit("warning-user-naughty-word");
					//     return null;
					// }

					// // check URLs:
					// if (new RegExp(REGEXS.ANY_URL).test(message)) {
					//     this.$root.$emit("warning-user-naughty-word");
					//     return null;
					// }
					break
				}
				case RTM_MESSAGE_TYPES.GIFT: {
					if (
						messageChannel.giftAnimationType ===
							GIFT_ANIMATION_TYPES.COMBO &&
						!messageChannel.isCompleted
					) {
						if (!this.comboSent[messageChannel.giftId]) {
							this.comboSent[messageChannel.giftId] =
								messageChannel
							this.createWaitingCombo(messageChannel)
						} else {
							this.comboSent[messageChannel.giftId].giftCount =
								this.comboSent[messageChannel.giftId]
									.giftCount + 1
							messageChannel = {
								...messageChannel,
								...{
									giftCount:
										this.comboSent[messageChannel.giftId]
											.giftCount,
								},
							}
							clearTimeout(
								this.comboSent[messageChannel.giftId].timeoutId
							)
							this.createWaitingCombo(messageChannel)
						}
						this.comboSent = { ...this.comboSent }
					} else if (
						messageChannel.giftAnimationType !==
						GIFT_ANIMATION_TYPES.COMBO
					) {
						this.textMessages.push(messageChannel)
					}
				}
			}
			return { ...messageChannel }
		},

		/**
		 *
		 * @param {*} messageChannel
		 */
		createWaitingCombo(messageChannel) {
			const timeoutCombo = setTimeout(() => {
				messageChannel = {
					...messageChannel,
					...{
						isCompleted: true,
					},
				}
				if (this.comboSent[messageChannel.giftId]) {
					delete this.comboSent[messageChannel.giftId]
					this.comboSent = { ...this.comboSent }
				}
				if (this.streamDetail.status === STATUS_VIDEO.LIVE) {
					this.$refs.rtmSdk.sendMessage(
						'gift',
						RTM_MESSAGE_TYPES.GIFT,
						messageChannel
					)
				}
				this.textMessages.push(messageChannel)
			}, this.comboTime)
			this.comboSent[messageChannel.giftId].timeoutId = timeoutCombo
			this.comboSent = { ...this.comboSent }
		},

		/**
		 *
		 * @param messageItem
		 * @param coHostName
		 * @returns {string}
		 */
		formatMessageChannel(messageItem, coHostName = '') {
			let message = ''
			switch (messageItem.messageType) {
				case this.RTM_MESSAGE_TYPES.MESSAGE: {
					message = ` ${messageItem.message}`
					break
				}
				case this.RTM_MESSAGE_TYPES.USER_JOIN: {
					message = this.$t('streamMessage.has joined the livestream')
					break
				}
				case this.RTM_MESSAGE_TYPES.USER_LEFT: {
					message = ' has left room.'
					break
				}
				case this.RTM_MESSAGE_TYPES.FOLLOW: {
					message = ' became a Fan!'
					break
				}
				case this.RTM_MESSAGE_TYPES.LIKE: {
					message = this.$t('streamMessage.liked the livestream')
					break
				}
				case this.RTM_MESSAGE_TYPES.SHARED_STREAM: {
					message = ' shared the livestream'
					break
				}
				case this.RTM_MESSAGE_TYPES.GIFT: {
					message = ` ${this.$t(`streamMessage.${messageItem.giftAnimationType === GIFT_ANIMATION_TYPES.COMBO ? 'sent combo gift' : 'sent gift'}`)} ${messageItem.giftName}`
					break
				}
				case this.RTM_MESSAGE_TYPES.PK_VIEWER_BOUGHT_PRODUCT: {
					message = this.formatString(this.$t(`streamMessage.has bought products from`), coHostName)
					break
				}
			}
			return message
		},

		/**
		 *
		 * @param type
		 * @returns {string}
		 */
		getClassFromTypeMessageChannel(type) {
			let classMessage = ''
			switch (type) {
				case this.RTM_MESSAGE_TYPES.MESSAGE: {
					classMessage = 'text'
					break
				}
				case this.RTM_MESSAGE_TYPES.USER_JOIN: {
					classMessage = 'userJoin'
					break
				}
				case this.RTM_MESSAGE_TYPES.USER_LEFT: {
					classMessage = 'userLeft'
					break
				}
				case this.RTM_MESSAGE_TYPES.START_SHARE_SCREEN:
				case this.RTM_MESSAGE_TYPES.STOP_SHARE_SCREEN: {
					classMessage = 'share-screen'
					break
				}

				case this.RTM_MESSAGE_TYPES.LIKE: {
					classMessage = 'like'
					break
				}
				case this.RTM_MESSAGE_TYPES.SHARED_STREAM: {
					classMessage = 'shareLiveStream'
					break
				}
				case this.RTM_MESSAGE_TYPES.FOLLOW: {
					classMessage = 'followHost'
					break
				}
				case this.RTM_MESSAGE_TYPES.GIFT: {
					classMessage = `gift`
					break
				}
				case this.RTM_MESSAGE_TYPES.PK_VIEWER_BOUGHT_PRODUCT: {
					classMessage = `viewer-bought-product`
					break
				}
			}
			return classMessage
		},
		getClassMessageByLevel(level) {
			if (level > 10 && level <= 20) return 'level--2'
			else if (level > 20) return 'level--3'
			return ''
		},

		/**
		 *
		 * @param {*} message
		 */
		checkMessageHasUrls(message) {
			let hasUrl = false
			const replaced = message.search(REGEXS.ANY_URL) >= 0
			if (replaced) {
				// Remove html:
				message = message
					.replace(/<[^>]+>/g, '')
					.replace(
						REGEXS.ANY_URL,
						"<a href='$1' target='_blank'>$1</a>"
					)
				hasUrl = true
			}

			return {
				message,
				hasUrl,
			}
		},

		async createTimeoutCheckStatistic() {
			if (!this.timeoutCheckStatisticId) {
				this.timeoutCheckStatisticId = setTimeout(() => {
					this.$root.$emit('stream-ended')
					// clear old timeout:
					clearTimeout(this.timeoutCheckStatisticId)
					this.timeoutCheckStatisticId = null
				}, this.projectSettings.stream.configs.checkStatisticInterval * 1000)
			} else {
				// clear old timeout:
				await clearTimeout(this.timeoutCheckStatisticId)
				this.timeoutCheckStatisticId = null
				this.timeoutCheckStatisticId = setTimeout(() => {
					this.$root.$emit('stream-ended')
					// clear old timeout:
					clearTimeout(this.timeoutCheckStatisticId)
					this.timeoutCheckStatisticId = null
				}, this.projectSettings.stream.configs.checkStatisticInterval * 1000)
			}
		},

		/**
		 *
		 * @param data
		 * @returns {null|*}
		 */
		formatNewMessageChannel(data) {
			if (!data) return

			switch (data.messageType) {
				case RTM_MESSAGE_TYPES.MESSAGE: {
					const formatHasUrlMessage = this.checkMessageHasUrls(
						data.message
					)

					data = Object.assign(data, {
						message: formatHasUrlMessage.message,
						hasUrl: formatHasUrlMessage.hasUrl,
					})

					return this.textMessages.push(data)
				}

				case RTM_MESSAGE_TYPES.STATISTICS: {
					if (data.senderUserId !== this.hostInfo.userId) {
						return;
					}
					let messageData = data
					if (!this.projectSettings?.rtm?.useAgora && data.message) {
						// using grpc
						messageData = JSON.parse(data.message.toString())
					}
					// update data statistic stream:
					this.updateStreamStatistic({
						likeCount: parseInt(messageData.totalLikes)
							? parseInt(messageData.totalLikes)
							: this.dataStreamStatistic.likeCount,
						viewCount: parseInt(messageData.totalViewers)
							? parseInt(messageData.totalViewers)
							: this.dataStreamStatistic.viewCount,
					})

					this.updateStreamDetail({
						promoCode: messageData.promoCode
							? messageData.promoCode
							: null,
						isShowPromo: !!messageData.promoCode,
						cartUrl: messageData.cartUrl
							? messageData.cartUrl
							: null,
						isShowViewCount: messageData.isShowView
							? messageData.isShowView
							: false,
						isShowLikeCount: messageData.isShowLike
							? messageData.isShowLike
							: false,
					})

					this.createTimeoutCheckStatistic()
					break
				}

				case RTM_MESSAGE_TYPES.GIFT: {
					this.handleReceivedGift(data)
					break
				}

				case RTM_MESSAGE_TYPES.HOST_SORT_PRODUCT: {
					this.resortStreamProducts({
						newIndex: data.newIndex,
						productId: data.productId,
					})
					break
				}

				case RTM_MESSAGE_TYPES.LIKE: {
					this.addNewLikedAnimation(data)
					return null
				}

				case RTM_MESSAGE_TYPES.HOST_PINNED_MESSAGE: {
					const formatHasUrlMessage = this.checkMessageHasUrls(
						data.message
					)

					data = Object.assign(data, {
						message: formatHasUrlMessage.message,
						hasUrl: formatHasUrlMessage.hasUrl,
					})

					this.updatePinnedMessage(data)
					break
				}
				case RTM_MESSAGE_TYPES.HOST_UNPIN_MESSAGE: {
					this.updatePinnedMessage(null)
					break
				}
				case RTM_MESSAGE_TYPES.ADMIN_MUTED: {
					if (this.userChat?.userName === data.targetUsername) {
						this.updateStreamDetail({
							isMute: true,
						})
						this.$root.$emit(`warning-user-muted`)
					}
					break
				}
				case RTM_MESSAGE_TYPES.ADMIN_UN_MUTED: {
					if (this.userChat?.userName === data.targetUsername) {
						this.updateStreamDetail({
							isMute: false,
						})
					}
					break
				}

				case RTM_MESSAGE_TYPES.ADMIN_KICK: {
					const dataAdminMessage = JSON.parse(data.message)
					if (
						this.userChat?.userId ===
						dataAdminMessage.ReceiverUserId
					) {
						this.isKicked = true
						this.$notification.w(
							ROOM_CHANNEL_MESSAGES.USER_WAS_KICKED_ROOM,
							STATUS_MESSAGES.WARNING
						)
						this.$router.push({ name: 'Home' })
					}
					break
				}
				case RTM_MESSAGE_TYPES.ADMIN_UN_KICK: {
					const dataAdminMessage = JSON.parse(data.message)
					if (
						this.userChat?.userId ===
						dataAdminMessage.ReceiverUserId
					) {
						this.isKicked = false
					}
					break
				}

				case RTM_MESSAGE_TYPES.USER_JOIN: {
					if (
						this.listTotalUserJoin.length - 1 <=
						this.currentUserAppearIndex
					) {
						this.listTotalUserJoin.push(data)
					} else if (this.isAnimation) {
						this.listTotalUserJoin[
							this.listTotalUserJoin.length - 1
						].concurrent += 1
					}
					break
				}

				case RTM_MESSAGE_TYPES.NOT_CONNECTED_CHAT_SERVER: {
					// this.$notification.w(ROOM_CHANNEL_MESSAGES.NOT_CONNECTED_CHAT_SERVER, STATUS_MESSAGES.WARNING);
					break
				}

				case RTM_MESSAGE_TYPES.RECONNECT_CHAT_SUCCESS: {
					this.$notification.s(
						ROOM_CHANNEL_MESSAGES.RECONNECT_CHAT_SUCCESS,
						STATUS_MESSAGES.SUCCESS
					)
					break
				}
				case RTM_MESSAGE_TYPES.HOST_STREAM_ENDED: {
					if (data.senderUserId !== this.hostInfo.userId) {
						return;
					}
					this.$root.$emit('stream-ended')
					break
				}
				case RTM_MESSAGE_TYPES.ADMIN_START_POLL: {
					this.$root.$emit('start-poll')
					break
				}

				case RTM_MESSAGE_TYPES.ADMIN_STOP_POLL: {
					this.$root.$emit('end-poll')
					break
				}

				case RTM_MESSAGE_TYPES.PK: {
					if ((parseInt(data.senderUserId) !== parseInt(this.hostInfo.userId)) && (parseInt(data.senderUserId) !== parseInt(this.coHostInfo.uid))) {
						return;
					}

					this.updatePkInfo({
						channelId: data.channelId,
						duration: data.pkDuration,
						status: data.status
					})

					switch (data.status) {
						case PK_STATUS.NOTIFYPREPARING: {
							if ((parseInt(data.senderUserId) === parseInt(this.hostInfo.userId))) {
								// Update Pk Config + Cohost
								if (data.pkConfig) {
									this.updatePkConfig({
										slug: data.pkConfig?.slug || "",
										opponentAvatarUrl: data.pkConfig.opponentAvatarUrl,
										opponentDisplayName: data.pkConfig.opponentDisplayName,
										opponentUserName: data.pkConfig.opponentUserName,
										opponentUserId: data.pkConfig.opponentUserId,
									})

									this.updateCoHostInfo({
										slug: data.pkConfig.slug,
										userImage: data.pkConfig.opponentAvatarUrl,
										uid: data.pkConfig.opponentUserId,
										userName: data.pkConfig.opponentUserName,
										displayName: data.pkConfig.opponentDisplayName,
									})
								}
							}
							break
						}

						case PK_STATUS.STARTED: {
							this.$root.$emit('hide-switching-banner')
							// Update Host/coHost info
							// update data pk statistic stream:
							this.updateDataPkStatistic(data)
							break
						}

						case PK_STATUS.ENDED: {
							// Update Host/coHost info
							// update data pk statistic stream:
							this.updateDataPkStatistic(data)
							break
						}

						case PK_STATUS.PUNISHING: {
							// this.resetDataPk()
							// If viewer join when PK Over, need to update score for host and co-host
							this.updateDataPkStatistic(data)
							break
						}

						case PK_STATUS.DISCONNECTED: {
							this.resetDataPk()
							this.$root.$emit('disconnecting-pk', { slug: this.coHostInfo.slug })
							this.updateCoHostInfo(null)
							this.$root.$emit('show-switching-banner')
							break
						}

						default: {
							break
						}
					}

					break
				}
				case RTM_MESSAGE_TYPES.PK_VIEWER_BOUGHT_PRODUCT: {
					this.textMessages.push(data)
					break
				}
				default: {
					return this.textMessages.push(data)
				}
			}
		},

		resetDataPk() {
			this.updatePkStatistic({})
			this.updateHostInfo({
				score: 0
			})
			this.updateCoHostInfo({
				score: 0
			})
		},
		updateDataPkStatistic(data) {
			// Update Host/coHost info
			// update data pk statistic stream:
			if (data.pkStatistic) {
				this.updatePkStatistic(data.pkStatistic)

				let keyHostFilter = 'challengee'
				let keyCoHostFilter = 'challenger'
				if (parseInt(this.hostInfo.userId) === parseInt(data.pkStatistic.challengerUserId)) {
					keyHostFilter = 'challenger'
					keyCoHostFilter = 'challengee'
				}
				this.updateHostInfo({
					score: (data.status === PK_STATUS.INITIALISING) ? 0 : data.pkStatistic[`${keyHostFilter}StarCount`]
				})

				this.updateCoHostInfo({
					score: (data.status === PK_STATUS.INITIALISING) ? 0 : data.pkStatistic[`${keyCoHostFilter}StarCount`]
				})
			}
		},

		/**
		 *
		 * @param {*} data
		 */
		handleReceivedGift(data) {
			this.$emit('receivedGift', data)
			if (data.channel === this.hostInfo.slug) {
				this.updateHostInfo({
					totalGold: parseFloat(data.receiverStars),
				})
			}

			if (data.giftAnimationType === GIFT_ANIMATION_TYPES.COMBO) {
				// Gift combo:
				if (data.isCompleted) {
					this.tmpUpdateGiftMessages.push(data)
				}
			} else {
				this.tmpUpdateGiftMessages.push(data)
			}
			if (!this.jobUpdateGiftMessagesId) {
				this.createJobUpdateGiftMessagesPerTime()
			}
		},

		/**
		 * Job Update Gift Messages
		 */
		createJobUpdateGiftMessagesPerTime() {
			this.jobUpdateGiftMessagesId = setTimeout(() => {
				this.$refs.rtmSdk.addTextMessages(this.tmpUpdateGiftMessages)
				this.tmpUpdateGiftMessages = []
				this.jobUpdateGiftMessagesId = null
			}, this.jobUpdateGiftMessageTime * 1000)
		},

		shouldReceiveMessageChannel(data) {
			return true
		},

		formatNewMessagePeer(text) {
			try {
				const data = JSON.parse(text)
				switch (data.messageType) {
					case this.RTM_MESSAGE_TYPES.ADMIN_KICK:
					case this.RTM_MESSAGE_TYPES.HOST_KICKED: {
						this.isKicked = true
						this.$notification.w(
							ROOM_CHANNEL_MESSAGES.USER_WAS_KICKED_ROOM,
							STATUS_MESSAGES.WARNING
						)
						this.$router.push({ name: 'ErrorGeneral' })
						break
					}

					case this.RTM_MESSAGE_TYPES.ADMIN_UN_KICK:
					case this.RTM_MESSAGE_TYPES.HOST_UNKICKED: {
						this.isKicked = false
						break
					}

					case this.RTM_MESSAGE_TYPES.ADMIN_MUTED:
					case this.RTM_MESSAGE_TYPES.HOST_MUTED: {
						this.updateStreamDetail({
							isMute: true,
						})
						this.$root.$emit(`warning-user-muted`)
						break
					}

					case this.RTM_MESSAGE_TYPES.ADMIN_UN_MUTED:
					case this.RTM_MESSAGE_TYPES.HOST_UNMUTED: {
						this.updateStreamDetail({
							isMute: false,
						})
						break
					}

					case this.RTM_MESSAGE_TYPES.ADMIN_BAN: {
						// this.$notification.e(ROOM_CHANNEL_MESSAGES.BANNED_BY_ADMIN, STATUS_MESSAGES.ERROR);
						this.$router.push({ name: 'ErrorGeneral' })
						break
					}

					case this.RTM_MESSAGE_TYPES.ADMIN_UN_BAN: {
						console.log('Unban')
						break
					}

					case this.RTM_MESSAGE_TYPES.PAYMENT_SUCCESS: {
						if (this.userChat?.userName === data.targetUsername) {
							this.updateTransaction({
								status: TRANSACTION_STATUS.PURCHASED,
								data: null,
							})
							this.updateMyAssets({
								...this.myAssets,
								bean: this.myAssets.bean + data.message.data.Hypers
							})
							// this.updateTransaction({
							// 	status: TRANSACTION_STATUS.ERROR,
							// 	data: { message: msgJson.ErrorMessage },
							// })
							this.$root.$emit(EVENTS.USER_PAYMENT_SUCCESSFULLY)
						}
						break
					}

					default:
						break
				}
			}
			catch (error) {
				console.error("P2P error", error)
			}

		},

		/**
		 *
		 * @param data
		 */
		addUserJoin(data) {
			if (!data) return
			this.indexUserJoined++

			const user = Object.assign(data, {
				indexJoin: this.indexUserJoined,
			})

			if (data.concurrent === 1)
				user.senderDisplayName = `${user.senderDisplayName} and other viewer`
			else if (data.concurrent > 1)
				user.senderDisplayName = `${user.senderDisplayName} and other viewers`
			this.isAnimation = true
			this.listUserCurrentJoin.push(user)
			setTimeout(() => {
				this.listUserCurrentJoin.splice(0, 1)
				setTimeout(() => {
					this.currentUserAppearIndex += 1
					this.isAnimation = false
				}, 400)
			}, 2000)
		},

		afterEnterLikeTransition(el) {
			el.remove()
			this.removeFirstLikeAnimation()
		},

		sendeLimit() {
			this.$notification.w(
				ROOM_CHANNEL_MESSAGES.REACH_LIMIT_SENDER,
				STATUS_MESSAGES.WARNING
			)
		},

		updateRemainingMuteTime(remaingTime) {
			this.remaingMutingTime = remaingTime
		},

		loggedInRtm() {
			this.$root.$emit("status-log-in-rtm", true)
		}
	},
}
