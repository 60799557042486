import { SDK_API } from '@/configs/APIs'
const PAYMENT_APIS = SDK_API.PAYMENT

export default ($axios) => ({
  /**
   * Get all topup
   */
  async getTopUp() {
    const {
      data: { code, data, message },
    } = await $axios.post(`${PAYMENT_APIS.TOP_UP}?type=1`)
    return { code, data, message }
  },

  /**
   * Create checkout session
   * @param {*} param
   */
  async createCheckoutSession(topupId) {
    const { data: response } = await $axios.post(
      PAYMENT_APIS.CREATE_CHECKOUT_SESSION,
      {
        successUrl: `${window.location.origin}/recharge/credit-card?payment=success&session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/recharge/credit-card?payment=cancel&session_id={CHECKOUT_SESSION_ID}`,
        topUpId: topupId,
      }
    )
    return response
  },
})
