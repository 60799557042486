<template>
	<div id="fb-login-button" @click="logInWithFacebook">
		<slot></slot>
	</div>
</template>

<script>
import { PUBLIC_SDK_SCRIPT } from '~/configs/Settings'
import { SIGN_IN_TYPE } from '~/configs/SDK'

export default {
	name: 'SignInWithFacebook',
	mounted() {
		this.init(document, 'script', 'facebook-jssdk', this.initFb)
	},
	methods: {
		init(d, s, id, callBack) {
			if (process.browser) {
				const fjs = d.getElementsByTagName(s)[0]
				if (d.getElementById(id)) {
					return
				}
				const js = d.createElement(s)
				js.id = id
				js.src = PUBLIC_SDK_SCRIPT.FACEBOOK
				js.addEventListener('load', callBack)
				fjs.parentNode.insertBefore(js, fjs)
			}
		},
		initFb() {
			if (process.browser) {
				window.fbAsyncInit = function () {
					// eslint-disable-next-line no-undef
					const fb = FB
					fb.init({
						appId: process.env.FACEBOOK_APP_ID,
						cookie: true,
						xfbml: true,
						version: process.env.FACEBOOK_API_VERSION,
					})
					fb.AppEvents.logPageView()
				}
			}
		},
		logInWithFacebook() {
			const _this = this
			if (process.browser) {
				window.FB.login(function (response) {
					if (response.status === 'connected') {
						window.FB.api(
							'/me?fields=email,name',
							function (resProfile) {
								if (process.env.APP_ENV !== 'production')
									console.log('FACEBOOK RESPONSE', {
										...response.authResponse,
										...resProfile,
									})
								_this.$emit(
									'onSuccess',
									{
										...response.authResponse,
										...resProfile,
									},
									SIGN_IN_TYPE.FACEBOOK
								)
							}
						)
					}
				})
			}
		},
	},
}
</script>
