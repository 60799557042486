import { SDK_API } from '@/configs/APIs'
const CHANNEL_APIS = SDK_API.CHANNEL

export default ($axios) => ({
	/**
	 * Get list naughty words
	 */
	async getNaughtyWords() {
		const {
			data: { code, data, message },
		} = await $axios.post(CHANNEL_APIS.NAUGHTY_WORDS)
		return { code, data, message }
	},
})
