<template>
	<div
		class="profile-side-panel position-relative"
	>
		<b-container class="pt-5 px-3">
			<b-row>
				<b-col cols="12" class="mb-5"
					><a href="#" @click="handleClickBackButton">
						<b-img
							src="@/static/media/icons/noun_back_arrow.svg"
						></b-img> </a
				></b-col>
			</b-row>
			<b-row>
				<b-col cols="12" class="d-flex justify-content-center">
					<b-link href="/">
						<b-img
							src="@/static/media/images/logo-footer.svg"
						></b-img> </b-link
				></b-col>
			</b-row>
			<b-row v-if="isLoggedIn && userDetail" class="mt-5 px-3">
				<b-col
					xl="4"
					lg="12"
					class="
						d-flex
						align-items-center
						justify-content-center
						px-0
					"
				>
					<img
						:src="loadImageSrc(userDetail.userImage)"
						class="profile-side-panel__avatar"
						alt="avatar"
						@error="loadImageError"
				/></b-col>
				<b-col
					xl="8"
					lg="12"
					class="
						d-flex
						align-items-center
						justify-content-md-center
						pr-0
						pl-3
					"
				>
					<div class="w-100">
						<div
							class="profile-side-panel__display-name text-white"
						>
							{{ userDetail.displayName }}
						</div>
						<div class="profile-side-panel__email text-white">
							{{ userDetail.email }}
						</div>
						<div
							v-if="!isInProfilePage"
							class="profile-side-panel__edit-label mt-3"
						>
							<nuxt-link to="/profile" class="text-white">
								{{ $t('textActions.Edit') }}
							</nuxt-link>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row v-if="isLoggedIn && userDetail" class="mt-5 px-0">
				<b-col cols="12" class="profile-side-panel__item"
					><img
						:src="HyperIcon"
						class="icon-wallet"
						alt="wallet"
					/><span class="text-white">
						{{ giftStore.bean.toLocaleString('en-US') }}
						{{ $t('headers.HYPER') }}
					</span></b-col
				>
			</b-row>
			<b-row class="mt-5 px-0">
				<b-col cols="12" class="profile-side-panel__item"
					><b-link href="/tickets">
						<img
							:src="TicketsIcon"
							class="icon-topup ticket-icon"
							alt="wallet"
						/><span class="text-white">
							{{ $t('pages.profilePage.Tickets') }}
						</span>
					</b-link></b-col
				>
			</b-row>
			<b-row class="mt-5 px-0">
				<b-col cols="12" class="profile-side-panel__item"
					><b-link href="/transaction-history">
						<img
							:src="TopUpHistoryMenuIcon"
							class="icon-topup transaction-icons"
							alt="wallet"
						/><span class="text-white">
							{{ $t('pages.profilePage.Transaction History') }}
						</span>
					</b-link></b-col
				>
			</b-row>
			<b-row class="mt-5 px-0">
				<b-col cols="12" class="profile-side-panel__item"
					><b-link href="/redeem">
						<img
							:src="RedeemIcon"
							class="icon-topup transaction-icons"
							alt="wallet"
						/><span class="text-white">
							{{ $t('pages.profilePage.Redeem') }}
						</span>
					</b-link></b-col
				>
			</b-row>
			<b-row class="mt-5 px-0">
				<b-col cols="12" class="profile-side-panel__item"
					><b-link href="/recharge">
						<img
							:src="TopUpMenuIcon"
							class="icon-topup"
							alt="wallet"
						/><span class="text-white">
							{{ $t('pages.profilePage.Top Up') }}
						</span>
					</b-link></b-col
				>
			</b-row>
			<b-row
				v-if="projectSettings.stream.features.shop"
				class="mt-5 px-0"
			>
				<b-col cols="12" class="profile-side-panel__item"
					><b-link href="/order-status">
						<img
							:src="OrderStatusIcon"
							class="icon-topup transaction-icons"
							alt="wallet"
						/><span class="text-white">
							{{ $t('pages.profilePage.Order Status') }}
						</span>
					</b-link></b-col
				>
			</b-row>
			<b-row
				v-if="projectSettings.stream.features.shop"
				class="mt-5 px-0"
			>
				<b-col cols="12" class="profile-side-panel__item"
				><b-link href="/checkout">
					<img
						:src="CheckoutIcon"
						class="icon-topup transaction-icons"
						alt="wallet"
					/><span class="text-white">
							{{ $t('pages.profilePage.Cart') }}
						</span>
				</b-link></b-col
				>
			</b-row>
			<b-row class="mt-5 px-0 logout_menu_item">
				<b-col cols="12" class="profile-side-panel__item"
					><div v-if="isLoggedIn" class="logout-button" @click="logoutUser">
						<img
							:src="LogoutIcon"
							class="icon-topup"
							alt="wallet"
						/><span class="text-white">
							{{ $t('headers.logout') }}
						</span>
					</div>
					<div v-else class="logout-button" @click="handleShowLoginModal">
						<img
							:src="ARROW_RIGHT_ICON"
							class="icon-topup"
							alt="wallet"
						/>
						<span class="text-white">
							{{ $t('headers.login') }}
						</span>
					</div>
				</b-col
				>
			</b-row>
		</b-container>

		<LoginModal
			v-if="showLoginModal"
			:open="showLoginModal"
			@hidden="showLoginModal = false"
			@showForgotPasswordModal="
				showLoginModal = false
				showForgotPasswordModal = true
			"
			@openSideBar="openSidebar"
			@handleShowRegisterModal="handleShowRegisterModal"
		/>
		<SignUpModal
			v-if="showRegisterModal"
			:open="showRegisterModal"
			@hidden="showRegisterModal = false"
			@show-register-confirmation="showRegisterConfirm"
			@handleShowLoginModal="handleShowLoginModal"
		/>
		<RegisterConfirmationModal
			v-if="showRegisterConfirmationModal"
			:email-confirm="emailConfirm"
			:open="showRegisterConfirmationModal"
			@hidden="showRegisterConfirmationModal = false"
		/>
		<RegisterSuccessModal
			:open="showRegisterSuccessModal"
			@hidden="
				showRegisterSuccessModal = false
				showLoginModal = true
			"
		/>
		<ForgotPasswordModal
			:open="showForgotPasswordModal"
			@hidden="showForgotPasswordModal = false"
			@show-register="
				showForgotPasswordModal = false
				showRegisterModal = true
			"
		/>
		<LogOutModal
			:open="showLogoutModal"
			@hidden="showLogoutModal = false"
		/>
	</div>
</template>

<script>
import i18nMixins from '@/mixins/i18nMixins'
import { mapActions, mapGetters } from 'vuex'
import TopUpIcon from '@/static/media/icons/profile_top_up.svg'
import TopUpHistoryIcon from '@/static/media/icons/profile_top_up_history.svg'
import RedeemIcon from '@/static/media/icons/redeem.svg'
import OrderStatusIcon from '@/static/media/icons/order_status.png'
import CheckoutIcon from '@/static/media/icons/supermarket_red.svg'
import LogOutModal from '@/components/modals/LogoutModal'
import LogoutIcon from '@/static/media/icons/log_out.svg'
import TicketsIcon from '@/static/media/icons/tickets.svg'
import HyperIcon from '@/static/media/icons/hyper.svg'
import { STATUS_RESPONSES } from '@/configs/SDK'
import SdkMixins from '@/mixins/SdkMixins'
import { DEFAULT_ICON_STATES } from '@/configs/Icons'
import SignUpModal from "@/components/modals/SignUpModal";
import LoginModal from "@/components/modals/LoginModal";
import RegisterConfirmationModal from "@/components/modals/RegisterConfirmationModal";
import RegisterSuccessModal from "@/components/modals/RegisterSuccessModal";
import ForgotPasswordModal from "@/components/modals/ForgotPasswordModal";

export default {
	name: 'ProfileSidePanel',
	components: {
		LogOutModal,
		SignUpModal,
		LoginModal,
		RegisterConfirmationModal,
		RegisterSuccessModal,
		ForgotPasswordModal,
	},
	mixins: [SdkMixins, i18nMixins],
	data() {
		return {
			TOP_UP_ICON: DEFAULT_ICON_STATES.token.activeSrc,
			TopUpMenuIcon: TopUpIcon,
			TopUpHistoryMenuIcon: TopUpHistoryIcon,
			RedeemIcon,
			OrderStatusIcon,
			LogoutIcon,
			TicketsIcon,
			HyperIcon,
			CheckoutIcon,
			ARROW_RIGHT_ICON: DEFAULT_ICON_STATES.arrowRightIcon.activeSrc,
			showLogoutModal: false,

			showLoginModal: false,
			showRegisterModal: false,
			showRegisterConfirmationModal: false,
			showRegisterSuccessModal: false,
			showForgotPasswordModal: false,
			emailConfirm: '',
		}
	},
	computed: {
		...mapGetters({
			isLoggedIn: 'isLoggedIn',
			user: 'user',
			userDetail: 'userDetail',
			projectSettings: 'projectSettings',
			giftStore: 'giftStore/myAssets',
		}),
		isInProfilePage() {
			return this.$route.name === 'profile'
		},
	},
	async mounted() {
		if (!this.isLoggedIn) {
			if (this.$route.name !== 'redeem-code') {
				this.$router.push('/')
			}
		} else {
			await this.getUserDetail()
		}

		this.$root.$on('required-login', () => {
			this.showLoginModal = true
		})
	},
	methods: {
		...mapActions({
			updateUserDetail: 'updateUserDetail',
		}),
		async getUserDetail() {
			const { code, data } =
				await this.$httpResources.repositories.user.detail({
					userId: this.user.userInfo.userId,
					userName: this.user.userInfo.userName,
					isExcludedPurchaseTicket: false
				})
			if (code === STATUS_RESPONSES.SUCCESS) {
				await this.updateUserDetail({
					...data.user,
					tickets: [...data.tickets],
					transactions: [...data.transactions],
					signInType: data.signInType,
				})
			}
		},
		logoutUser() {
			this.showLogoutModal = true
		},
		handleClickBackButton() {
			this.$router.go(-1)
		},
		openSidebar() {
			this.$root.$emit('bv::toggle::collapse', 'sidebar-left')
		},
		handleShowRegisterModal() {
			this.$root.$emit('user-register-when-redeem-voucher')
			this.showLoginModal = false
			this.showRegisterModal = true
		},
		handleShowLoginModal() {
			this.showLoginModal = true
			this.showRegisterModal = false
		},
		showRegisterConfirm(email) {
			this.emailConfirm = email
			this.showRegisterConfirmationModal = true
		},
	},
	watch: {
		async isLoggedIn() {
			if (!this.isLoggedIn) {
				this.$router.go()
			} else {
				await this.getUserDetail()
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.profile-side-panel {
	background-color: var(--dark);
	height: 100vh;
	width: 100%;
	min-height: 90rem;

	&__avatar {
		height: 8rem;
		width: 8rem;
		max-width: 10rem;
		border-radius: 50%;
		border: 1px solid $color-red-2;
		object-fit: cover;
	}
	&__edit-label {
		text-align: right;
		font-size: 1.5rem;
		text-decoration: underline !important;
	}
	&__display-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;
		@include respond(tablet) {
			text-align: center;
		}
	}
	&__email {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;
		@include respond(tablet) {
			text-align: center;
		}
	}
	.icon-wallet {
		width: 3.5rem;
		height: 3.5rem;
		margin-right: 1.22rem;
	}
	.icon-topup {
		width: 2.5rem;
		margin-right: 1.3rem;
		padding-left: 0.3rem;
		&.ticket-icon {
			width: 3rem;
			margin-right: 0.8rem;
		}
		&.transaction-icons {
			height: 2.5rem;
		}
	}
	&__item {
		padding-left: 6rem;
		.logout-button {
			cursor: pointer;
		}
	}
	.logout_menu_item {
		position: absolute;
		bottom: 5rem;
	}
}
</style>
