var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction-signal"},[_c('BeModal',{attrs:{"modal-options":_vm.modalOptions,"no-close-button":_vm.noCloseButton,"logo":_vm.transactionModal.logo,"title":_vm.transactionModal.title,"sub-title":_vm.transactionModal.subTitle},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.state.status === _vm.statusMessage.PURCHASED)?_c('div',{staticClass:"transaction-msg__body-content transaction-purchased"},[_c('img',{staticClass:"thank-you-icon icon-modal",attrs:{"src":_vm.SUCCESS_ICON,"alt":"thank-you-icon"}}),_c('p',{staticClass:"main"},[_vm._v(_vm._s(_vm.$t('formModals.Purchase Success')))]),_c('p',{staticClass:"sub"},[_vm._v(" "+_vm._s(_vm.$t( 'formModals.Check out your purchases in the Profile Page' ))+" ")]),_c('div',{staticClass:"btn-transaction"},[_c('button',{staticClass:"\n\t\t\t\t\t\t\tbtn btn-primary btn-animated\n\t\t\t\t\t\t\tw-100\n\t\t\t\t\t\t\ttext-uppercase\n\t\t\t\t\t\t",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('textActions.OK'))+" ")])])]):(_vm.state.status === _vm.statusMessage.COMPLETED)?_c('div',{staticClass:"transaction-msg__body-content transaction-completed"},[_c('img',{staticClass:"thank-you-icon icon-modal",attrs:{"src":_vm.SUCCESS_ICON,"alt":"thank-you-icon"}}),_c('p',{staticClass:"main"},[_vm._v(_vm._s(_vm.$t('formModals.Purchase Success')))]),_c('p',{staticClass:"sub"},[_vm._v(" "+_vm._s(_vm.$t( 'formModals.Congratulations, your transaction is successful' ))+" ")]),_c('p',{staticClass:"info",domProps:{"innerHTML":_vm._s(
						_vm.$t('formModals.You have purchased _xxx_ hypes', {
							hypes: _vm.state.dimonds.toLocaleString('en-US'),
						})
					)}}),_c('div',{staticClass:"btn-transaction"},[_c('button',{staticClass:"\n\t\t\t\t\t\t\tbtn btn-primary btn-animated\n\t\t\t\t\t\t\tw-100\n\t\t\t\t\t\t\ttext-uppercase\n\t\t\t\t\t\t",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('textActions.OK'))+" ")])])]):_c('div',{staticClass:"transaction-msg__body-content transaction-failed"},[_c('img',{staticClass:"transaction-failed-icon icon-modal",attrs:{"src":_vm.OPPS_ICON,"alt":"transaction-failed"}}),_c('p',{staticClass:"main"},[_vm._v(_vm._s(_vm.$t('formModals.Sorry')))]),_c('p',{staticClass:"sub"},[_vm._v(" "+_vm._s(_vm.$t('formModals.Please try a different payment method'))+" ")]),_c('div',{staticClass:"btn-transaction"},[_c('button',{staticClass:"\n\t\t\t\t\t\t\tbtn btn-primary btn-animated\n\t\t\t\t\t\t\tw-100\n\t\t\t\t\t\t\ttext-capitalize\n\t\t\t\t\t\t",on:{"click":_vm.retryPayment}},[_vm._v(" "+_vm._s(_vm.$t('textActions.Try Again'))+" ")])])])]},proxy:true},(_vm.state.status === _vm.statusMessage.COMPLETED)?{key:"header-custom",fn:function(){return [_c('div',{staticClass:"header"},[_c('div',{staticClass:"header__sub"},[_vm._v(" "+_vm._s(_vm.$t('formModals.Transaction'))+" ")]),_c('div',{staticClass:"header__primary"},[_vm._v(" "+_vm._s(_vm.state.transactionId)+" ")])]),_c('div',{staticClass:"close-button",on:{"click":_vm.close}},[_c('img',{staticClass:"close-icon",attrs:{"src":_vm.CLOSE_ICON}})])]},proxy:true}:(
				_vm.state.status === _vm.statusMessage.NONE ||
				_vm.state.status === _vm.statusMessage.ERROR
			)?{key:"header-custom",fn:function(){return [_c('div',{staticClass:"header"},[_c('div',{staticClass:"header__sub"},[_vm._v(" "+_vm._s(_vm.$t('formModals.Transaction Failed'))+" ")])]),_c('div',{staticClass:"close-button",on:{"click":_vm.close}},[_c('img',{staticClass:"close-icon",attrs:{"src":_vm.CLOSE_ICON}})])]},proxy:true}:null],null,true)}),(_vm.userChat && !_vm.inLivePage)?_c('SignalRtm',{ref:"signalRtm",attrs:{"server":_vm.server,"license":_vm.license,"user":_vm.userChat}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }