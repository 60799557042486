<template>
	<div id="profile">
		<HeaderProfile />
		<b-container class="px-0 mx-3 w-100">
			<b-row>
				<b-col
					cols="12"
					md="3"
					lg="3"
					class="d-lg-flex d-md-flex d-none px-0"
				>
					<ProfileSidePanel />
				</b-col>
				<b-col cols="12" lg="9" md="9" class="pr-0">
					<Nuxt />
				</b-col>
			</b-row>
		</b-container>
		<TransactionMessageModal id="trans-msg" />
	</div>
</template>

<script>
import LayoutMixins from '@/mixins/LayoutMixins'
import ProfileSidePanel from '@/components/side-panel/profile'
import TransactionMessageModal from '~/components/modals/TransactionMessageModal'
import HeaderProfile from '~/components/headers/HeaderProfile'

export default {
	components: {
		ProfileSidePanel,
		TransactionMessageModal,
		HeaderProfile,
	},
	mixins: [LayoutMixins],
	middleware: ['checkIsMaintenance', 'auth'],
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

#profile {
	font-family: 'Fira Sans', sans-serif;
	.profile-header {
		display: none;
		@include respond(tablet) {
			display: flex;
		}
	}
	.container {
		margin: 0 auto;
		max-width: calc(100% - 4rem);
		@include respond(mobile) {
			max-width: calc(100% - 3rem);
		}
	}
}
</style>
