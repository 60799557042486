<template>
	<ValidationObserver ref="verifyEmailForm" v-slot="{ invalid }">
		<form @submit.prevent="verifyEmailReset">
			<BInputWithValidation
				ref="email"
				v-model.trim="item.email"
				autofocus
				type="text"
				:placeholder="$t(`formModals.Email address`)"
				rules="required|email"
				vid="email"
				:name="$t(`formModals.Email`)"
			/>
			<div v-if="errorText" class="w-100 error-text">
				{{ errorText }}
			</div>
			<div v-if="invalidEmail" class="text-center guide-part">
				<div class="guide-part__text">
					{{ $t(`formModals.Doesnt have an account`) }}
				</div>
				<div class="guide-part__link" @click="showRegister">
					{{ $t(`formModals.Register here`) }}
				</div>
			</div>
			<div class="text-center action-verify-email" :class="actionClass">
				<button
					type="submit"
					:disabled="invalid"
					class="btn btn-primary btn-animated w-100"
				>
					{{ $t(`formModals.Continue`) }}
				</button>
			</div>
		</form>
	</ValidationObserver>
</template>

<script>
import BInputWithValidation from '@/components/BInputWithValidation'
import SdkMixins from '@/mixins/SdkMixins'
import { STATUS_RESPONSES } from '@/configs/SDK'

export default {
	name: 'VerifyEmailForm',
	components: {
		BInputWithValidation,
	},
	mixins: [SdkMixins],
	props: {
		actionClass: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		item: {
			email: '',
		},
		errorText: null,
		invalidEmail: false,
	}),
	computed: {},
	watch: {
		open(val) {
			if (val) this.$bvModal.show(this.modalOptions.id)
		},
		invalidEmail(val) {
			if (val) this.$emit('show-invalid-email')
		},
	},
	mounted() {
		if (this.open) this.$bvModal.show(this.modalOptions.id)
	},
	methods: {
		async verifyEmailReset() {
			const isValid = await this.$refs.verifyEmailForm.validate()
			if (!isValid) return
			this.$emit('start-loading')
			try {
				const { code, codeDetails, message } =
					await this.$httpResources.repositories.auth.verifyEmailReset(
						this.item.email
					)
				if (code === STATUS_RESPONSES.SUCCESS) {
					this.$emit('verify-email-success', this.item.email)
				} else {
					this.handleFormError(code, codeDetails, message)
				}
			} catch (error) {}
			this.$emit('stop-loading')
		},
		handleFormError(code, codeDetails, message) {
			if (code === STATUS_RESPONSES.INVALID_MODEL && codeDetails) {
				codeDetails.forEach((codeDetail) => {
					if (codeDetail.code === STATUS_RESPONSES.USER_NOT_FOUND) {
						// show register:
						this.invalidEmail = true
					} else {
						this.$refs.verifyEmailForm.setErrors({
							email: [message],
						})
					}
				})
			} else if (code === STATUS_RESPONSES.USER_NOT_FOUND) {
				// show register:
				this.invalidEmail = true
			}

			// this.$notification.e(SYSTEM_MESSAGES.EMAIL_NOT_EXIST, STATUS_MESSAGES.ERROR);
		},
		showRegister() {
			this.$emit('show-register')
		},
	},
}
</script>
<style lang="scss" scoped>
.action-verify-email {
	margin-top: 5rem;
	margin-bottom: 4rem;
}
</style>
