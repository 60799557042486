import { COOKIE_KEYS } from '@/configs/Settings'
import { mapActions } from 'vuex'
export default {
	name: 'i18nMixins',
	mixins: [],
	data() {
		return {
			selectedLang: null,
		}
	},
	computed: {
		availableLocales() {
			return this.$i18n.locales
		},
	},
	watch: {},
	created() {},
	mounted() {
		const lang = this.availableLocales.find(
			(locale) =>
				locale.code ===
				(this.$cookies.get(COOKIE_KEYS.SELECTED_LANG) ||
					this.getLanguageByCountry(this.country))
		)
		if (lang) this.handleSelectedLang(lang)
	},
	methods: {
		...mapActions(['updateLocale']),
		handleSelectedLang(lang) {
			this.selectedLang = lang
			this.updateLocale(lang.code)
			this.$cookies.set(COOKIE_KEYS.SELECTED_LANG, lang.code, {
				path: '/',
				maxAge: 60 * 60 * 24 * 365, // 1 year
			})
			if (lang.code !== this.$i18n.locale) {
				this.$i18n.setLocale(lang.code)
				const langId =
					this.$i18n.locales.find(
						(locale) => locale.code === lang.code
					)?.id || 0
				this.$httpResources.setHeader('language', langId)
			}
		},
		getLanguageByCountry(country) {
			if (country?.toLowerCase() === 'tw') {
				return 'zh-tw'
			} else if (country?.toLowerCase() === 'cn') {
				return 'zh-cn'
			} else if (country?.toLowerCase() === 'th') {
				return 'th'
			} else if (country?.toLowerCase() === 'vn') {
				return 'vi'
			} else {
				return 'en'
			}
		},
	},
}
