import { render, staticRenderFns } from "./error.vue?vue&type=template&id=1a12df80&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=1a12df80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a12df80",
  null
  
)

export default component.exports