<template>
	<div>
		<client-only>
			<div class="base default-footer d-none d-sm-block">
				<b-container class="m-auto">
					<footer>
						<div class="about-us-footer-logo">
							<b-nav class="flex-nowrap" vertical>
								<b-nav-item active
									><img
										src="@/static/media/icons/logo-footer-hyper-live.svg"
										class="w-50"
								/></b-nav-item>
								<b-nav-item
									><b-nav-text
										class="
											text-break
											about-us-footer-logo-desc
										"
										>{{
											$t(
												'footers.HYPERLIVE is the ultimate platform for entertainment and lifestyle'
											)
										}}</b-nav-text
									></b-nav-item
								>
								<b-nav-item
									><b-nav-text
										>©
										{{
											$t(
												'footers.2021 all rights reserved'
											)
										}}</b-nav-text
									></b-nav-item
								>
							</b-nav>
						</div>
						<div>
							<b-nav class="mt-5" vertical>
								<b-nav-item href="/about" active>
									{{ $t('headers.aboutUs') }}
								</b-nav-item>
								<b-nav-item href="/faq" active>
									{{ $t('headers.faq') }}
								</b-nav-item>
								<b-nav-item href="/faq/terms-of-use">{{
									$t('footers.termsConditions')
								}}</b-nav-item>
								<b-nav-item href="/faq/privacy-policy">{{
									$t('footers.privacyPolicy')
								}}</b-nav-item>
								<b-nav-item
									href="https://business.hyperlive.tv"
									>{{ $t('footers.corporate') }}</b-nav-item
								>
							</b-nav>
						</div>
						<div>
							<b-nav class="mt-5" vertical>
								<b-nav-text
									><strong>{{
										$t('footers.contactUs')
									}}</strong></b-nav-text
								>
								<b-nav-text
									><a
										class="text-white"
										href="mailto:hello@hyperlive.tv"
										><b-icon icon="envelope"></b-icon>
										hello@hyperlive.tv</a
									></b-nav-text
								>
								<b-nav-text
									><strong>{{
										$t('footers.followUs2')
									}}</strong></b-nav-text
								>
								<b-nav-text>
									<b-nav>
										<b-nav-item
											class="mr-5 footer-social-icon"
											:href="FACEBOOK_URL"
											><b-icon icon="facebook"></b-icon
										></b-nav-item>
										<b-nav-item
											class="mr-5 footer-social-icon"
											:href="INSTAGRAM_URL"
											><b-icon icon="instagram"></b-icon
										></b-nav-item>
									</b-nav>
								</b-nav-text>
							</b-nav>
						</div>
					</footer>
				</b-container>
			</div>
			<footer class="mobile footer d-block d-sm-none">
				<ul class="list-group list-group-flush text-center">
					<li
						class="
							list-group-item
							bg-transparent
							text-white
							p-4
							border-0
							mt-5
						"
					>
						<a href="/about">{{ $t('headers.aboutUs') }}</a>
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white
							p-4
							border-0
						"
					>
						<a href="/faq">{{ $t('footers.faq') }}</a>
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white
							p-4
							border-0
						"
					>
						<a href="/faq/terms-of-use">{{
							$t('footers.termsConditions')
						}}</a>
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white
							p-4
							border-0
						"
					>
						<a href="/faq/privacy-policy">{{
							$t('footers.privacyPolicy')
						}}</a>
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white
							p-4
							border-0
						"
					>
						<a href="https://business.hyperlive.tv">{{
							$t('footers.corporate')
						}}</a>
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white-50 text-uppercase
							pt-5
							pb-3
							border-0
						"
					>
						<h2>{{ $t('footers.contactUs') }}</h2>
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white
							p-4
							border-0
						"
					>
						<a class="text-white" href="mailto:hello@hyperlive.tv"
							><b-icon icon="envelope"></b-icon>
							hello@hyperlive.tv</a
						>
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white-50 text-uppercase
							p-5
							border-0
						"
					>
						<h2>{{ $t('footers.followUs') }}</h2>
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white
							p-4
							justify-content-center
							d-flex
							border-0
						"
					>
						<b-nav vertical>
							<b-nav-text>
								<b-nav>
									<b-nav-item
										class="mx-5 footer-social-icon"
										:href="FACEBOOK_URL"
										><b-icon
											icon="facebook"
											font-scale="3"
										></b-icon
									></b-nav-item>
									<b-nav-item
										class="mx-5 footer-social-icon"
										:href="INSTAGRAM_URL"
										><b-icon
											icon="instagram"
											font-scale="3"
										></b-icon
									></b-nav-item>
								</b-nav>
							</b-nav-text>
						</b-nav>
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white-50 text-uppercase
							p-5
							border-0
							flex-column
						"
					>
						<img
							src="@/static/media/icons/logo-footer-hyper-live.svg"
							class="w-25"
						/>
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white-50
							p-5
							border-0
							flex-column
						"
					>
						{{
							$t(
								'footers.HYPERLIVE is the ultimate platform for entertainment and lifestyle'
							)
						}}
					</li>
					<li
						class="
							list-group-item
							bg-transparent
							text-white
							p-4
							border-0
							mb-5
						"
					>
						<b-nav-text
							>©
							{{
								$t('footers.2021 all rights reserved')
							}}</b-nav-text
						>
					</li>
				</ul>
			</footer>
		</client-only>
	</div>
</template>
<script>
import { SOCIAL_NETWORK_URL } from '~/configs/SocialNetwork'

export default {
	data() {
		return {
			FACEBOOK_URL: SOCIAL_NETWORK_URL.Facebook,
			INSTAGRAM_URL: SOCIAL_NETWORK_URL.Instagram,
		}
	},
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/pages/footer.scss';

footer {
	font-family: $font-open-sans;
	&.mobile {
		.list-group {
			&-item {
				a {
					color: #fff;
				}
			}
		}
	}
	.about-us {
		&-footer-logo {
			width: 30%;
		}
		&-footer-logo-desc {
			font-size: 1.5rem;
		}
	}
}

.footer-social-icon > a {
	background-color: #de006a;
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	@include respond(phone) {
		width: 8rem;
		height: 8rem;
	}
}

.default-footer {
	height: 100%;
	padding: 2.5rem 0 0;
}
</style>
