import { TRANSACTION_STATUS } from "@/configs/SDK"

const state = () => ({
   transaction: {
      status: TRANSACTION_STATUS.NONE,
      data: null,
   },
})

const getters = {
   transactionState(state) {
      return state.transaction
   },
}

const mutations = {
   UPDATE_TRANSACTION(state, payload) {
      state.transaction = { ...state.transaction, ...payload }
   },
   RESET_TRANSACTION(state) {
      state.transaction = {
         status: TRANSACTION_STATUS.NONE,
         data: null,
      }
   },
}

const actions = {
   updateTransaction({ commit }, payload) {
      commit("UPDATE_TRANSACTION", payload)
   },
   resetTransaction({ commit }) {
      commit("RESET_TRANSACTION")
   },
}

export default {
   state,
   getters,
   mutations,
   actions,
}
