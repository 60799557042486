<template>
	<div id="faq">
		<Header />
		<Nuxt />
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/headers/HeaderFAQ.vue'
import Footer from '@/components/footer'
import LayoutMixins from '@/mixins/LayoutMixins'

export default {
	components: {
		Header,
		Footer,
	},
	mixins: [LayoutMixins],
	middleware: ['checkIsMaintenance'],
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');

#faq {
	font-family: 'Arvo', serif !important;
	footer {
		.desc {
			font-family: 'Arvo', serif !important;
		}
	}
	.container {
		margin: 0 auto;
	}
}
</style>
