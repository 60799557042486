import { FBQ_EVENTS } from '@/configs/FacebookPixel'
export default {
	name: 'FacebookPixelMixins',
	data() {
		return {}
	},
	mounted() {},
	methods: {
		initFacebookPixel(fbqId) {
			if (process.env.APP_ENV === 'production') {
				// eslint-disable-next-line no-undef
				fbq('init', fbqId)
			}
		},

		/**
		 * Track event: https://www.facebook.com/business/help/402791146561655?id=1205376682832142
		 * @param {*} event
		 */
		trackFacebookPixelEvent(event = FBQ_EVENTS.PAGE_VIEW) {
			// eslint-disable-next-line no-undef
			if (process.env.APP_ENV === 'production' && fbq) {
				// eslint-disable-next-line no-undef
				fbq('track', event)
			}
		},
	},
}
