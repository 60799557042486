import { STATUS_VIDEO } from '@/configs/SDK'
import { PK_STATUS } from '@/configs/RTM_SDK'

export const DEMO_STREAM_DETAIL = {
	status: STATUS_VIDEO.LIVE,
	slug: 'pk-host',
	publisher: {
		about: '',
		address: "It's a secret",
		countryCode: null,
		created: '14/10/2020T09:58:40.000Z',
		displayName: 'HyperLive',
		isSeller: 0,
		language: 0,
		notification: 1,
		status: 1,
		userId: 7749,
		userImage: '',
		userName: 'hyperlive',
		totalGold: 1234,
	},
	streamUrl:
		'https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8',
	mp4StreamUrl:
		'https://assets.mixkit.co/videos/preview/mixkit-tree-with-yellow-flowers-1173-large.mp4',
	// https://assets.mixkit.co/videos/preview/mixkit-tree-with-yellow-flowers-1173-large.mp4
	// http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4
	// https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8
	viewCount: 12345,
	likeCount: 5467,
	isLike: false,
	viewSessionId: 123,
	canPlay: true,
	reviveNumber: 3,
	shareStreamUrl: 'http://localhost:8080/watch/dasdd',
	streamId: 199999,
	beginStream: '2021-07-09T02:48:34',
	title: 'test stream',
	isShowViewCount: true,
	isShowLikeCount: true,
	isMute: false,
}

export const DEMO_PRODUCTS = [
	{
		id: 125,
		name: 'Tech Solid Bl Short Sleeve Tee',
		price: 39,
		description: '',
		promoCode: '',
		promotionPrice: 0,
		imageUrl:
			'https://dynamic.zacdn.com/rS9RtADdnYix9FKpAJgrf26QlXM=/fit-in/762x1100/filters:quality(95):fill(ffffff)/http://static.sg.zalora.net/p/under-armour-7726-4900371-1.jpg',
		url: 'https://www.zalora.sg/under-armour-tech-solid-bl-short-sleeve-tee-black-1730094.html',
		label: 1,
		state: '0',
		orderIndex: 1,
		brandName: 'Under Armour',
		sku: '797F5ACCE6CA01GS',
		discountPercentage: 0,
	},
	{
		id: 126,
		name: 'UA Hovr Sonic 4 Shoes',
		price: 179,
		description: '',
		promoCode: '',
		promotionPrice: 165.9,
		imageUrl:
			'https://dynamic.zacdn.com/df6mH3G3pfEktZlPk9-oY10p31Q=/fit-in/762x1100/filters:quality(95):fill(ffffff)/http://static.sg.zalora.net/p/under-armour-5445-7340371-1.jpg',
		url: 'https://www.zalora.sg/under-armour-ua-hovr-sonic-4-shoes-black-1730437.html',
		label: 2,
		state: '1',
		orderIndex: 2,
		brandName: 'Under Armour',
		sku: '54B36AC9015D3BGS',
		discountPercentage: 10,
	},
	{
		id: 127,
		name: 'Army Print Backpack',
		price: 60.9,
		description: '',
		promoCode: '',
		promotionPrice: 0,
		imageUrl:
			'https://dynamic.zacdn.com/HGakaZoKPrsQ6Vk1dI4VK14L0Os=/fit-in/762x1100/filters:quality(95):fill(ffffff)/http://static.sg.zalora.net/p/swiss-polo-0072-7599681-1.jpg',
		url: 'https://www.zalora.sg/swiss-polo-army-print-backpack-black-1869957.html',
		label: 3,
		state: '0',
		orderIndex: 3,
		brandName: 'Swiss Polo',
		sku: '610B0SHAADD6B4GS',
		discountPercentage: 0,
	},
	{
		id: 128,
		name: 'Snake Locker Black Rope Bracelet',
		price: 32,
		description: '',
		promoCode: '',
		promotionPrice: 16,
		imageUrl:
			'https://dynamic.zacdn.com/Xqa_EKJevHsJE-80e_qMVCfZyKg=/fit-in/762x1100/filters:quality(95):fill(ffffff)/http://static.sg.zalora.net/p/plain-supplies-5695-6006521-1.jpg',
		url: 'https://www.zalora.sg/plain-supplies-snake-locker-black-rope-bracelet-black-white-silver-1256006.html',
		label: 4,
		state: '0',
		orderIndex: 4,
		brandName: 'PLAIN SUPPLIES',
		sku: '54B36AC9015D3BGS',
		discountPercentage: 50,
	},
	{
		id: 504,
		name: 'Razer Firefly V2 Micro-textured Surface Mouse Mat with Razer Chroma™',
		price: 79.9,
		description: '',
		promoCode: '',
		promotionPrice: 60,
		imageUrl:
			'https://suntec-belive-clients.belive.sg/product/addbdaf3-1e16-48bd-be33-0295a8955c48Screenshot_2021-03-18_at_2.50.24_PM.png',
		url: 'https://www.razer.com/sg-en/gaming-mouse-mats/razer-firefly-v2/RZ02-03020100-R3U1',
		label: 3,
		state: '0',
		orderIndex: 1,
	},
	{
		id: 503,
		name: 'Razer Naga Pro Modular Wireless Mouse with Swappable Side Plates',
		price: 229.9,
		description: '',
		promoCode: '',
		promotionPrice: 178.9,
		imageUrl:
			'https://suntec-belive-clients.belive.sg/product/2b35a56d-4103-4b1e-b705-061ddcc3f985Screenshot_2021-03-18_at_2.30.43_PM.png',
		url: 'https://www.razer.com/sg-en/gaming-mice/razer-naga-pro/RZ01-03420100-R3A1',
		label: 2,
		state: '0',
		orderIndex: 2,
	},
	{
		id: 502,
		name: 'ASUS DUAL-GTX1050-O2G-V2 GRAPHIC CARD',
		price: 269,
		description: '',
		promoCode: '',
		promotionPrice: 200,
		imageUrl:
			'https://suntec-belive-clients.belive.sg/product/29cd9312-32e1-4386-9362-60f4cc830ecdScreenshot_2021-03-18_at_2.28.06_PM.png',
		url: 'https://www.amazon.sg/ASUS-90YV0AA1-M0NA00-DUAL-GTX1050-O2G-V2-GRAPHIC-CARD/dp/B0787YR7L1/ref=asc_df_B0787YR7L1/?tag=googleshoppin-22&linkCode=df0&hvadid=389133911992&hvpos=&hvnetw=g&hvrand=16482358905261741047&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvl',
		label: 1,
		state: '0',
		orderIndex: 3,
	},
	{
		id: 506,
		name: 'Razer Kraken X USB Digital Surround Sound Gaming Headset',
		price: 99.9,
		description: '',
		promoCode: '',
		promotionPrice: 72.9,
		imageUrl:
			'https://suntec-belive-clients.belive.sg/product/3b15c8c5-8449-4ed7-936b-54d91dfb8b5bScreenshot_2021-03-18_at_2.52.12_PM.png',
		url: 'https://www.razer.com/sg-en/gaming-headsets/razer-kraken-x-usb/RZ04-02960100-R3U1',
		label: 5,
		state: '0',
		orderIndex: 4,
	},
	{
		id: 505,
		name: 'Razer Huntsman Elite - Linear Optical Switch - US Gaming Keyboard with Razer™ Optical Switches',
		price: 349.9,
		description: '',
		promoCode: '',
		promotionPrice: 299.9,
		imageUrl:
			'https://suntec-belive-clients.belive.sg/product/ea2f9d1a-adea-49d2-9dd8-b8c1605ae39cScreenshot_2021-03-18_at_2.51.38_PM.png',
		url: 'https://www.razer.com/sg-en/gaming-keyboards/Razer-Huntsman-Elite/RZ03-01871000-R3M1',
		label: 4,
		state: '0',
		orderIndex: 5,
	},
]

export const DEMO_POLLS = {
	title: 'Quis nostrum exercitationem ullam corporis suscipit?',
	choices: [
		{
			key: 'option1',
			value: 'Option A',
		},
		{
			key: 'option2',
			value: 'Option B',
		},
		{
			key: 'option3',
			value: 'Option C',
		},
	],
	id: 1412,
	option1: 'Option A',
	option2: 'Option B',
	option3: 'Option C',
	status: 0,
	userAnswer: 0,
}

export const DEMO_POLL_RESULT = {
	id: 0,
	title: 'Quis nostrum exercitationem ullam corporis suscipit',
	numOfOption1: 124,
	numOfOption2: 0,
	numOfOption3: 24,
}

export const DEMO_PINNED_MESSAGE = {
	senderDisplayName: 'Admin',
	message: 'This is a pinned message',
}

export const DEMO_GIFTS = [
	{
		amount: 0,
		orderIndex: 3,
		giftId: 276,
		giftName: 'Heart',
		costGold: 0,
		costBean: 2,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/fe701890-767b-406d-9480-d853871754cc10_Heart.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/25df0528-8fc2-4b33-b45d-568969b88b8c10_late_Heart.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'Gift Animation',
		giftCategoryOrderIndex: 1,
	},
	{
		amount: 0,
		orderIndex: 1,
		giftId: 300,
		giftName: 'ANNIKA XUE SAGER',
		costGold: 0,
		costBean: 1,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/ec5b2a71-8e03-4bc9-8f4f-671ae22e2f33ANNIKA_XUE_SAGER.jpg',
		giftType: 0,
		giftFile: '',
		giftAnimationType: 0,
		giftCategory: 0,
		giftCategoryName: 'Gift Combo',
		giftCategoryOrderIndex: 0,
	},
	{
		amount: 0,
		orderIndex: 2,
		giftId: 301,
		giftName: 'BERNADETTE WU-ONG',
		costGold: 0,
		costBean: 1,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/433f4cba-8e88-4286-ab4c-b368232d9075BERNADETTE_WU-ONG.jpg',
		giftType: 0,
		giftFile: '',
		giftAnimationType: 0,
		giftCategory: 0,
		giftCategoryName: 'Gift Combo',
		giftCategoryOrderIndex: 0,
	},
	{
		amount: 0,
		orderIndex: 1,
		giftId: 1,
		giftName: 'HyperLive',
		costGold: 0,
		costBean: 1,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/a1d3d4db-0034-486a-a53d-c57191833bbdHyperLive_White.png',
		giftType: 0,
		giftFile: '',
		giftAnimationType: 0,
		giftCategory: 0,
		giftCategoryName: 'General',
		giftCategoryOrderIndex: 2,
	},
	{
		amount: 0,
		orderIndex: 2,
		giftId: 284,
		giftName: 'SG Live',
		costGold: 0,
		costBean: 1,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/f9f9378b-de14-4525-b34e-1eb54557bd88SGLive_Logo_PNG_1500by1500px_blackbg.png',
		giftType: 0,
		giftFile: '',
		giftAnimationType: 0,
		giftCategory: 0,
		giftCategoryName: 'General',
		giftCategoryOrderIndex: 2,
	},
	{
		amount: 0,
		orderIndex: 3,
		giftId: 303,
		giftName: 'LARANYA KUMA',
		costGold: 0,
		costBean: 1,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/64a22b54-09c1-4a18-9706-5c6c7baa8ca7LARANYA_KUMA.jpg',
		giftType: 0,
		giftFile: '',
		giftAnimationType: 0,
		giftCategory: 0,
		giftCategoryName: 'General',
		giftCategoryOrderIndex: 2,
	},
	{
		amount: 0,
		orderIndex: 4,
		giftId: 275,
		giftName: 'Clap',
		costGold: 0,
		costBean: 2,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/7cfda880-be5e-4761-a0d3-04f11436fbc609_Clap.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/0a5f7d10-b0f1-443b-b7d8-54679a04186609_late_Clap.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'General',
		giftCategoryOrderIndex: 2,
	},
	{
		amount: 0,
		orderIndex: 5,
		giftId: 277,
		giftName: 'Flower',
		costGold: 0,
		costBean: 2,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/b72b701a-0f93-400a-9db1-7526688604fe11_Flower.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/f9c93097-c3da-4e64-9f6b-02351c0475dd11_late_Flower.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'Gift Combo',
		giftCategoryOrderIndex: 0,
	},
	{
		amount: 0,
		orderIndex: 7,
		giftId: 279,
		giftName: 'Diamond',
		costGold: 0,
		costBean: 5,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/85e5b24b-df54-4124-8133-0c63b8070d9e13_Diamond.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/3120cac7-7505-4cc9-9fa9-15dde0ed59d413_late_Diamond.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'General',
		giftCategoryOrderIndex: 2,
	},
	{
		amount: 0,
		orderIndex: 8,
		giftId: 272,
		giftName: 'House Balloon',
		costGold: 0,
		costBean: 5,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/fa6b3a68-99f2-4251-9db5-3b613624894506_HouseBalloon.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/19336d56-bda9-425a-a04e-e0535bde7a2006_late_HouseBalloon.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'Gift Combo',
		giftCategoryOrderIndex: 0,
	},
	{
		amount: 0,
		orderIndex: 9,
		giftId: 274,
		giftName: 'Carouseal',
		costGold: 0,
		costBean: 20,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/6b373c7d-5523-4287-bf2b-01d83f1d797608_Carouseal.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/82be1368-0249-471b-bc50-b4230b6eb12c08_Carouseal.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'Gift Combo',
		giftCategoryOrderIndex: 0,
	},
	{
		amount: 0,
		orderIndex: 10,
		giftId: 282,
		giftName: 'Castle',
		costGold: 0,
		costBean: 20,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/f638065b-0323-4b72-b650-b3c87735aba816_Castle.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/dbee7df7-1b01-48ee-96b6-a1a7b078f2db16_Castle.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'General',
		giftCategoryOrderIndex: 2,
	},
	{
		amount: 0,
		orderIndex: 11,
		giftId: 273,
		giftName: 'Race Car',
		costGold: 0,
		costBean: 50,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/d10d639a-b3d4-4313-9b16-e99f73a0342e07_RaceCar.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/089f87b5-bcbe-46b5-8f78-1159709a394b07_late_RaceCar.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'General',
		giftCategoryOrderIndex: 2,
	},
	{
		amount: 0,
		orderIndex: 12,
		giftId: 271,
		giftName: 'Merlion',
		costGold: 0,
		costBean: 100,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/fff55c34-6ebf-4b79-8045-611caa0ee96305_Merlion.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/f2f34fa5-874d-4ffb-98cf-ad1e2f1d10f405_late_Merlion.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'Gift Animation',
		giftCategoryOrderIndex: 1,
	},
	{
		amount: 0,
		orderIndex: 165,
		giftId: 280,
		giftName: 'Astronaut',
		costGold: 0,
		costBean: 14,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/fdd2a57b-8e80-433c-8ad8-ecc592ed8e6914_Astronaut.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/16f8a174-1d45-4d16-ae5d-fc2cf91f3d6e14_Astronaut.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'General',
		giftCategoryOrderIndex: 2,
	},
	{
		amount: 0,
		orderIndex: 168,
		giftId: 286,
		giftName: 'test',
		costGold: 0,
		costBean: 0,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/d002d994-193c-459f-8035-d57d95885f7eSGLive_v1_banner.jpg',
		giftType: 0,
		giftFile: '',
		giftAnimationType: 0,
		giftCategory: 0,
		giftCategoryName: 'General',
		giftCategoryOrderIndex: 2,
	},
	{
		amount: 0,
		orderIndex: 170,
		giftId: 288,
		giftName: 'Lantern',
		costGold: 0,
		costBean: 500,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/18435d9d-a95a-485c-99d9-34742722cf01Lantern.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/7ad0cfe1-4b25-4e75-bf69-aadd08c3b58b01_MidAutumn_Mid.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'General',
		giftCategoryOrderIndex: 2,
	},
	{
		amount: 0,
		orderIndex: 171,
		giftId: 289,
		giftName: 'Mookcake',
		costGold: 0,
		costBean: 1000,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/57a04911-0deb-4581-9849-a527aa143ac7Mooncake.png',
		giftType: 0,
		giftFile:
			'https://hlive-dev-clients.belive.sg/gift_image/8f2c81b9-4a07-4d20-84ef-d638ad216f6302_MidAutumn_Large.svga',
		giftAnimationType: 2,
		giftCategory: 0,
		giftCategoryName: 'Gift Combo',
		giftCategoryOrderIndex: 0,
	},
	{
		amount: 0,
		orderIndex: 172,
		giftId: 290,
		giftName: 'Test New Gift 1',
		costGold: 0,
		costBean: 0,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/97188032-e212-4317-b9fd-f5520d53a79bhq720.jpg',
		giftType: 0,
		giftFile: '',
		giftAnimationType: 0,
		giftCategory: 0,
		giftCategoryName: 'Gift Animation',
		giftCategoryOrderIndex: 1,
	},
	{
		amount: 0,
		orderIndex: 175,
		giftId: 293,
		giftName: 'General gift datq 2',
		costGold: 0,
		costBean: 2,
		giftImage:
			'https://hlive-dev-clients.belive.sg/gift_image/878d6f55-fbdc-4a2b-9042-bdac798ff691T8HMZPJSX-UD95N8L0G-5015f11f33fe-512.jpeg',
		giftType: 0,
		giftFile: '',
		giftAnimationType: 0,
		giftCategory: 0,
		giftCategoryName: 'Gift Animation',
		giftCategoryOrderIndex: 1,
	},
]

export const PK_INFO = {
	friendId: 0,
	friendUserName: 'string',
	friendDisplayName: 'string',
	friendStreamSlug: 'string',
	status: 0,
	friendUserImage: 'string',
}

export const DEMO_PK_RTM = {
	channelId: 'pk-test',
	status: PK_STATUS.STARTED,
	pkConfig: {
		slug: 'co-host-test',
		opponentAvatarUrl: '',
		opponentDisplayName: 'DucBM',
		opponentUserName: 'ducbm',
		opponentUserId: 9992,
	},
	pkStatistic: {
		challengeeStarCount: 0,
		challengeeUserId: 1,
		challengeeUsername: 'bipham',

		challengerStarCount: 0,
		challengerUserId: 1,
		challengerUsername: 'ducbm',

		timeLeft: 0,
	},
	pkDuration: 600,
	goldPerGift: 5,
	sendTime: 19200,
}
