<template>
	<b-modal
		:id="modalConfigs.id"
		:modal-class="`be-modal-custom ${modalConfigs.class}`"
		:dialog-class="`be-modal ${modalConfigs.dialogClass}`"
		:header-class="`be-modal__header ${modalConfigs.headerClass}`"
		:content-class="`be-modal__content ${modalConfigs.contentClass}`"
		:body-class="`be-modal__body ${modalConfigs.bodyClass}`"
		centered
		:size="modalConfigs.size"
		:no-close-on-backdrop="noCloseOnBackdrop"
		no-close-on-esc
		hide-footer
		:hide-header="fullSizeContent"
		@shown="$emit('shown')"
		@hidden="$emit('hidden')"
		@hide="$emit('hide')"
	>
		<template #modal-header="{ close }">
			<slot name="header-custom">
				<img
					v-if="logo"
					:class="`be-modal__logo ${modalConfigs.dialogClass}__logo`"
					:src="loadImageSrc(logo)"
					alt="#"
					@error="loadImageError"
				/>
				<div
					v-if="title"
					:class="`be-modal__header--title ${modalConfigs.headerClass}--title`"
				>
					{{ title }}
				</div>
				<div
					v-if="subTitle"
					:class="`be-modal__header--sub-title ${modalConfigs.headerClass}--sub-title ${subTitleClass}`"
				>
					{{ subTitle }}
				</div>
			</slot>
			<slot name="close-custom-button">
				<b-button
					v-if="!noCloseButton"
					size="sm"
					variant="outline"
					class="btn-close-modal btn-close-modal--orange"
					@click="close()"
				>
					<img class="close-icon" :src="closeButton" /> </b-button
			></slot>
		</template>
		<template #default>
			<b-overlay spinner-variant="primary" :show="loading" opacity="0.3">
				<slot></slot>
			</b-overlay>
		</template>
	</b-modal>
</template>

<script>
import { DEFAULT_ICON_STATES } from '@/configs/Icons'

const SubTitleTypes = {
	NORMAL: 'normal',
	ERROR: 'error',
}

export default {
	name: 'BeModal',
	components: {},
	props: {
		modalOptions: {
			type: Object,
			default: () => {
				return {}
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		noCloseButton: {
			type: Boolean,
			default: false,
		},
		logo: {
			type: String,
			default: DEFAULT_ICON_STATES.logo.activeSrc,
		},
		title: {
			type: String,
			default: 'Sign Up (1/2)',
		},
		subTitle: {
			type: String,
			default: 'Please fill out the following fields to sign up',
		},
		subTitleType: {
			type: String,
			default: SubTitleTypes.NORMAL,
		},
		closeButton: {
			type: String,
			default: DEFAULT_ICON_STATES.closeBlack.activeSrc,
		},
		fullSizeContent: {
			type: Boolean,
			default: false,
		},
		noCloseOnBackdrop: {
			type: Boolean,
			default: true,
		}
	},
	data: () => ({
		CLOSE_ICON: DEFAULT_ICON_STATES.closeBlack.activeSrc,
	}),
	computed: {
		modalConfigs() {
			return Object.assign(
				{
					id: '',
					class: '',
					dialogClass: '',
					headerClass: '',
					contentClass: '',
					bodyClass: '',
					size: '',
				},
				this.modalOptions
			)
		},
		subTitleClass() {
			switch (this.subTitleType) {
				case SubTitleTypes.ERROR: {
					return 'error-sub-title'
				}
				default:
					return ''
			}
		},
	},
	beforeDestroy() {
		this.$emit('hide')
		this.$emit('hidden')
	},
}
</script>

<style lang="scss" scoped>
.close-icon {
	fill: $color-white;
}

.error-sub-title {
	color: $color-red-2;
}
</style>
