import { SDK_API } from "@/configs/APIs";
const USER_APIS = SDK_API.USER;

export default ($axios) => ({
    /**
     * Get detail of user
     * @param {*} param
     */
    async detail({userId, userName, isExcludedPurchaseTicket = true}) {
        const {
            data: { code, data, message }
        } = await $axios.post(USER_APIS.DETAIL, {
            userId: parseInt(userId),
            userName: userName,
			isExcludedPurchaseTicket
        })
        return { code, data, message };
    },

    /**
     * multipart/form-data
     * Update profile of user
     * @param {*} dataParams -> multipart/form-data
     */
    async update(dataParams) {
        const {
            data: { code, data, message }
        } = await $axios.post(USER_APIS.UPDATE, dataParams, {
            headers: { "Content-Type": "multipart/form-data" },
         })
        return { code, data, message };
    },

    /**
     * Change password
     * @param {password, newPassword} param
     */
    async changePassword({password, newPassword}) {
        const {
            data: { code, data, codeDetails, message }
        } = await $axios.post(USER_APIS.CHANGE_PASSWORD, { password, newPassword })
        return { code, data, codeDetails, message };
    },

    /**
     * Get total users
     */
    async count() {
        const {
            data: { code, data, message }
        } = await $axios.get(USER_APIS.COUNT)
        return { code, data, message };
    },
});
