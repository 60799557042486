import { STATUS_RESPONSES, STATUS_VIDEO } from '@/configs/SDK'
import { DEMO_STREAM_DETAIL, DEMO_PRODUCTS } from '@/assets/data/stream'
import { PK_STATUS } from '@/configs/RTM_SDK'
import { Decrypt, ENCRYPT_OBJECT_TYPE } from '~/utils/AESCrypto'

export const state = () => ({
	streamDetail: null,
	urlSources: [],
	streamStatistic: {
		viewCount: 1,
		likeCount: 0,
	},
	pkStatistic: {},
	hostInfo: null,
	coHostInfo: null,
	streamProducts: [],
	pinnedMessage: null,
	pkMode: false,
	pkInfo: {},
	pkConfig: {},
})

export const getters = {
	streamDetail(state) {
		return state.streamDetail
	},
	urlSources(state) {
		return state.urlSources
	},
	streamStatistic(state) {
		return state.streamStatistic
	},
	hostInfo(state) {
		return state.hostInfo
	},
	streamProducts(state) {
		return state.streamProducts
	},
	pinnedMessage(state) {
		return state.pinnedMessage
	},
	pkStatistic(state) {
		return state.pkStatistic
	},
	coHostInfo(state) {
		return state.coHostInfo
	},
	pkMode(state) {
		if (!state.coHostInfo || !state.hostInfo) {
			return false
		}
		if (state.pkInfo && (state.pkInfo.status !== PK_STATUS.DISCONNECTED) && state.streamDetail?.status === STATUS_VIDEO.LIVE) {
			return true
		}
		// return false
	},
	pkInfo(state) {
		return state.pkInfo
	},
	pkConfig(state) {
		return state.pkConfig
	},
	pkStarted(state) {
		if (!state.coHostInfo || !state.hostInfo) {
			return false
		}
		if (state.pkInfo && (state.pkInfo.status === PK_STATUS.STARTED)) {
			return true
		}
		return false;
	},
}

export const mutations = {
	UPDATE_STREAM_DETAIL(state, streamDetail) {
		if (streamDetail && state.streamDetail) {
			state.streamDetail = Object.assign(state.streamDetail, streamDetail)
		} else {
			state.streamDetail = streamDetail
		}
	},
	UPDATE_URL_SOURCES(state, urlSources) {
		state.urlSources = [...urlSources]
	},
	UPDATE_HOST_INFO(state, hostInfo) {
		if (hostInfo && state.hostInfo) {
			state.hostInfo = Object.assign(state.hostInfo, hostInfo)
		} else {
			state.hostInfo = hostInfo
		}
	},
	UPDATE_STREAM_STATISTIC(state, streamStatistic) {
		state.streamStatistic = Object.assign(
			state.streamStatistic,
			streamStatistic
		)
	},
	UPDATE_STREAM_PRODUCTS(state, streamProducts) {
		state.streamProducts = streamProducts.sort(function (a, b) {
			return a.orderIndex - b.orderIndex
		})
	},
	RESORT_STREAM_PRODUCTS({ state, commit }, sortProduct) {
		const productSorted = state.streamProducts.filter(function (product) {
			return parseInt(product.id) === parseInt(sortProduct.productId)
		})
		if (productSorted.length) {
			const oldIndex = productSorted[0].orderIndex
			if (oldIndex > sortProduct.newIndex) {
				// eslint-disable-next-line array-callback-return
				state.streamProducts.map(function (product) {
					if (
						product.orderIndex <= oldIndex &&
						product.orderIndex > sortProduct.newIndex
					) {
						return product.orderIndex++
					}
				})
			} else if (oldIndex < sortProduct.newIndex) {
				// eslint-disable-next-line array-callback-return
				state.streamProducts.map(function (product) {
					if (
						product.orderIndex >= oldIndex &&
						product.orderIndex < sortProduct.newIndex
					) {
						return product.orderIndex--
					}
				})
			}

			commit('UPDATE_STREAM_PRODUCTS', state.streamProducts)
		}
	},
	UPDATE_PINNED_MESSAGE(state, message) {
		state.pinnedMessage = message
	},
	UPDATE_CO_HOST_INFO(state, coHostInfo) {
		if (coHostInfo && state.coHostInfo) {
			state.coHostInfo = {
				...state.coHostInfo, ...coHostInfo
			}
		} else {
			state.coHostInfo = coHostInfo
		}
	},
	UPDATE_PK_STATISTIC(state, pkStatistic) {
		if (pkStatistic && state.pkStatistic) {
			state.pkStatistic = {
				...state.pkStatistic, ...pkStatistic
			}
		} else {
			state.pkStatistic = pkStatistic
		}
	},
	UPDATE_PK_INFO(state, pkInfo) {
		if (pkInfo && state.pkInfo) {
			state.pkInfo = {
				...state.pkInfo, ...pkInfo
			}
		} else {
			state.pkInfo = pkInfo
		}
	},
	UPDATE_PK_CONFIG(state, pkConfig) {
		if (pkConfig && state.pkConfig) {
			state.pkConfig = {
				...state.pkConfig, ...pkConfig
			}
		} else {
			state.pkConfig = pkConfig
		}
	},
}

export const actions = {
	updateStreamDetail: ({ commit }, streamDetail) => {
		commit('UPDATE_STREAM_DETAIL', streamDetail)
	},
	updateStreamStatistic: ({ commit }, streamStatistic) => {
		commit('UPDATE_STREAM_STATISTIC', streamStatistic)
	},
	updateHostInfo: ({ commit }, hostInfo) => {
		commit('UPDATE_HOST_INFO', hostInfo)
	},
	updateStreamProducts: ({ commit }, streamProducts) => {
		commit('UPDATE_STREAM_PRODUCTS', streamProducts)
	},
	resortStreamProducts: ({ commit }, sortProduct) => {
		commit('RESORT_STREAM_PRODUCTS', sortProduct)
	},
	async getStreamDetail({ commit, dispatch }, streamConfig) {
		// eslint-disable-next-line prefer-const
		let { code, data, message } =
			await this.$httpResources.repositories.stream.getDetail(
				streamConfig.slug
			)
		if (
			(code === STATUS_RESPONSES.USER_BANNED_BY_ADMIN ||
				code !== STATUS_RESPONSES.SUCCESS ||
				!data) &&
			process.env.APP_ENV !== 'development'
		) {
			return { code, data, message }
		}
		if (process.env.APP_ENV === 'development' && !data) {
			data = DEMO_STREAM_DETAIL
		}
		let primaryUrl =
			streamConfig.isPaid && data.status === STATUS_VIDEO.LIVE
				? Decrypt(data.streamUrl, ENCRYPT_OBJECT_TYPE.STREAM_DATA)
				: data.streamUrl
		if (data.status === STATUS_VIDEO.LIVE && data.isIVS && data.ivsToken) {
			primaryUrl += `?token=${data.ivsToken}`
		}
		let urlSources = []
		if (primaryUrl) {
			urlSources = [primaryUrl]
		}

		if (data.status === STATUS_VIDEO.RECORDED && data.isRecorded) {
			// check video is Recorded based on status + isRecorded data
			if (data?.mp4StreamUrl) {
				urlSources = [...urlSources, ...[data.mp4StreamUrl]]
			}
		}

		commit('UPDATE_STREAM_DETAIL', data)
		commit('UPDATE_HOST_INFO', {
			...data.publisher,
			...{
				score: 0,
				slug: data.slug,
				uid: data.publisher.userId
			}
		})
		commit('UPDATE_URL_SOURCES', urlSources)
		commit('UPDATE_STREAM_STATISTIC', {
			viewCount: data.viewCount,
			likeCount: data.likeCount,
		})

		// commit('UPDATE_PK_STATISTIC', {
		// 	viewCount: data.viewCount,
		// 	likeCount: data.likeCount,
		// })

		if (data.pk) {
			commit('UPDATE_CO_HOST_INFO', {
				userId: data.pk.friendId,
				uid: data.pk.friendId,
				userName: data.pk.friendUserName,
				displayName: data.pk.friendDisplayName,
				slug: data.pk.friendStreamSlug,
				status: data.pk.status,
				userImage: data.pk.friendUserImage,
				score: 0
			})
		}
		return { code, data, message }
	},
	clearStream({ commit }) {
		commit('UPDATE_STREAM_DETAIL', null)
		commit('UPDATE_HOST_INFO', null)
		commit('UPDATE_URL_SOURCES', [])
		commit('UPDATE_STREAM_STATISTIC', {
			viewCount: 1,
			likeCount: 0,
		})
	},
	async getStreamProducts({ commit }, { slug, nextId = 0, limit = 10 }) {
		const { code, data, message } =
			await this.$httpResources.repositories.stream.getProducts(
				slug,
				nextId,
				limit
			)
		if (code === STATUS_RESPONSES.SUCCESS) {
			commit(
				'UPDATE_STREAM_PRODUCTS',
				data.result
					? data.result
					: process.env.APP_ENV === 'development'
					? DEMO_PRODUCTS
					: []
			)
			return {
				code,
				data: data.result,
				isEnd: data.isEnd,
				nextId: data.nextId,
				message,
			}
		}
		else if (process.env.APP_ENV === 'development') {
			commit(
				'UPDATE_STREAM_PRODUCTS',
				DEMO_PRODUCTS
			)
			return {
				code,
				data: DEMO_PRODUCTS,
				isEnd: true,
				nextId: 1,
				message,
			}
		}
		else {
			commit(
				'UPDATE_STREAM_PRODUCTS',
				[]
			)
			return {
				code,
				data: [],
				isEnd: true,
				nextId: 1,
				message,
			}
		}
	},
	updatePinnedMessage: ({ commit }, message) => {
		commit('UPDATE_PINNED_MESSAGE', message)
	},
	updatePkStatistic: ({ commit }, pkStatistic) => {
		commit('UPDATE_PK_STATISTIC', pkStatistic)
	},
	updatePkInfo: ({ commit }, pkInfo) => {
		commit('UPDATE_PK_INFO', pkInfo)
	},
	updatePkConfig: ({ commit }, pkConfig) => {
		commit('UPDATE_PK_CONFIG', pkConfig)
	},
	updateCoHostInfo: ({ commit }, coHostInfo) => {
		commit('UPDATE_CO_HOST_INFO', coHostInfo)
	},
}
