import CryptoJS from 'crypto-js'
import { isEmpty } from 'lodash/lang'

export const ENCRYPT_OBJECT_TYPE = {
	PASSWORD: 0,
	STREAM_DATA: 1,
}

const EncryptConfig = (encryptObjectType) => {
	switch (encryptObjectType) {
		case ENCRYPT_OBJECT_TYPE.STREAM_DATA: {
			return {
				key: CryptoJS.enc.Utf8.parse(process.env.AES_STREAM_PASSKEY),
				iv: CryptoJS.enc.Utf8.parse(process.env.AES_STREAM_IV),
			}
		}
		case ENCRYPT_OBJECT_TYPE.PASSWORD:
		default:
			return {
				key: CryptoJS.enc.Utf8.parse(process.env.cryptoKey),
				iv: CryptoJS.enc.Utf8.parse(process.env.cryptoIv),
			}
	}
}

/**
 * AES encryption: string key iv returns base64
 */
export function Encrypt(
	word,
	encryptObjectType = ENCRYPT_OBJECT_TYPE.PASSWORD
) {
	if (isEmpty(word)) return
	const secret = EncryptConfig(encryptObjectType)

	const srcs = CryptoJS.enc.Utf8.parse(word)
	const encrypted = CryptoJS.AES.encrypt(srcs, secret.key, {
		iv: secret.iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	})
	return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}

/**
 * AES decryption: string key iv returns base64
 *
 */
export function Decrypt(
	word,
	encryptObjectType = ENCRYPT_OBJECT_TYPE.PASSWORD
) {
	if (isEmpty(word)) return
	const secret = EncryptConfig(encryptObjectType)

	const base64 = CryptoJS.enc.Base64.parse(word)
	const src = CryptoJS.enc.Base64.stringify(base64)

	const decrypt = CryptoJS.AES.decrypt(src, secret.key, {
		iv: secret.iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	})

	const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
	return decryptedStr.toString()
}

export function EncryptHmacSHA1(data, appKey) {
	return CryptoJS.HmacSHA1(data.trim(), appKey).toString(CryptoJS.enc.Hex)
}
