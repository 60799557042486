<template>
	<div id="about">
		<Header />
		<Nuxt />
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/headers/Header.vue'
import Footer from '@/components/footer'
import LayoutMixins from '@/mixins/LayoutMixins'

export default {
	components: {
		Header,
		Footer,
	},
	mixins: [LayoutMixins],
	middleware: ['checkIsMaintenance'],
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

#about {
	.navbar.bg-navbar {
		background-color: #292a2c;
	}
	footer {
		font-family: 'Open Sans', sans-serif;
		font-size: 1.5rem;
	}
}
</style>
