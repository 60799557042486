import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'LayoutMixins',
	data() {
		return {
			intervalCheckMaintenance: null,
		}
	},
	computed: {
		...mapGetters({
			isBottom: 'isBottom',
			isMaintenance: 'isMaintenance',
			projectSettings: 'projectSettings',
		}),
	},
	watch: {
		isMaintenance(val) {
			if (val) {
				this.$router.push('/maintenance')
			} else this.$router.push('/')
		},
	},
	mounted() {
		if (process.env.MAINTENANCE_URL) {
			this.initCheckMaintenance()
		}
		this.onResize()
		if (process.browser) {
			window.addEventListener('resize', this.onResize)
		}
		this.checkIfScrolledToBottom()
		this.initFacebookPixel(process.env.FACEBOOK_PIXEL_ID)
		this.trackFacebookPixelEvent()
	},
	beforeDestroy() {
		if (process.browser) {
			window.removeEventListener('resize', this.onResize)
		}
		clearInterval(this.intervalCheckMaintenance)
		this.intervalCheckMaintenance = null
	},
	methods: {
		...mapActions(['updateIsBottom', 'checkMaintenance']),
		initCheckMaintenance() {
			this.intervalCheckMaintenance = setInterval(
				this.checkMaintenance,
				this.projectSettings.maintenance.interval * 1000
			)
		},
		checkIfScrolledToBottom() {
			const _this = this
			if (process.browser) {
				window.onscroll = function () {
					if (
						Math.ceil(window.innerHeight + window.pageYOffset) >=
							document.body.offsetHeight &&
						!_this.isBottom
					)
						_this.updateIsBottom(true)
					else if (_this.isBottom) _this.updateIsBottom(false)
				}
			}
		},
	},
}
