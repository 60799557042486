import { MOBILE_OS } from "@/configs/SDK";

export const state = () => ({
   isMobileLayout: false,
   isBottom: false,
   categories: [],
    showHeader: true,
    OSUsing: MOBILE_OS.UN_KNOWN
});

export const getters = {
   streams(state) {
      return state.streams;
   },
   categories(state) {
      return state.categories;
   },
   isMobileLayout(state) {
      return state.isMobileLayout;
   },
   isBottom(state) {
      return state.isBottom;
   },
    showHeader(state) {
        return state.showHeader;
    },
    OSUsing(state) {
      return state.OSUsing;
  },
};

export const mutations = {
   UPDATE_CATEGORIES(state, categories) {
      state.categories = categories;
   },
   UPDATE_MOBILE_BROWSER_LAYOUT(state, isMobileLayout) {
      state.isMobileLayout = isMobileLayout;
   },
   UPDATE_IS_BOTTOM(state, isBottom) {
      state.isBottom = isBottom;
   },
    UPDATE_STATE_SHOW_HEADER(state, showHeader) {
        state.showHeader = showHeader;
    },
    UPDATE_OS_USING(state, OSUsing) {
      state.OSUsing = OSUsing;
  },
};

export const actions = {
   updateIsBottom({ commit }, isBottom) {
      commit("UPDATE_IS_BOTTOM", isBottom);
   },
   updateCategories({ commit }, categories) {
      commit("UPDATE_CATEGORIES", categories);
   },
   updateMobileBrowserLayout: ({ commit }, isMobileLayout) => {
      commit("UPDATE_MOBILE_BROWSER_LAYOUT", isMobileLayout);
   },
    updateStateShowHeader: ({ commit }, showHeader) => {
        commit("UPDATE_STATE_SHOW_HEADER", showHeader);
    },
    updateOSUsing: ({ commit }, OSUsing) => {
      commit("UPDATE_OS_USING", OSUsing);
  },
};
