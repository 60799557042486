export const AUTH_ROUTES = ['profile', 'profile-edit', 'settings']

export const REGEXS = {
	// eslint-disable-next-line
	ANY_URL:
		/(((https?:(\/\/)?(www\.)?([a-zA-Z0-9\-]+?\.)?)([a-zA-Z0-9\-]+?)\.([a-zA-Z]+))(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$%&'\(\)\*\+,;\=]*)?)/gi,
}

export const COOKIE_KEYS = {
	IS_IN_APP_BROWSER: 'is_in_app_browser',
	ACCESS_TOKEN: 'access_token',
	GUEST_TOKEN: 'guest_token',
	REFRESH_TOKEN: 'refresh_token',
	LANGUAGE: 'lang',
	COUNTRY_CODE: 'country_code',
	USER_NAME: 'user_name',
	SELECTED_LANG: 'selected_lang',
	CHAT_DISPLAY_NAME: 'chat_display_name',
	LIKED_CHANNEL: 'liked_channel',
	STRIPE_SESSION_ID: 'stripe-sessionId',
	HYPERSHOP_ACCESS_TOKEN: 'hypershop_access_token',
	VOUCHER_CODE: 'voucher_code',
	VOUCHER_EVENT_ID: 'voucher_event_id',
}

export const ENV_CONFIGS = {
	IS_DETECT_LOCATION: process.env.APP_ENV !== 'development',
	STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY,
}

export const ROUTE_AVAILABLES = JSON.parse(process.env.ROUTE_AVAILABLES)
export const DEFAULT_HOMEPAGE_URL = process.env.DEFAULT_HOMEPAGE_URL
export const ROUTE_DISABLES = JSON.parse(process.env.ROUTE_DISABLES)
export const VERITY_EMAIL_ROUTE = process.env.VERITY_EMAIL_ROUTE
export const REDIRECT_URL = {
	AUTH_VERIFY_EMAIL: {
		name: 'auth-verify-email',
		action: 'verify-email',
		from: '/auth/verify-email',
		to: `/${VERITY_EMAIL_ROUTE}?action=verify-email&`,
	},
}

export const PUBLIC_SDK_SCRIPT = {
	PAYPAL: 'https://www.paypal.com/sdk/js',
	GOOGLE: 'https://apis.google.com/js/api:client.js',
	GOOGLE_GSI: 'https://accounts.google.com/gsi/client',
	FACEBOOK: 'https://connect.facebook.net/en_US/sdk.js',
	APPLE: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
}
