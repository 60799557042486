<template>
	<div class="profile-header base">
		<header id="mobile" class="header d-block d-sm-none">
			<b-navbar
				toggleable="lg"
				type="dark"
				variant="info"
				class="fixed-top navbar-header"
			>
				<b-container>
					<b-navbar-brand
						href="javascript:window.location.replace('/')"
						class="w-50 mx-0"
					>
						<img
							src="@/static/media/generals/default-logo.svg"
							class="w-50"
						/>
					</b-navbar-brand>
					<div
						class="
							d-flex
							align-items-center
							w-50
							justify-content-end
						"
					>
						<b-nav-item-dropdown
							v-if="selectedLang"
							class="
								h-100
								btn
								nav-item-dropdown nav-lang-dropdown
							"
							variant="outline-light"
						>
							<template #button-content>
								<div class="nav-lang">
									<div
										class="
											nav-lang--country
											iti-flag
											flag-item
										"
										:class="[selectedLang.class]"
									></div>
									<div class="nav-lang--code">
										{{ selectedLang.shortCode }}
									</div>
								</div>
							</template>
							<b-dropdown-item
								v-for="lang in availableLocales"
								:key="`lang-${lang.code}`"
								href="javascript:void(0)"
								class="d-flex align-items-center dropdown__item"
								@click.prevent.stop="handleSelectedLang(lang)"
							>
								<div class="nav-lang-dropdown__item">
									<div
										:class="`nav-lang-dropdown--country iti-flag flag-item ${lang.class}  shadow`"
									></div>
									{{ lang.label }}
								</div>
							</b-dropdown-item>
						</b-nav-item-dropdown>

						<b-nav-item
							v-if="isLoggedIn && userDetail"
							href="javascript:void(0)"
							class="
								top-up-menu
								about-us-account-token
								d-flex
								align-items-center
							"
							link-classes="d-flex text-capitalize align-items-center text-underscore p-0 text-nowrap"
							@click="handleOpenShopModal"
						>
							<b-avatar
								variant="info"
								src="@/static/media/icons/token.svg"
								class="mx-4"
								size="2rem"
								href="javascript:void(0)"
								@click="handleOpenShopModal"
							></b-avatar>
							{{ giftStore.bean.toLocaleString('en-US') }}
							{{ $t('headers.HYPER') }}
						</b-nav-item>
						<b-navbar-toggle
							v-b-toggle.sidebar-left
							target="nav-collapse"
							class="ml-4 p-2"
						></b-navbar-toggle>

						<b-sidebar
							id="sidebar-left"
							bg-variant="dark"
							shadow
							backdrop
							width="20em"
							lazy
							header-class
							no-header
						>
							<template #default>
								<div class="px-3 py-2">
									<div v-if="isLoggedIn">
										<nav class="mb-3">
											<b-nav
												vertical
												class="
													profile-top-mobile-header
												"
											>
												<b-nav-item>
													<div>
														<b-row class="mt-5">
															<b-col
																cols="12"
																class="
																	d-flex
																	align-items-center
																	justify-content-center
																"
															>
																<img
																	:src="
																		loadImageSrc(
																			userDetail.userImage
																		)
																	"
																	class="
																		profile-header-panel__avatar
																	"
																	alt="avatar"
																	@error="
																		loadImageError
																	"
															/></b-col>
															<b-col
																cols="12"
																class="
																	d-flex
																	align-items-center
																	justify-content-md-center
																	text-white
																	text-center
																	text-transform-none
																"
															>
																<div
																	class="
																		w-100
																		mt-3
																	"
																>
																	{{
																		userDetail.displayName
																	}}
																	<div
																		class="
																			text-white
																			text-lg-left
																			text-md-center
																			mt-3
																			text-transform-none
																		"
																	>
																		{{
																			userDetail.email
																		}}
																	</div>
																	<div
																		v-if="
																			!isInProfilePage
																		"
																		class="
																			profile-side-panel__edit-label
																			mt-3
																		"
																	>
																		<nuxt-link
																			to="/profile"
																			class="
																				text-white
																			"
																		>
																			{{
																				$t(
																					'textActions.Edit'
																				)
																			}}
																		</nuxt-link>
																	</div>
																</div>
															</b-col>
														</b-row>
													</div>
												</b-nav-item>
											</b-nav>
										</nav>
									</div>
									<div v-if="!isLoggedIn">
										<nav class="mb-3">
											<b-nav
												vertical
												class="
													profile-top-mobile-header
												"
											>
												<b-nav-item>
													<b-avatar
														v-b-toggle.sidebar-left
														src="@/static/media/icons/close.svg"
														size="2rem"
														class="
															mr-3
															bg-transparent
														"
													></b-avatar>
												</b-nav-item>
											</b-nav>
											<b-nav
												vertical
												class="
													profile-top-mobile-header
												"
											>
												<b-nav-item>
													<b-avatar
														src="@/static/media/images/black_avatar.png"
														size="5rem"
														class="
															mr-3
															bg-transparent
														"
													></b-avatar>
													<p>
														<a
															href="javascript:void(0)"
															@click="
																showLoginModal = true
															"
															>{{
																$t(
																	'headers.login'
																)
															}}</a
														>
													</p>
												</b-nav-item>
											</b-nav>
										</nav>
									</div>
									<nav class="mb-3">
										<b-nav
											vertical
											class="bottom-mobile-header"
										>
											<b-button
												v-if="!isLoggedIn"
												href="javascript:void(0)"
												variant="primary"
												block
												class="bg-w-color"
												@click="handleOpenShopModal"
												>{{
													$t('headers.hyper')
												}}</b-button
											>
											<a
												v-else
												class="bg-w-color recharge-href"
												href="javascript:void(0)"
												@click="handleOpenShopModal"
											>
												{{
													giftStore.bean.toLocaleString(
														'en-US'
													)
												}}
												{{ $t('headers.HYPER') }}
											</a>
											<hr class="border" />
											<b-nav-item
												class="none-t-tranf"
												to="/redeem"
												>{{
													$t(
														'pages.profilePage.Redeem'
													)
												}}</b-nav-item
											>
											<b-nav-item
												class="none-t-tranf"
												to="/tickets"
												>{{
													$t(
														'pages.profilePage.Tickets'
													)
												}}</b-nav-item
											>
											<b-nav-item
												class="none-t-tranf"
												href="javascript:void(0)"
												@click="handleOpenShopModal"
												>{{
													$t(
														'pages.profilePage.Top Up'
													)
												}}</b-nav-item
											>
											<b-nav-item
												class="none-t-tranf"
												to="/transaction-history"
												>{{
													$t(
														'pages.profilePage.Transaction History'
													)
												}}</b-nav-item
											>
											<b-nav-item
												class="none-t-tranf"
												to="/order-status"
												>{{
													$t(
														'pages.profilePage.Order Status'
													)
												}}</b-nav-item
											>
											<b-nav-item
												class="none-t-tranf"
												to="/checkout"
											>{{
													$t(
														'pages.profilePage.Cart'
													)
												}}</b-nav-item
											>
											<b-nav-item
												class="none-t-tranf"
												to="#"
												@click="logoutUser"
												>{{
													$t('headers.logout')
												}}</b-nav-item
											>
										</b-nav>
									</nav>
								</div>
							</template>
						</b-sidebar>
					</div>
				</b-container>
			</b-navbar>
		</header>

		<LoginModal
			v-if="showLoginModal"
			:open="showLoginModal"
			@hidden="showLoginModal = false"
			@showForgotPasswordModal="
				showLoginModal = false
				showForgotPasswordModal = true
			"
			@openSideBar="openSidebar"
			@handleShowRegisterModal="handleShowRegisterModal"
		/>
		<SignUpModal
			v-if="showRegisterModal"
			:open="showRegisterModal"
			@hidden="showRegisterModal = false"
			@show-register-confirmation="showRegisterConfirm"
			@handleShowLoginModal="handleShowLoginModal"
		/>
		<RegisterConfirmationModal
			v-if="showRegisterConfirmationModal"
			:email-confirm="emailConfirm"
			:open="showRegisterConfirmationModal"
			@hidden="showRegisterConfirmationModal = false"
		/>
		<RegisterSuccessModal
			:open="showRegisterSuccessModal"
			@hidden="
				showRegisterSuccessModal = false
				showLoginModal = true
			"
		/>
		<ForgotPasswordModal
			:open="showForgotPasswordModal"
			@hidden="showForgotPasswordModal = false"
			@show-register="
				showForgotPasswordModal = false
				showRegisterModal = true
			"
		/>
		<LogOutModal
			:open="showLogoutModal"
			@hidden="showLogoutModal = false"
		/>
		<ShopModal
			:modal-options="hypershopModalOptions"
			:url="shopUrl"
			:open="isShowShopModal"
			@hidden="isShowShopModal = false"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import LogOutModal from '@/components/modals/LogoutModal'
import SdkMixins from '@/mixins/SdkMixins'
import i18nMixins from '@/mixins/i18nMixins'
import { SDK_API } from '@/configs/APIs'
import ShopModal from '@/components/modals/ShopModal'
import SignUpModal from "~/components/modals/SignUpModal";
import LoginModal from "~/components/modals/LoginModal";
import RegisterConfirmationModal from "~/components/modals/RegisterConfirmationModal";
import RegisterSuccessModal from "~/components/modals/RegisterSuccessModal";
import ForgotPasswordModal from "~/components/modals/ForgotPasswordModal";

const HYPERSHOP_APIS = SDK_API.HYPERSHOP

const hypershopModalOptions = {
	id: 'channel-hshop-modal',
	class: 'channel-hshop-modal-custom',
	dialogClass: 'channel-hshop-modal',
	headerClass: 'channel-hshop-modal__header',
	contentClass: 'channel-hshop-modal__content',
	bodyClass: 'channel-hshop-modal__body',
}

export default {
	name: 'Header',
	components: {
		LogOutModal,
		ShopModal,
		SignUpModal,
		LoginModal,
		RegisterConfirmationModal,
		RegisterSuccessModal,
		ForgotPasswordModal,
	},
	mixins: [SdkMixins, i18nMixins],
	props: {},
	data() {
		return {
			showLogoutModal: false,

			// Hypershop
			hypershopModalOptions,
			shopUrl: '',
			isShowShopModal: false,

			showLoginModal: false,
			showRegisterModal: false,
			showRegisterConfirmationModal: false,
			showRegisterSuccessModal: false,
			showForgotPasswordModal: false,
			emailConfirm: '',
		}
	},
	async fetch() {},
	computed: {
		...mapGetters({
			userDetail: 'userDetail',
			isLoggedIn: 'isLoggedIn',
			giftStore: 'giftStore/myAssets',
		}),
		isInProfilePage() {
			return this.$route.name === 'profile'
		},
	},
	watch: {},
	created() {},
	methods: {
		openSidebar() {
			this.$root.$emit('bv::toggle::collapse', 'sidebar-left')
		},
		logoutUser() {
			this.showLogoutModal = true
		},
		handleOpenShopModal() {
			this.shopUrl = `${process.env.HYPERSHOP_URL}${HYPERSHOP_APIS.TOPUP_PAGE}?`
			this.isShowShopModal = true
		},
		handleShowRegisterModal() {
			this.$root.$emit('user-register-when-redeem-voucher')
			this.showLoginModal = false
			this.showRegisterModal = true
		},
		handleShowLoginModal() {
			this.showLoginModal = true
			this.showRegisterModal = false
		},
		showRegisterConfirm(email) {
			this.emailConfirm = email
			this.showRegisterConfirmationModal = true
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/pages/header.scss';

.main-header {
	nav {
		z-index: 5;
	}
}

.profile-top-mobile-header {
	.nav-item {
		a {
			display: inline-flex;
			align-items: center;
			p {
				text-transform: none;
				text-decoration: underline;
				color: $color-coral-pink;
				a {
					color: $color-coral-pink;
				}
			}
		}
	}
	.nav-link {
		.text-center {
			font-size: 1.5rem;
		}
	}
	.profile-header-panel__avatar {
		width: 10rem;
		height: 10rem;
		border-radius: 50%;
		border: 1px solid $color-red-2;
		object-fit: cover;
	}
}

.bottom-mobile-header {
	.btn {
		width: 40%;
		margin-left: 2rem;
		margin-bottom: 1rem;
	}
	.nav-item {
		.nav-link {
			color: $color-coral-pink;
		}
		&.none-t-tranf {
			a {
				text-transform: none;
			}
		}
	}
	.border {
		display: block;
		height: 1px;
		border: 0 !important;
		border-top: 1px solid #707070 !important;
		margin: 2rem;
		padding: 0;
	}
	.recharge-href {
		color: $color-coral-pink;
		font-weight: bold;
		padding-left: 2rem;
	}
}

.navbar {
	min-height: $navbar-height;
}
.navbar-nav {
	.about-us {
		&-account-fullname.user-logged-menu {
			width: 100%;
		}
		&-account-token.nav-link {
			padding: 0 !important;
			width: 100%;
		}
		&-account-token.nav-item {
			padding: 0 !important;
			width: 100%;
		}
	}
}

.text-underscore {
	text-decoration: underline !important;
}

.text-transform-none {
	text-transform: none;
}

.header-display-name {
	max-width: 15rem;
	line-height: 2;
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.full-name {
	line-height: 1.5;
}

#mobile {
	.navbar {
		height: $navbar-height;
		.nav-link {
			color: #fff;
		}
		.dropdown-toggle {
			color: #fff;
			.nav-lang {
				display: inline-flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				.nav-lang--code {
					color: #fff;
				}
			}
		}
	}
	.nav-lang-dropdown::v-deep {
		.nav-link {
			color: #fff;
		}
	}
}
</style>
