export const SDK_API = {
	AUTH: {
		// User:
		GUEST_LOGIN: `/users/guest-login`,
		CHECK_LOGIN: `/users/check-login`,
		LOGIN: `/users/login`,
		LOGOUT: `/users/logout`,
		REGISTER: `/users/register`,
		VERIFY_EMAIL_CONFIRMATION: `/users/verify-email`,
		VERIFY_EMAIL_RESET: `/users/forgot-password`,
		VERIFY_CODE_RESET: `/users/verify-code`,
		RESET_PASSWORD: `/users/reset-password`,
		RESEND_RESET_LINK: `/users/resend-reset-link`,
		RESEND_VERIFY_EMAIL: `/users/send-verify-email`,
	},

	// Stream
	STREAM: {
		DETAIL: `/streams/detail`,
		LEAVE: `/streams/leave-stream`,
		LIKE: `/streams/like`,
		PINNED_MESSAGE: `/streams/pinned`,
		PRODUCTS: `/streams/products`,
		STATISTIC: `/streams/statistic`,
		CART: `/streams/get-checkout`,
		LEADER_BOARD_ECOMMERCE: '/streams/leaderboard-ecommerce',
	},

	/**
	 * Gifts
	 */
	GIFTS: {
		STORE: `/gifts/store`,
		SEND: `/gifts/send-gift`,
		LEADER_BOARD_GIFT: `/gifts/stream-gift-leaderboard`,
	},

	/**
	 * CHANNEL configs:
	 */
	CHANNEL: {
		NAUGHTY_WORDS: `/settings/naughty-words`,
	},

	/**
	 * User Profile
	 */
	USER: {
		UPDATE: `/users/edit-profile`,
		DETAIL: `/users/detail`,
		CHANGE_PASSWORD: `/users/change-password`,
		COUNT: `/users/count`,
	},

	/**
	 * Pages
	 */
	PAGES: {
		HOME: `/homepages/home`,
	},

	/**
	 * Payment
	 */
	PAYMENT: {
		TOP_UP: `/topups/all`,
		CREATE_CHECKOUT_SESSION: `/topups/create-checkout-session`,
	},

	/**
	 * Polls
	 */
	POLL: {
		CURRENT: `/polls/current-in-stream`,
		ANSWER: `/polls/track-answer`,
		RESULT: `/polls/result`,
		ALL: `/polls/streams`,
	},

	/**
	 * Event
	 */
	EVENT: {
		ALL_UPCOMING: `/events/unhidden-events`,
		ALL_REC: `/events/unhidden-recs`,
		DETAIL: `/events/event-detail`,
		BUY_TICKET: `/events/buy-ticket`,
	},

	/**
	 * Subscribe Email
	 */
	EMAIL: {
		SUBSCRIBERS: `/subscribers/`,
		TOTAL_SUBSCRIBERS: `/subscribers/count`,
	},

	/**
	 * Banners
	 */
	BANNERS: {
		CURRENT: `/banners`,
	},

	/**
	 * Event Channel
	 */
	EVENT_CHANNEL: {
		CURRENT: `/channel`,
		DETAIL: `/channel`,
		BUY_TICKET: `/events/buy-ticket-channel`,
	},

	/**
	 * Common
	 */
	COMMON: {
		PROJECTS_SETTINGS: process.env.SETTING_URL,
	},

	/**
	 * Voucher
	 */
	VOUCHER: {
		APPLY: `/vouchers/redeem`,
		DETAIL: `/vouchers/detail`,
	},

	/**
	 * HyperShop
	 */
	HYPERSHOP: {
		ORDER_PAGE: '/member/getOrderPage',
		CHECKOUT_PAGE: '/member/showCheckout',
		TICKET_DETAIL_PAGE: '/member/ticket-detail',
		TICKET_LIST: '/api/ticket-list',
		TOPUP_PAGE: '/member/showHypersTopupPage',
	},

	VOTING: {
		ALL: '/voting',
		DETAIL: '/voting/{id}',
		LEADERBOARD: '/voting/leaderboard',
		SUBMIT_VOTE: '/voting/submit-vote'
	}
}
