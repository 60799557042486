import { mapGetters } from 'vuex'

export default {
	name: 'AuthMixins',
	mixins: [],
	data() {
		return {}
	},
	computed: {
		...mapGetters({
			isLoggedIn: 'isLoggedIn',
			projectSettings: 'projectSettings',
		}),
		requiredLoginSignUpPopUp() {
			if (
				(this.projectSettings.chat.allowGuestChat && !this.projectSettings.chat.requireGuestInputInfo) ||
				this.isLoggedIn
			) {
				return false
			} else {
				return true
			}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		/**
		 *
		 * @param {*} nextFunction
		 * @param {*} args
		 */
		async authenLoginFunction(nextFunction, args = []) {
			if (!this.requiredLoginSignUpPopUp) {
				if (nextFunction && typeof nextFunction === 'function')
					await nextFunction(...args)
			} else this.$root.$emit('required-login')
		},
	},
}
