<template>
	<BeModal
		:modal-options="modalOptions"
		:no-close-button="noCloseButton"
		:loading="loading"
		:title="registerConfirmationModal.title"
		:sub-title="registerConfirmationModal.subTitle"
		@hidden="closeModal"
	>
		<template #default>
			<div class="body">
				<div class="content">
					<div class="content__icon">
						<img
							:src="cardInfo.content.icon"
							class="card-icon"
							alt="card-icon"
						/>
					</div>
					<div
						class="content__main"
						v-html="cardInfo.content.main"
					></div>
				</div>
				<div class="info">
					{{ emailConfirm }}
				</div>
				<div class="description">
					<div
						v-for="(sub, index) in cardInfo.descriptions"
						:key="index"
						class="description__sub"
						v-html="sub"
					></div>
					<div v-if="limitSMS > 0" class="resend-wrapper text-center">
						<span class="hint hint--info">{{
							$t('formModals.Didnt receive a link')
						}}</span>
						<span
							class="hint hint--info hint--clickable ml-2"
							:class="countDownResendOTP > 0 ? 'disabled' : ''"
							@click="resendLink"
							>{{ $t('formModals.Resend') }}</span
						>
						<span
							v-if="countDownResendOTP > 0"
							class="hint hint--info hint--countdown"
							>&nbsp;({{ countDownResendOTP }}s)</span
						>
					</div>
				</div>
			</div>
			<div class="footer">
				<slot name="footer">
					<b-button
						class="btn btn-primary w-100 btn-animated"
						@click="closeModal"
					>
						{{ options.actionText }}
					</b-button>
				</slot>
			</div>
		</template>
	</BeModal>
</template>

<script>
import { DEFAULT_ICON_STATES } from '@/configs/Icons'
import BeModal from '@/components/modals/BeModal'
import { STATUS_RESPONSES } from '@/configs/SDK'
import { mapGetters } from 'vuex'

export default {
	name: 'RegisterConfirmationModal',
	components: {
		BeModal,
	},
	mixins: [],
	props: {
		emailConfirm: {
			type: String,
			default: '',
		},
		open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			registerConfirmationModal: {
				title: '',
				subTitle: '',
			},
			modalOptions: {
				id: 'register-confirmation-modal',
				class: 'register-confirmation-modal-custom',
				dialogClass: 'register-confirmation-modal',
				headerClass: 'register-confirmation-modal__header',
				contentClass: 'register-confirmation-modal__content',
				bodyClass: 'register-confirmation-modal__body',
			},
			noCloseButton: false,
			limitSMS: 5,
			countDownResendOTP: 0,
		}
	},
	computed: {
		...mapGetters({
			projectSettings: 'projectSettings',
		}),
		cardInfo() {
			return {
				content: {
					icon: DEFAULT_ICON_STATES.checkEmail.activeSrc,
					main: `${this.$t(
						'formModals.You’re almost done'
					)}<br />${this.$t('formModals.Check your email')}`,
				},
				descriptions: [this.$t('formModals.guideEmailVerifyContent')],
			}
		},
		options() {
			return {
				actionText: this.$t('formModals.Got it'),
			}
		},
	},
	watch: {
		open(val) {
			if (val) {
				this.$bvModal.show(this.modalOptions.id)
				this.startResendTimer()
			}
		},
	},
	mounted() {
		this.limitSMS = this.projectSettings.resendVerify.limit
		if (this.open) {
			this.$bvModal.show(this.modalOptions.id)
			this.startResendTimer()
		}
	},
	methods: {
		startResendTimer() {
			this.limitSMS -= 1
			this.countDownResendOTP =
				this.projectSettings.resendVerify.delayTime
			this.countDownTimer()
		},
		closeModal() {
			this.limitSMS = this.projectSettings.resendVerify.limit
			this.$bvModal.hide(this.modalOptions.id)
			this.$emit('hidden')
		},
		async resendLink() {
			if (this.countDownResendOTP > 0) return

			const { code } =
				await this.$httpResources.repositories.auth.resendVerifyEmail(
					this.emailConfirm
				)
			if (code === STATUS_RESPONSES.SUCCESS) {
				this.startResendTimer()
			}
		},
		countDownTimer() {
			if (this.countDownResendOTP > 0) {
				setTimeout(() => {
					this.countDownResendOTP -= 1
					this.countDownTimer()
				}, 1000)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.body {
	padding: 0 4rem;
	.content {
		&__icon {
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 100%;
				height: 100%;
				max-width: 8.6rem;
				max-height: 8.2rem;
			}
		}
		&__main {
			font-size: 1.8rem;
			font-weight: bold;
			line-height: 1.29;
			text-align: center;
			color: #1f1f1f;
			margin: 2rem 0;
		}
	}
	.info {
		font-family: $font-secondary;
		font-size: 1.6rem;
		font-weight: bold;
		line-height: 0.67;
		text-align: center;
		margin-bottom: 3rem;
		color: $color-black;
	}
	.description {
		font-family: $font-secondary;
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1.31;
		color: $color-black;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: justify;
		-moz-text-align-last: center;
		text-align-last: center;
		flex-direction: column;
		&__resend {
			margin-bottom: 2rem;
		}
	}
}
.footer {
	margin-bottom: 4rem;
}
</style>
