<template>
	<BeModal
		:modal-options="modalOptions"
		:title="modalOptions.title"
		:sub-title="modalOptions.subTitle"
		:logo="modalOptions.logo"
		:no-close-button="true"
		@hidden="resetModal"
	>
		<template #default>
			<!-- <h1 class="my-4 text-center font-weight-bold">Are you sure</h1> -->
			<h5 class="mb-4 text-center font-weight-bold">
				{{ $t('formModals.Are you sure to Logout') }}
			</h5>
			<footer class="pb-5 text-center">
				<b-button class="mt-3 mr-3 bg-d-color" @click="closeModal()">{{
					$t('textActions.Cancel')
				}}</b-button>
				<b-button class="mt-3 bg-w-color" @click="userLogout()">{{
					$t('textActions.OK')
				}}</b-button>
			</footer>
		</template>
	</BeModal>
</template>

<script>
import { DEFAULT_ICON_STATES } from '@/configs/Icons'
import BeModal from '@/components/modals/BeModal'
import SdkMixins from '@/mixins/SdkMixins'
export default {
	name: 'LogoutModal',
	components: {
		BeModal,
	},
	mixins: [SdkMixins],
	props: {
		open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			noCloseButton: false,
		}
	},
	computed: {
		modalOptions() {
			return {
				id: 'logout-modal',
				class: 'logout-modal-custom',
				dialogClass: 'logout-modal',
				headerClass: 'logout-modal__header',
				contentClass: 'logout-modal__content',
				bodyClass: 'logout-modal__body',
				title: this.$t('formModals.Are you sure'),
				subTitle: '',
				logo: DEFAULT_ICON_STATES.logout.activeSrc,
			}
		},
	},
	watch: {
		open(val) {
			if (val) this.$bvModal.show(this.modalOptions.id)
		},
	},
	mounted() {
		if (this.open) this.$bvModal.show(this.modalOptions.id)
	},
	methods: {
		closeModal() {
			this.$bvModal.hide(this.modalOptions.id)
			this.$emit('hidden')
		},
		async userLogout() {
			await this.logout()
			this.$bvModal.hide(this.modalOptions.id)
			this.$emit('hidden')
		},
		resetModal() {
			this.$emit('hidden')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/card-info';
.modal-content {
	width: 0;
}
.btn {
	width: 13rem;
	font-size: 1.7rem !important;
	font-family: $font-secondary;
	margin-right: 5px;
	border: 0;
	border-radius: 4px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: center;
	outline-color: transparent;
	outline-width: 0;
	outline-offset: 0;
	height: 4.5rem;
	&.bg-w-color {
		background-color: #fb6468;
		color: #fff;
	}
	&.bg-d-color {
		background-color: #1f1f1f;
		color: #fff;
	}
}
</style>
