export const RTM_MESSAGE_TYPES = {
	MESSAGE: 1,
	LIKE: 3,
	GIFT: 7,
	USER_LEFT: 101,
	USER_JOIN: 100,
	STATISTICS: 2,
	HOST_KICKED: 202,
	HOST_UNKICKED: 212,
	HOST_MUTED: 204,
	HOST_UNMUTED: 205,
	START_SHARE_SCREEN: 1412,
	STOP_SHARE_SCREEN: 1413,
	HOST_UN_LOCKED_ROOM: 1415,
	HOST_STARTED_STREAM: 1414,
	HOST_STREAM_ENDED: 201,

	HOST_PINNED_MESSAGE: 209,
	HOST_UNPIN_MESSAGE: 210,
	HOST_SORT_PRODUCT: 211,

	/**
	 * Admin actions:
	 */
	ADMIN_KICK: 402,
	ADMIN_BLOCKED: 403,
	ADMIN_MUTED: 404,
	ADMIN_UN_MUTED: 405,
	ADMIN_PAUSED: 406,
	ADMIN_UN_KICK: 407,
	ADMIN_START_POLL: 413,
	ADMIN_STOP_POLL: 414,

	/**
	 * Custom message client to client
	 */
	CUSTOM_GROUPMODE_MUTEALL: 1400,
	CUSTOM_GROUPMODE_ONLYHOST: 1401,
	CUSTOM_GROUPMODE_ABLECHAT: 1402,
	CUSTOM_MODE_HOST_KICK_USER: 1403,
	CUSTOM_MODE_HOST_UN_KICK_USER: 1404,
	CUSTOM_MODE_HOST_MUTE_USER: 1405,
	CUSTOM_MODE_HOST_UNMUTE_USER: 1406,

	/**
	 * PK
	 */
	PK: 500,
	PK_VIEWER_BOUGHT_PRODUCT: 502,

	/**
	 * Payment status
	 */
	PAYMENT_SUCCESS: 503,
}

export const PK_STATUS = {
	NONE: 0,
	REQUESTING: 1,
	CONNECTED: 2,
	DISCONNECTING: 3,
	DISCONNECTED: 4,
	ACCEPT: 5,
	REJECT: 6,
	AUTOREJECT: 7,
	STREAMCREATED: 8,
	INVITATIONTIMEOUT: 10,
	INVITATIONCANCEL: 11,
	STARTED: 25,
	ENDED: 26,
	SYNC: 28,
	INITIALISING: 29,
	PUNISHING: 30,
	REMATCHREQUEST: 31,
	REMATCHACCEPT: 32,
	REMATCHREJECT: 33,
	NOTIFYPREPARING: 50,
}

export const RTM_MESSAGES = {
	MESSAGE: 1,
	USER_LEFT: 101,
	USER_JOIN: 100,
	START_SHARE_SCREEN: 'START_SHARE_SCREEN',
	STOP_SHARE_SCREEN: 'STOP_SHARE_SCREEN',
	HOST_STREAM_ENDED: 'STREAM ENDED',
	HOST_STARTED_STREAM: 'HOST STARTED STREAM',
	HOST_UN_LOCKED_ROOM: 'HOST UN-LOCKED ROOM',
	HOST_LOCKED_ROOM: 'HOST LOCKED ROOM',
	LIKE_STREAM: 'LIKE STREAM',
}

export const RTM_CONFIGS = {
	MAXIMUM_RECEIVED_LIKES: 20,
	DELAY_ANIMATION_LIKE: 200,
}

export const AGORA_CONNECTION_STATE = {
	STATUS: {
		CONNECTED: 'CONNECTED',
		ABORTED: 'ABORTED',
		DISCONNECTED: 'DISCONNECTED',
		CONNECTING: 'CONNECTING',
		RECONNECTING: 'RECONNECTING',
	},
	REASON: {
		REMOTE_LOGIN: 'REMOTE_LOGIN',
	},
}

export const AGORA_ERROR_CODES = {
	CHANNEL_JOIN_SUCCESS: 'RTM Channel Join success',
	CHANNEL_JOIN_FAIL: 'RTM Channel Join fail',
	DISCONNECTED: 'RTM disconnected',
	CONNECTED: 'RTM connected',
	CONNECTING: 'RTM connecting',
	RECONNECTING: 'RTM re-connecting',
	REMOTE_LOGIN: 'RTM multiple device loginRTM Login success',
}

export const ADMIN_RTM_MESSAGE_TYPES = {
	QUESTION: 0,
	RESULT: 1,
	FINISH: 2,
}

export const GIFT_ANIMATION_TYPES = {
	JSON: 1,
	SVGA: 2,
	COMBO: 0,
}
