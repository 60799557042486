<template>
	<BeModal
		:modal-options="modalOptions"
		:no-close-button="noCloseButton"
		:loading="loading"
		:title="signupModal.title"
		:sub-title="signupModal.subTitle"
		:logo="modalLogo"
		:close-button="CLOSE_BUTTON"
		:full-size-content="!emailNeedVerify"
		@hidden="closeModal"
	>
		<template v-if="!emailNeedVerify" #default>
			<RegisterForm
				ref="registerFormComponent"
				@hidden="closeModal"
				@show-register-confirmation="handleFinishSignUp"
				@showResendVerifyEmail="showResendVerifyEmail"
				@showLoginModal="showLoginModal"
				@closeModal="closeModal"
			/>
		</template>
		<div v-else class="text-center guide-part reset-link-part">
			<div
				class="guide-part__text"
				v-html="
					$t(
						'formModals.Please check your email _xxx_ for verification link',
						{ email: emailNeedVerify }
					)
				"
			></div>
			<div v-if="limitSMS > 0" class="resend-wrapper text-center">
				<span class="hint hint--info">{{
					$t('formModals.Didnt receive a link')
				}}</span>
				<span
					class="hint hint--info hint--clickable ml-2"
					:class="countDownResendOTP > 0 ? 'disabled' : ''"
					@click="resendLink"
					>{{ $t('formModals.Resend') }}</span
				>
				<span
					v-if="countDownResendOTP > 0"
					class="hint hint--info hint--countdown"
					>&nbsp;({{ countDownResendOTP }}s)</span
				>
			</div>
		</div>
	</BeModal>
</template>

<script>
import CLOSE_BUTTON from '@/static/media/icons/close.svg'
import BeModal from '@/components/modals/BeModal'
import RegisterForm from '@/components/forms/RegisterForm'
import { DEFAULT_ICON_STATES } from '@/configs/Icons'
import { mapGetters } from 'vuex'
import { STATUS_RESPONSES } from '~/configs/SDK'

const VERIFY_MODAL_OPTIONS = {
	id: 'login-modal',
	class: 'reset-password-modal-custom',
	dialogClass: 'reset-password-modal',
	headerClass: 'reset-password-modal__header',
	contentClass: 'reset-password-modal__content',
	bodyClass: 'reset-password-modal__body',
}

export default {
	name: 'SignUpModal',
	components: {
		BeModal,
		RegisterForm,
	},
	mixins: [],
	props: {
		open: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		loading: false,
		signupModal: {
			title: '',
			subTitle: '',
		},
		modalOptions: {
			id: 'signup-modal',
			class: 'signup-modal-custom',
			dialogClass: 'signup-modal',
			headerClass: 'signup-modal__header',
			contentClass: 'signup-modal__content',
			bodyClass: 'signup-modal__body',
		},
		DEFAULT_LOGO: DEFAULT_ICON_STATES.logo,
		noCloseButton: false,
		limitSMS: 5,
		countDownResendOTP: 0,
		emailNeedVerify: '',
		CLOSE_BUTTON,
	}),
	computed: {
		...mapGetters({
			projectSettings: 'projectSettings',
		}),
		modalLogo() {
			return this.emailNeedVerify
				? DEFAULT_ICON_STATES.logo.activeSrc
				: null
		},
	},
	watch: {
		open(val) {
			if (val) this.$bvModal.show(this.modalOptions.id)
		},
	},
	mounted() {
		this.limitSMS = this.projectSettings.resendVerify.limit
		if (this.open) this.$bvModal.show(this.modalOptions.id)
		this.$emit('mounted')
	},
	methods: {
		handleFinishSignUp(email) {
			this.$emit('show-register-confirmation', email)
			this.closeModal()
		},
		closeModal() {
			this.$emit('hide')
			this.$bvModal.hide(this.modalOptions.id)
			this.$emit('hidden')
			this.signupModal = {
				title: '',
				subTitle: '',
			}
			this.emailNeedVerify = ''
		},
		showResendVerifyEmail(email) {
			this.signupModal = {
				...{
					title: this.$t('formModals.Your account is not verified'),
					subTitle: '',
					logo: this.CHECK_EMAIL_ICON,
				},
			}
			this.modalOptions = { ...VERIFY_MODAL_OPTIONS }
			this.modalLogo = DEFAULT_ICON_STATES.checkEmail.activeSrc
			this.emailNeedVerify = email
			this.showLoginForm = false
		},
		async resendLink() {
			if (this.countDownResendOTP > 0) return

			const { code } =
				await this.$httpResources.repositories.auth.resendVerifyEmail(
					this.emailNeedVerify
				)
			if (code === STATUS_RESPONSES.SUCCESS) {
				this.countDownResendOTP =
					this.projectSettings.resendVerify.delayTime
				this.limitSMS -= 1
				this.countDownTimer()
			}
		},
		countDownTimer() {
			if (this.countDownResendOTP > 0) {
				setTimeout(() => {
					this.countDownResendOTP -= 1
					this.countDownTimer()
				}, 1000)
			}
		},
		showLoginModal() {
			this.$emit('handleShowLoginModal')
		},
	},
}
</script>

<style lang="scss" scoped>
.checkbox {
	&__label {
		font-family: $font-secondary;
		font-size: 1.1rem;
		color: $color-grey-light-3;
		padding: 0.1rem;
		margin-left: 0.5rem;

		&--term {
			&,
			&:hover,
			&:visited {
				color: $color-secondary;
			}
		}
	}
}
</style>
