import Vue from "vue";
import ImageMixins from "@/mixins/ImageMixins";
import HelperMixins from "@/mixins/HelperMixins";
import FacebookPixelMixins from "@/mixins/FacebookPixelMixins";

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__image_mixins__) {
    Vue.__image_mixins__ = true
    Vue.mixin(ImageMixins) // Set up your mixin then
}

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__helper_mixins__) {
    Vue.__helper_mixins__ = true
    Vue.mixin(HelperMixins) // Set up your mixin then
}

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__facebook_pixel_mixins__) {
    Vue.__facebook_pixel_mixins__ = true
    Vue.mixin(FacebookPixelMixins) // Set up your mixin then
}