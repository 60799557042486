export default class SignalRService {
   constructor({ server, channel, on, token }) {
      this._server = server
      this._channel = channel
      this._on = on
      this._token = token
      this._connection = null
      this._retryConnect = 0
      this._scriptLoaded = false
   }

   get connection() {
      return this._connection
   }

   get token() {
      return this._token
   }

   set token(val) {
      this._token = val
   }

   get server() {
      return this._server
   }

   set server(val) {
      this._server = val
   }

   initScript(callBack) {
      const d = document
      const s = "script"
      const id = "signr"
      let js,
         fjs = d.getElementsByTagName(s)[0]

      const existEl = d.getElementById(id)
      if (existEl) {
         if (this._scriptLoaded) {
            if (callBack) callBack()
         } else
            existEl.addEventListener("load", () => {
               this._scriptLoaded = true
               if (callBack) callBack()
            })
         return
      }
      js = d.createElement(s)
      js.id = id
      js.src = `${process.env.SIGNALR_CDN}`
      js.addEventListener("load", () => {
         this._scriptLoaded = true
         if (callBack) callBack()
      })
      d.body.appendChild(js, fjs)
   }

   async init() {
      // eslint-disable-next-line no-undef
      const _signalR = signalR
      let _this = this
      const isProd = process.env.APP_ENV === "production"

      //init signalR Hub
      this._connection = new _signalR.HubConnectionBuilder()
         .configureLogging(isProd ? _signalR.LogLevel.None : _signalR.LogLevel.Debug)
         .withUrl(this._server)
         .build()

      //Receive message
      if (this._on) {
         for (let msg in this._on) {
            this._connection.on(msg, this._on[msg])
         }
      }
      //start connection
      await this.start()

      //retry connection when close
      this._connection.onclose(async () => {
         await _this.start()
      })
   }

   connect() {
      this.initScript(() => this.init())
   }

   async start() {
      if (!this._connection || (this._connection && this._connection.state === "Connected")) return
      try {
         await this._connection.start()
         this._retryConnect = 0
         if (this._channel && this._token)
            this.invoke(this._channel, this._token).catch(function (err) {
               return console.error(err.toString())
            })
      } catch (err) {
         console.error(err.toString())
         if (this._retryConnect < 30) {
            this._retryConnect++
            setTimeout(() => this.start(), 3000)
         }
      }
   }

   destroy() {
      this._connection?.stop()
      this._connection = null
   }

   invoke(...args) {
      return this._connection.invoke(...args)
   }
}
