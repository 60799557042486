import AuthRepostiory from './AuthRepostiory'
import StreamRepository from './StreamRepository'
import ChannelRepository from './ChannelRepository'
import PageRepository from './PageRepository'
import GiftRepository from './GiftRepository'
import PaymentRepository from './PaymentRepository'
import UserRepository from './UserRepository'
import PollRepository from './PollRepository'
import EventRepository from './EventRepository'
import EmailRepository from './EmailRepository'
import EventChannelRepository from './EventChannelRepository'
import CommonRepository from './CommonRepository'
import VotingRepository from './VotingRepository'

export default ($axios) => ({
	auth: AuthRepostiory($axios),
	stream: StreamRepository($axios),
	channel: ChannelRepository($axios),
	pages: PageRepository($axios),
	gift: GiftRepository($axios),
	payment: PaymentRepository($axios),
	user: UserRepository($axios),
	poll: PollRepository($axios),
	event: EventRepository($axios),
	email: EmailRepository($axios),
	eventChannel: EventChannelRepository($axios),
	common: CommonRepository($axios),
	voting: VotingRepository($axios),
})
