import ChatMixins from '@/mixins/ChatMixins'
import AuthMixins from '@/mixins/AuthMixins'
import { mapGetters } from 'vuex'

const { RtmSdk, RtmAgora, ChatHelperMixins } = process.client
	? require('bi-live-libs')
	: {}

export default {
	name: 'SignalRtm',
	mixins: [ChatMixins, ChatHelperMixins, AuthMixins],
	components: {
		RtmAgora,
		RtmSdk,
	},
	props: {
		user: {
			type: Object,
			required: true,
		},
		server: {
			type: String,
			required: true,
		},
		channel: {
			type: String,
			required: false,
		},
		appId: {
			type: String,
			default: process.env.AGORA_APP_ID,
		},
		license: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			connected: true,
			rsaWorker: null,
		}
	},
	watch: {
	},
	computed: {
		...mapGetters({
			isLoggedIn: 'isLoggedIn',
			isMobileLayout: 'general/isMobileLayout',
			projectSettings: 'projectSettings',
		}),
		getAgoraOption() {
			return {
				token: null,
				useShouldReceiveMessage: false,
				useCamelCase: false,
			}
		},
	},
	async mounted() {
		
	},
	methods: {
        messageFromPeer({ text }) {
            console.log("messageFromPeer", text)
			return this.formatNewMessagePeer(text)
		},
	},
	async beforeDestroy() {},
}
