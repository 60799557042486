import Supermarket from "@/static/media/icons/supermarket.svg";
import LiveIcon from "@/static/media/icons/live.svg";
import PlayIcon from "@/static/media/icons/play-icon.png";
import LogoSecondary from "@/static/media/generals/logo-secondary.svg";
import Logo from "@/static/media/generals/logo.png";
import LoveIcon from "@/static/media/icons/love.svg";
import EyeIcon from "@/static/media/icons/eye.svg";
import PinIcon from "@/static/media/icons/pin.svg";
import PencilIcon from "@/static/media/icons/pencil.svg";
import HeartIcon from "@/static/media/icons/heart-line.svg";
import HeartRedIcon from "@/static/media/icons/heart_red.svg";
import ShareIcon from "@/static/media/icons/share.svg";
import FlyingThumb from "@/static/media/icons/flying-thumb.svg";
import CloseIcon from "@/static/media/icons/close.svg";
import CloseRedIcon from "@/static/media/icons/close-red.svg";
import FireIcon from "@/static/media/icons/fire.svg";
import ShopIcon from "@/static/media/icons/shop.svg";
import RedeemIcon from "@/static/media/icons/redeem.png";
import CartIcon from "@/static/media/icons/cart.png";
import GiftIcon from "@/static/media/icons/gift.svg";
import TicketIcon from "@/static/media/icons/ticket.svg";
import TicketAltIcon from "@/static/media/icons/ticket-alt.svg";
import FavouriteIcon from "@/static/media/icons/favourite.svg";
import EmailIcon from "@/static/media/icons/email.svg";
import CheckEmailIcon from "@/static/media/icons/check-email.svg";
import YeayIcon from "@/static/media/icons/yeay.svg";
import TransactionFailedIcon from "@/static/media/icons/payments/transaction-failed.svg";
import ThankYouIcon from "@/static/media/icons/payments/thank-you.svg";
import SuccessIcon from "@/static/media/icons/payments/success.svg";
import OppsIcon from "@/static/media/icons/payments/opps.svg";
import DiamondIcon from "@/static/media/icons/diamond.svg";
import TopUpIcon from "@/static/media/icons/top-up.svg";
import SettingIcon from "@/static/media/icons/settings.svg";
import SignOutIcon from "@/static/media/icons/sign-out.svg";
import ProfileIcon from "@/static/media/icons/profile.svg";
import DiamondBlueIcon from "@/static/media/icons/diamond-blue.png";
import CloseBlackIcon from "@/static/media/icons/close-black.svg";
import CloseCircleBlack from "@/static/media/icons/close_circle_black.svg";
import CloseCircleGrey from "@/static/media/icons/close-circle-grey.svg";
import ClockIcon from "@/static/media/icons/clock.svg";
import ClockWhiteIcon from "@/static/media/icons/clock-white.svg";
import ArrowDownIcon from "@/static/media/icons/arrow-down.svg";
import SearchIcon from "@/static/media/icons/search.svg";
import TokenIcon from "@/static/media/icons/token.svg";
import EyeOff from "@/static/media/icons/off.svg";
import EyeOn from "@/static/media/icons/on.svg";
import Fullscreen from "@/static/media/icons/full-screen.svg";
import ExitFullscreen from "@/static/media/icons/exit-fullscreen.svg";
import NavArrowLeft from "@/static/media/icons/nav-arrow-left.svg";
import LogoutIcon from "@/static/media/icons/icon-logout.svg";
import CalendarIcon from "@/static/media/icons/calendar.svg";
import CalendarWhiteIcon from "@/static/media/icons/calendar-white.svg";
import TicketWhiteIcon from "@/static/media/icons/ticket-white.svg";
import MuteIcon from "@/static/media/icons/mute.svg";
import UnMuteIcon from "@/static/media/icons/unmute.svg";
import PLAY_ICON from "@/static/media/pre-register/play-icon.svg";
import SendWithArrowIcon from "@/static/media/icons/send-button-arow.svg";
import ArrowCircleDown from "@/static/media/icons/arrow_circle_down.svg";
import ArrowCircleUp from "@/static/media/icons/arrow_circle_up.svg";
import HeartIcon2 from "@/static/media/icons/heart2.svg";
import ShareIcon2 from "@/static/media/icons/share2.svg";
import ShopIcon2 from "@/static/media/icons/shop2.png";
import GiftIcon2 from "@/static/media/icons/gift2.svg";
import PollIcon from "@/static/media/icons/poll.svg";
import BigGift from "@/static/media/icons/big-gift.png";
import SuccessGreen from "@/static/media/icons/success-green.svg";
import SuccessRed from "@/static/media/icons/success-red.svg";
import TicketRedIcon from "@/static/media/icons/ticket_red.svg";
import CloseCircleGreyWhite from "@/static/media/icons/close-circle-grey-white.svg";
import ArrowRightIcon from "@/static/media/icons/arrow-right.svg";
import AppleIcon from "@/static/media/icons/apple.svg";
import GooglePlayIcon from "@/static/media/icons/google-play.svg";
import DownloadIcon from "@/static/media/icons/download.svg";
import BuyTicketIcon from "@/static/media/icons/buy_ticket.svg";
import WatchNowIcon from "@/static/media/icons/watch_now.svg";
import CheckCircleGreenIcon from "@/static/media/icons/check_circle_green.svg";
import ArrowLeftIcon from "@/static/media/icons/arrow-left.svg";
import BackToTopIcon from "@/static/media/icons/back-to-top.svg";


import Pk1 from "@/static/media/icons/pk/1.png";
import Pk0 from "@/static/media/icons/pk/0.png";
import Pk2 from "@/static/media/icons/pk/2.png";
import Pk3 from "@/static/media/icons/pk/3.png";
import Pk4 from "@/static/media/icons/pk/4.png";
import Pk5 from "@/static/media/icons/pk/5.png";
import Pk6 from "@/static/media/icons/pk/6.png";
import Pk7 from "@/static/media/icons/pk/7.png";
import Pk8 from "@/static/media/icons/pk/8.png";
import Pk9 from "@/static/media/icons/pk/9.png";
import PkPer from "@/static/media/icons/pk/per.png";

import PkBase from "@/static/media/icons/pk/base.png";
import PkBlue from "@/static/media/icons/pk/blue.png";
import PkPink from "@/static/media/icons/pk/pink.png";
import PkBlueBase from "@/static/media/icons/pk/base_blue.png";
import PkPinkBase from "@/static/media/icons/pk/base_pink.png";
import PkIcon from "@/static/media/icons/pk/lightning.png";
import DefaultAvatar from "@/static/media/generals/default-avatar.svg";
import PK_DRAW from "@/static/media/icons/pk/Draw.png"
import PK_LOSER from "@/static/media/icons/pk/glow.svg"
import PK_WINNER from "@/static/media/icons/pk/WIN.png";
import PK_INTIALISING from "@/static/media/icons/pk/icon_pk_initialising.gif";
import PK_ENDED from "@/static/media/icons/pk/RESULT.png";
import PK_PUNISHING from "@/static/media/icons/pk/punishing.png";
import PK_PLAYING from "@/static/media/icons/pk/icon_pk_status.gif";
import PK_LOGO from '@/static/media/icons/pk/pk_logo.png'
import PK_THUMB from '@/static/media/icons/pk/pk_thumb.png'

export const DEFAULT_ICON_STATES = {
	closeCircleGreyWhite: {
		isHover: false,
		activeSrc: CloseCircleGreyWhite,
		inactiveSrc: CloseCircleGreyWhite,
	},
	supermarket: {
		isHover: false,
		activeSrc: Supermarket,
		inactiveSrc: Supermarket,
	},
	live: {
		isHover: false,
		activeSrc: LiveIcon,
		inactiveSrc: LiveIcon,
	},
	play: {
		isHover: false,
		activeSrc: PlayIcon,
		inactiveSrc: PlayIcon,
	},
	logo: {
		isHover: false,
		activeSrc: Logo,
		inactiveSrc: Logo,
	},
	logoSecondary: {
		isHover: false,
		activeSrc: LogoSecondary,
		inactiveSrc: LogoSecondary,
	},
	love: {
		isHover: false,
		activeSrc: LoveIcon,
		inactiveSrc: LoveIcon,
	},
	eye: {
		isHover: false,
		activeSrc: EyeIcon,
		inactiveSrc: EyeIcon,
	},
	pin: {
		isHover: false,
		activeSrc: PinIcon,
		inactiveSrc: PinIcon,
	},
	pencil: {
		isHover: false,
		activeSrc: PencilIcon,
		inactiveSrc: PencilIcon,
	},
	heart: {
		isHover: false,
		activeSrc: HeartIcon,
		inactiveSrc: HeartIcon,
	},
	heartRed: {
		isHover: false,
		activeSrc: HeartRedIcon,
		inactiveSrc: HeartRedIcon,
	},
	share: {
		isHover: false,
		activeSrc: ShareIcon,
		inactiveSrc: ShareIcon,
	},
	flyingThumb: {
		isHover: false,
		activeSrc: FlyingThumb,
		inactiveSrc: FlyingThumb,
	},
	close: {
		isHover: false,
		activeSrc: CloseIcon,
		inactiveSrc: CloseIcon,
	},
	closeRed: {
		isHover: false,
		activeSrc: CloseRedIcon,
		inactiveSrc: CloseRedIcon,
	},
	fire: {
		isHover: false,
		activeSrc: FireIcon,
		inactiveSrc: FireIcon,
	},
	shop: {
		isHover: false,
		activeSrc: ShopIcon,
		inactiveSrc: ShopIcon,
	},
	redeem: {
		isHover: false,
		activeSrc: RedeemIcon,
		inactiveSrc: RedeemIcon,
	},
	cart: {
		isHover: false,
		activeSrc: CartIcon,
		inactiveSrc: CartIcon,
	},
	gift: {
		isHover: false,
		activeSrc: GiftIcon,
		inactiveSrc: GiftIcon,
	},
	ticket: {
		isHover: false,
		activeSrc: TicketIcon,
		inactiveSrc: TicketIcon,
	},
	ticketAlt: {
		isHover: false,
		activeSrc: TicketAltIcon,
		inactiveSrc: TicketAltIcon,
	},
	favourite: {
		isHover: false,
		activeSrc: FavouriteIcon,
		inactiveSrc: FavouriteIcon,
	},
	email: {
		isHover: false,
		activeSrc: EmailIcon,
		inactiveSrc: EmailIcon,
	},
	checkEmail: {
		isHover: false,
		activeSrc: CheckEmailIcon,
		inactiveSrc: CheckEmailIcon,
	},
	yeay: {
		isHover: false,
		activeSrc: YeayIcon,
		inactiveSrc: YeayIcon,
	},
	transactionFailed: {
		isHover: false,
		activeSrc: TransactionFailedIcon,
		inactiveSrc: TransactionFailedIcon,
	},
	thankYou: {
		isHover: false,
		activeSrc: ThankYouIcon,
		inactiveSrc: ThankYouIcon,
	},
	success: {
		isHover: false,
		activeSrc: SuccessIcon,
		inactiveSrc: SuccessIcon,
	},
	opps: {
		isHover: false,
		activeSrc: OppsIcon,
		inactiveSrc: OppsIcon,
	},
	diamond: {
		isHover: false,
		activeSrc: DiamondIcon,
		inactiveSrc: DiamondIcon,
	},
	topUp: {
		isHover: false,
		activeSrc: TopUpIcon,
		inactiveSrc: TopUpIcon,
	},
	setting: {
		isHover: false,
		activeSrc: SettingIcon,
		inactiveSrc: SettingIcon,
	},
	signOut: {
		isHover: false,
		activeSrc: SignOutIcon,
		inactiveSrc: SignOutIcon,
	},
	profile: {
		isHover: false,
		activeSrc: ProfileIcon,
		inactiveSrc: ProfileIcon,
	},
	diamondBlue: {
		isHover: false,
		activeSrc: DiamondBlueIcon,
		inactiveSrc: DiamondBlueIcon,
	},
	closeBlack: {
		isHover: false,
		activeSrc: CloseBlackIcon,
		inactiveSrc: CloseBlackIcon,
	},
	closeCircleGrey: {
		isHover: false,
		activeSrc: CloseCircleGrey,
		inactiveSrc: CloseCircleGrey,
	},
	clock: {
		isHover: false,
		activeSrc: ClockIcon,
		inactiveSrc: ClockIcon,
	},
	arrowDown: {
		isHover: false,
		activeSrc: ArrowDownIcon,
		inactiveSrc: ArrowDownIcon,
	},
	search: {
		isHover: false,
		activeSrc: SearchIcon,
		inactiveSrc: SearchIcon,
	},
	token: {
		isHover: false,
		activeSrc: TokenIcon,
		inactiveSrc: TokenIcon,
	},
	eyeOff: {
		isHover: false,
		activeSrc: EyeOff,
		inactiveSrc: EyeOff,
	},
	eyeOn: {
		isHover: false,
		activeSrc: EyeOn,
		inactiveSrc: EyeOn,
	},
	fullscreen: {
		isHover: false,
		activeSrc: Fullscreen,
		inactiveSrc: Fullscreen,
	},
	clockWhite: {
		isHover: false,
		activeSrc: ClockWhiteIcon,
		inactiveSrc: ClockWhiteIcon,
	},
	navArrowLeft: {
		isHover: false,
		activeSrc: NavArrowLeft,
		inactiveSrc: NavArrowLeft,
	},
	logout: {
		isHover: false,
		activeSrc: LogoutIcon,
		inactiveSrc: LogoutIcon,
	},
	exitFullscreen: {
		isHover: false,
		activeSrc: ExitFullscreen,
		inactiveSrc: ExitFullscreen,
	},
	calendar: {
		isHover: false,
		activeSrc: CalendarIcon,
		inactiveSrc: CalendarIcon,
	},
	sendWithArrow: {
		isHover: false,
		activeSrc: SendWithArrowIcon,
		inactiveSrc: SendWithArrowIcon,
	},
	closeCircleBlack: {
		isHover: false,
		activeSrc: CloseCircleBlack,
		inactiveSrc: CloseCircleBlack,
	},
	arrowCircleUp: {
		isHover: false,
		activeSrc: ArrowCircleUp,
		inactiveSrc: ArrowCircleUp,
	},
	arrowCircleDown: {
		isHover: false,
		activeSrc: ArrowCircleDown,
		inactiveSrc: ArrowCircleDown,
	},
	heartIcon2: {
		isHover: false,
		activeSrc: HeartIcon2,
		inactiveSrc: HeartIcon2,
	},
	shopIcon2: {
		isHover: false,
		activeSrc: ShopIcon2,
		inactiveSrc: ShopIcon2,
	},
	giftIcon2: {
		isHover: false,
		activeSrc: GiftIcon2,
		inactiveSrc: GiftIcon2,
	},
	shareIcon2: {
		isHover: false,
		activeSrc: ShareIcon2,
		inactiveSrc: ShareIcon2,
	},
	pollIcon: {
		isHover: false,
		activeSrc: PollIcon,
		inactiveSrc: PollIcon,
	},
	bigGift: {
		isHover: false,
		activeSrc: BigGift,
		inactiveSrc: BigGift,
	},
	successGreen: {
		isHover: false,
		activeSrc: SuccessGreen,
		inactiveSrc: SuccessGreen,
	},
	successRed: {
		isHover: false,
		activeSrc: SuccessRed,
		inactiveSrc: SuccessRed,
	},
	ticketRedIcon: {
		isHover: false,
		activeSrc: TicketRedIcon,
		inactiveSrc: TicketRedIcon,
	},
	arrowRightIcon: {
		isHover: false,
		activeSrc: ArrowRightIcon,
		inactiveSrc: ArrowRightIcon,
	},
	arrowLeftIcon: {
		isHover: false,
		activeSrc: ArrowLeftIcon,
		inactiveSrc: ArrowLeftIcon,
	},
	downloadIcon: {
		isHover: false,
		activeSrc: DownloadIcon,
		inactiveSrc: DownloadIcon,
	},
	appleIcon: {
		isHover: false,
		activeSrc: AppleIcon,
		inactiveSrc: AppleIcon,
	},
	googlePlayIcon: {
		isHover: false,
		activeSrc: GooglePlayIcon,
		inactiveSrc: GooglePlayIcon,
	},
	buyTicketIcon: {
		isHover: false,
		activeSrc: BuyTicketIcon,
		inactiveSrc: BuyTicketIcon,
	},
	watchNowIcon: {
		isHover: false,
		activeSrc: WatchNowIcon,
		inactiveSrc: WatchNowIcon,
	},
	checkCircleGreenIcon: {
		isHover: false,
		activeSrc: CheckCircleGreenIcon,
		inactiveSrc: CheckCircleGreenIcon,
	},
	backToTop: {
		isHover: false,
		activeSrc: BackToTopIcon,
		inactiveSrc: BackToTopIcon,
	},
};

export const EVENT_ICONS = {
	calendarWhite: {
		isHover: false,
		activeSrc: CalendarWhiteIcon,
		inactiveSrc: CalendarWhiteIcon,
	},
	ticketWhite: {
		isHover: false,
		activeSrc: TicketWhiteIcon,
		inactiveSrc: TicketWhiteIcon,
	},
};

export const VIDEO_ICONS = {
	mute: {
		isHover: false,
		activeSrc: MuteIcon,
		inactiveSrc: MuteIcon,
	},
	unmute: {
		isHover: false,
		activeSrc: UnMuteIcon,
		inactiveSrc: UnMuteIcon,
	},
	play: {
		isHover: false,
		activeSrc: PLAY_ICON,
		inactiveSrc: PLAY_ICON,
	},
};

export const PK_ICONS = {
	pk: {
		isHover: false,
		activeSrc: PkIcon,
		inactiveSrc: PkIcon,
		blue: PkBlue,
		pink: PkPink,
		blueBase: PkBlueBase,
		pinkBase: PkPinkBase,
		base: PkBase,
		Pk1,
		Pk0,
		Pk2,
		Pk3,
		Pk4,
		Pk5,
		Pk6,
		Pk7,
		Pk8,
		Pk9,
		PkPer,
	},
	defaultAvatar: {
		isHover: false,
		activeSrc: DefaultAvatar,
		inactiveSrc: DefaultAvatar,
	},
	draw: {
		isHover: false,
		activeSrc: PK_DRAW,
		inactiveSrc: PK_DRAW,
	},
	loser: {
		isHover: false,
		activeSrc: PK_LOSER,
		inactiveSrc: PK_LOSER,
	},
	winner: {
		isHover: false,
		activeSrc: PK_WINNER,
		inactiveSrc: PK_WINNER,
	},
	intialising: {
		isHover: false,
		activeSrc: PK_INTIALISING,
		inactiveSrc: PK_INTIALISING,
	},
	ended: {
		isHover: false,
		activeSrc: PK_ENDED,
		inactiveSrc: PK_ENDED,
	},
	punishing: {
		isHover: false,
		activeSrc: PK_PUNISHING,
		inactiveSrc: PK_PUNISHING,
	},
	playing: {
		isHover: false,
		activeSrc: PK_PLAYING,
		inactiveSrc: PK_PLAYING,
	},
  	pkLogo: {
		isHover: false,
		activeSrc: PK_LOGO,
		inactiveSrc: PK_LOGO,
	},
	pkThumb: {
		isHover: false,
		activeSrc: PK_THUMB,
		inactiveSrc: PK_THUMB,
	},
};
