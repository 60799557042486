/* eslint-disable no-unused-vars */
import Vue from "vue";

Vue.directive("scroll", {
   bind: function(el, binding, vnode) {
      el.onScrollEvent = (event) => {
         if (typeof binding.value === "function") {
            binding.value(el);
         }
      };
      el.addEventListener("scroll", el.onScrollEvent);
   },
   unbind: function(el) {
      el.removeEventListener("scroll", el.onScrollEvent);
   }
});
