<template>
	<div id="apple-login-button" @click="login">
		<slot></slot>
	</div>
</template>

<script>
import { uuid } from 'vue-uuid'
import HelperMixins from '@/mixins/HelperMixins'
import { SIGN_IN_TYPE } from '~/configs/SDK'
import { PUBLIC_SDK_SCRIPT } from '~/configs/Settings'

const APPLE_PRIVATE_EMAIL_EXTEND = '@privaterelay.appleid.com'

export default {
	name: 'SignInWithApple',
	mixins: [HelperMixins],
	mounted() {
		try {
			this.initScript(
				document,
				'script',
				{ src: PUBLIC_SDK_SCRIPT.APPLE, id: 'apple-jssdk' },
				this.init
			)
		} catch (e) {
			// handle error.
			console.log(e)
		}
	},
	methods: {
		init() {
			if (process.browser) {
				window.AppleID.auth.init({
					clientId: process.env.APPLE_CLIENT_ID,
					scope: 'name email',
					redirectURI: process.env.APPLE_REDIRECT_URL,
					state: `${uuid.v4()}`,
					usePopup: true,
				})
			}
		},
		async login() {
			try {
				if (process.browser) {
					const data = await window.AppleID.auth.signIn()
					const token = data?.authorization?.id_token
					const username = data?.user?.name
					const decodedData = atob(token?.split('.')[1])
					const payload = JSON.parse(decodedData)

					if (payload) {
						this.$emit(
							'onSuccess',
							{
								userId: payload.sub,
								accessToken: token,
								email: payload.email.includes(
									APPLE_PRIVATE_EMAIL_EXTEND
								)
									? ''
									: payload.email,
								name: username
									? `${username?.firstName} ${username?.lastName}`
									: '',
							},
							SIGN_IN_TYPE.APPLE
						)
					}
				}
			} catch (error) {
				// handle error.
				console.error(error)
			}
		},
	},
}
</script>
