<template>
	<div class="error-page">
		<div class="error-page__container">
			<div class="error-page__header-wrapper">
				<h1 class="error-page__header">
					{{ error.statusCode }}
				</h1>
			</div>
			<div class="error-page__info">
				<div v-if="error.message && envDev" class="error-page__detail">
					{{ error.message }}
				</div>
				<h2 class="error-page__title">
					{{
						error.statusCode === 404
							? 'Page not found'
							: 'An error occurred'
					}}
				</h2>
				<div class="error-page__description">
					The page you are looking for not available!
				</div>
				<NuxtLink :to="DEFAULT_HOMEPAGE_URL">
					<button
						type="button"
						class="btn btn-animated btn-primary error-page__action"
					>
						Go to Home
					</button>
				</NuxtLink>
			</div>
		</div>
	</div>
</template>

<script>
import ERROR_GIFT from '@/static/media/gifs/error.gif'
import { DEFAULT_HOMEPAGE_URL } from '@/configs/Settings'
import LayoutMixins from '@/mixins/LayoutMixins'

export default {
	mixins: [LayoutMixins],
	layout: 'simple',
	middleware: ['checkIsMaintenance'],
	props: ['error'], // you can set a custom layout for the error page
	data() {
		return {
			ERROR_GIFT,
			DEFAULT_HOMEPAGE_URL,
			envDev: process.env.APP_ENV !== 'production',
		}
	},
}
</script>
<style lang="scss" scoped>
.error-page {
	padding: 2rem 2rem 4rem 2rem;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		width: 100%;
		height: 100%;
	}
	&__header-wrapper {
		background-image: url('@/static/media/gifs/error.gif');
		height: 40rem;
		background-position: center;
		width: 100%;
		background-repeat: no-repeat;
		background-size: contain;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	&__info {
		margin-top: -8rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 1.6rem;
		color: #606060;
		font-family: $font-secondary;
	}
	&__header {
		font-size: 5rem;
		font-weight: bold;
		color: #1f1f1f;
		font-family: $font-primary;
		margin-top: 2rem;
	}
	&__title {
		font-size: 2rem;
		font-weight: bold;
		color: #1f1f1f;
		font-family: $font-secondary;
		margin-bottom: 1rem;
	}
	&__description {
		margin-bottom: 2rem;
	}
	&__detail {
		margin-bottom: 1rem;
	}
}
</style>
