// Array of country objects for the flag dropdown.

// Here is the criteria for the plugin to support a given country/territory
// - It has an iso2 code: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
// - It has it's own country calling code (it is not a sub-region of another country): https://en.wikipedia.org/wiki/List_of_country_calling_codes
// - It has a flag in the region-flags project: https://github.com/behdad/region-flags/tree/gh-pages/png
// - It is supported by libphonenumber (it must be listed on this page): https://github.com/googlei18n/libphonenumber/blob/master/resources/ShortNumberMetadata.xml

// Each country array has the following information:
// [
//    Country name,
//    iso2 code,
//    International dial code,
//    Order (if >1 country with same dial code),
//    Area codes
// ]
const countries = {
	en: [
		['Afghanistan', 'af', '93'],
		['Albania', 'al', '355'],
		['Algeria', 'dz', '213'],
		['American Samoa', 'as', '1684'],
		['Andorra', 'ad', '376'],
		['Angola', 'ao', '244'],
		['Anguilla', 'ai', '1264'],
		['Antigua and Barbuda', 'ag', '1268'],
		['Argentina', 'ar', '54'],
		['Armenia', 'am', '374'],
		['Aruba', 'aw', '297'],
		['Australia', 'au', '61', 0],
		['Austria', 'at', '43'],
		['Azerbaijan', 'az', '994'],
		['Bahamas', 'bs', '1242'],
		['Bahrain', 'bh', '973'],
		['Bangladesh', 'bd', '880'],
		['Barbados', 'bb', '1246'],
		['Belarus', 'by', '375'],
		['Belgium', 'be', '32'],
		['Belize', 'bz', '501'],
		['Benin', 'bj', '229'],
		['Bermuda', 'bm', '1441'],
		['Bhutan', 'bt', '975'],
		['Bolivia', 'bo', '591'],
		['Bosnia and Herzegovina', 'ba', '387'],
		['Botswana', 'bw', '267'],
		['Brazil', 'br', '55'],
		['British Indian Ocean Territory', 'io', '246'],
		['British Virgin Islands', 'vg', '1284'],
		['Brunei', 'bn', '673'],
		['Bulgaria', 'bg', '359'],
		['Burkina Faso', 'bf', '226'],
		['Burundi', 'bi', '257'],
		['Cambodia', 'kh', '855'],
		['Cameroon', 'cm', '237'],
		[
			'Canada',
			'ca',
			'1',
			1,
			[
				'204',
				'226',
				'236',
				'249',
				'250',
				'289',
				'306',
				'343',
				'365',
				'387',
				'403',
				'416',
				'418',
				'431',
				'437',
				'438',
				'450',
				'506',
				'514',
				'519',
				'548',
				'579',
				'581',
				'587',
				'604',
				'613',
				'639',
				'647',
				'672',
				'705',
				'709',
				'742',
				'778',
				'780',
				'782',
				'807',
				'819',
				'825',
				'867',
				'873',
				'902',
				'905',
			],
		],
		['Cape Verde', 'cv', '238'],
		['Caribbean Netherlands', 'bq', '599', 1],
		['Cayman Islands', 'ky', '1345'],
		['Central African Republic', 'cf', '236'],
		['Chad', 'td', '235'],
		['Chile', 'cl', '56'],
		['China', 'cn', '86'],
		['Christmas Island', 'cx', '61', 2],
		['Cocos Islands', 'cc', '61', 1],
		['Colombia', 'co', '57'],
		['Comoros', 'km', '269'],
		['Congo', 'cd', '243'],
		['Congo', 'cg', '242'],
		['Cook Islands', 'ck', '682'],
		['Costa Rica', 'cr', '506'],
		['Côte d’Ivoire', 'ci', '225'],
		['Croatia', 'hr', '385'],
		['Cuba', 'cu', '53'],
		['Curaçao', 'cw', '599', 0],
		['Cyprus', 'cy', '357'],
		['Czech Republic', 'cz', '420'],
		['Denmark', 'dk', '45'],
		['Djibouti', 'dj', '253'],
		['Dominica', 'dm', '1767'],
		['Dominican Republic', 'do', '1', 2, ['809', '829', '849']],
		['Ecuador', 'ec', '593'],
		['Egypt', 'eg', '20'],
		['El Salvador', 'sv', '503'],
		['Equatorial Guinea', 'gq', '240'],
		['Eritrea', 'er', '291'],
		['Estonia', 'ee', '372'],
		['Ethiopia', 'et', '251'],
		['Falkland Islands', 'fk', '500'],
		['Faroe Islands', 'fo', '298'],
		['Fiji', 'fj', '679'],
		['Finland', 'fi', '358', 0],
		['France', 'fr', '33'],
		['French Guiana', 'gf', '594'],
		['French Polynesia', 'pf', '689'],
		['Gabon', 'ga', '241'],
		['Gambia', 'gm', '220'],
		['Georgia', 'ge', '995'],
		['Germany', 'de', '49'],
		['Ghana', 'gh', '233'],
		['Gibraltar', 'gi', '350'],
		['Greece', 'gr', '30'],
		['Greenland', 'gl', '299'],
		['Grenada', 'gd', '1473'],
		['Guadeloupe', 'gp', '590', 0],
		['Guam', 'gu', '1671'],
		['Guatemala', 'gt', '502'],
		['Guernsey', 'gg', '44', 1],
		['Guinea', 'gn', '224'],
		['Guinea-Bissau', 'gw', '245'],
		['Guyana', 'gy', '592'],
		['Haiti', 'ht', '509'],
		['Honduras', 'hn', '504'],
		['Hong Kong', 'hk', '852'],
		['Hungary', 'hu', '36'],
		['Iceland', 'is', '354'],
		['India', 'in', '91'],
		['Indonesia', 'id', '62'],
		['Iran', 'ir', '98'],
		['Iraq', 'iq', '964'],
		['Ireland', 'ie', '353'],
		['Isle of Man', 'im', '44', 2],
		['Israel', 'il', '972'],
		['Italy', 'it', '39', 0],
		['Jamaica', 'jm', '1876'],
		['Japan', 'jp', '81'],
		['Jersey', 'je', '44', 3],
		['Jordan', 'jo', '962'],
		['Kazakhstan', 'kz', '7', 1],
		['Kenya', 'ke', '254'],
		['Kiribati', 'ki', '686'],
		['Kosovo', 'xk', '383'],
		['Kuwait', 'kw', '965'],
		['Kyrgyzstan', 'kg', '996'],
		['Laos', 'la', '856'],
		['Latvia', 'lv', '371'],
		['Lebanon', 'lb', '961'],
		['Lesotho', 'ls', '266'],
		['Liberia', 'lr', '231'],
		['Libya', 'ly', '218'],
		['Liechtenstein', 'li', '423'],
		['Lithuania', 'lt', '370'],
		['Luxembourg', 'lu', '352'],
		['Macau', 'mo', '853'],
		['Macedonia', 'mk', '389'],
		['Madagascar', 'mg', '261'],
		['Malawi', 'mw', '265'],
		['Malaysia', 'my', '60'],
		['Maldives', 'mv', '960'],
		['Mali', 'ml', '223'],
		['Malta', 'mt', '356'],
		['Marshall Islands', 'mh', '692'],
		['Martinique', 'mq', '596'],
		['Mauritania', 'mr', '222'],
		['Mauritius', 'mu', '230'],
		['Mayotte', 'yt', '262', 1],
		['Mexico', 'mx', '52'],
		['Micronesia', 'fm', '691'],
		['Moldova', 'md', '373'],
		['Monaco', 'mc', '377'],
		['Mongolia', 'mn', '976'],
		['Montenegro', 'me', '382'],
		['Montserrat', 'ms', '1664'],
		['Morocco', 'ma', '212', 0],
		['Mozambique', 'mz', '258'],
		['Myanmar', 'mm', '95'],
		['Namibia', 'na', '264'],
		['Nauru', 'nr', '674'],
		['Nepal', 'np', '977'],
		['Netherlands', 'nl', '31'],
		['New Caledonia', 'nc', '687'],
		['New Zealand', 'nz', '64'],
		['Nicaragua', 'ni', '505'],
		['Niger', 'ne', '227'],
		['Nigeria', 'ng', '234'],
		['Niue', 'nu', '683'],
		['Norfolk Island', 'nf', '672'],
		['North Korea', 'kp', '850'],
		['Northern Mariana Islands', 'mp', '1670'],
		['Norway', 'no', '47', 0],
		['Oman', 'om', '968'],
		['Pakistan', 'pk', '92'],
		['Palau', 'pw', '680'],
		['Palestine', 'ps', '970'],
		['Panama', 'pa', '507'],
		['Papua New Guinea', 'pg', '675'],
		['Paraguay', 'py', '595'],
		['Peru', 'pe', '51'],
		['Philippines', 'ph', '63'],
		['Poland', 'pl', '48'],
		['Portugal', 'pt', '351'],
		['Puerto Rico', 'pr', '1', 3, ['787', '939']],
		['Qatar', 'qa', '974'],
		['Réunion', 're', '262', 0],
		['Romania', 'ro', '40'],
		['Russia', 'ru', '7', 0],
		['Rwanda', 'rw', '250'],
		['Saint Barthélemy', 'bl', '590', 1],
		['Saint Helena', 'sh', '290'],
		['Saint Kitts and Nevis', 'kn', '1869'],
		['Saint Lucia', 'lc', '1758'],
		['Saint Martin', 'mf', '590', 2],
		['Saint Pierre and Miquelon', 'pm', '508'],
		['Saint Vincent and the Grenadines', 'vc', '1784'],
		['Samoa', 'ws', '685'],
		['San Marino', 'sm', '378'],
		['São Tomé and Príncipe', 'st', '239'],
		['Saudi Arabia', 'sa', '966'],
		['Senegal', 'sn', '221'],
		['Serbia', 'rs', '381'],
		['Seychelles', 'sc', '248'],
		['Sierra Leone', 'sl', '232'],
		['Singapore', 'sg', '65'],
		['Sint Maarten', 'sx', '1721'],
		['Slovakia', 'sk', '421'],
		['Slovenia', 'si', '386'],
		['Solomon Islands', 'sb', '677'],
		['Somalia', 'so', '252'],
		['South Africa', 'za', '27'],
		['South Korea', 'kr', '82'],
		['South Sudan', 'ss', '211'],
		['Spain', 'es', '34'],
		['Sri Lanka', 'lk', '94'],
		['Sudan', 'sd', '249'],
		['Suriname', 'sr', '597'],
		['Svalbard and Jan Mayen', 'sj', '47', 1],
		['Swaziland', 'sz', '268'],
		['Sweden', 'se', '46'],
		['Switzerland', 'ch', '41'],
		['Syria', 'sy', '963'],
		['Taiwan', 'tw', '886'],
		['Tajikistan', 'tj', '992'],
		['Tanzania', 'tz', '255'],
		['Thailand', 'th', '66'],
		['Timor-Leste', 'tl', '670'],
		['Togo', 'tg', '228'],
		['Tokelau', 'tk', '690'],
		['Tonga', 'to', '676'],
		['Trinidad and Tobago', 'tt', '1868'],
		['Tunisia', 'tn', '216'],
		['Turkey', 'tr', '90'],
		['Turkmenistan', 'tm', '993'],
		['Turks and Caicos Islands', 'tc', '1649'],
		['Tuvalu', 'tv', '688'],
		['U.S. Virgin Islands', 'vi', '1340'],
		['Uganda', 'ug', '256'],
		['Ukraine', 'ua', '380'],
		['United Arab Emirates', 'ae', '971'],
		['United Kingdom', 'gb', '44', 0],
		['United States', 'us', '1', 0],
		['Uruguay', 'uy', '598'],
		['Uzbekistan', 'uz', '998'],
		['Vanuatu', 'vu', '678'],
		['Vatican City', 'va', '39', 1],
		['Venezuela', 've', '58'],
		['Vietnam', 'vn', '84'],
		['Wallis and Futuna', 'wf', '681'],
		['Western Sahara', 'eh', '212', 1],
		['Yemen', 'ye', '967'],
		['Zambia', 'zm', '260'],
		['Zimbabwe', 'zw', '263'],
		['Åland Islands', 'ax', '358', 1],
	],
	'zh-cn': [
		['阿富汗', 'af', '93'],
		['阿尔巴尼亚', 'al', '355'],
		['阿尔及利亚', 'dz', '213'],
		['美国萨摩亚', 'as', '1684'],
		['安道尔', 'ad', '376'],
		['安哥拉', 'ao', '244'],
		['安圭拉', 'ai', '1264'],
		['安提瓜和巴布达', 'ag', '1268'],
		['阿根廷', 'ar', '54'],
		['亚美尼亚', 'am', '374'],
		['阿鲁巴', 'aw', '297'],
		['澳大利亚', 'au', '61', 0],
		['奥地利', 'at', '43'],
		['阿塞拜疆', 'az', '994'],
		['巴哈马', 'bs', '1242'],
		['巴林', 'bh', '973'],
		['孟加拉国', 'bd', '880'],
		['巴巴多斯', 'bb', '1246'],
		['白俄罗斯', 'by', '375'],
		['比利时', 'be', '32'],
		['伯利兹', 'bz', '501'],
		['贝宁', 'bj', '229'],
		['百慕大', 'bm', '1441'],
		['不丹', 'bt', '975'],
		['玻利维亚', 'bo', '591'],
		['波斯尼亚和黑塞哥维', 'ba', '387'],
		['博茨瓦纳', 'bw', '267'],
		['巴西', 'br', '55'],
		['英属印度洋领地', 'io', '246'],
		['英国维尔京群岛', 'vg', '1284'],
		['文莱', 'bn', '673'],
		['保加利亚', 'bg', '359'],
		['布基纳法索', 'bf', '226'],
		['布隆迪', 'bi', '257'],
		['柬埔寨', 'kh', '855'],
		['喀麦隆', 'cm', '237'],
		[
			'加拿大',
			'ca',
			'1',
			1,
			[
				'204',
				'226',
				'236',
				'249',
				'250',
				'289',
				'306',
				'343',
				'365',
				'387',
				'403',
				'416',
				'418',
				'431',
				'437',
				'438',
				'450',
				'506',
				'514',
				'519',
				'548',
				'579',
				'581',
				'587',
				'604',
				'613',
				'639',
				'647',
				'672',
				'705',
				'709',
				'742',
				'778',
				'780',
				'782',
				'807',
				'819',
				'825',
				'867',
				'873',
				'902',
				'905',
			],
		],
		['佛得角', 'cv', '238'],
		['加勒比荷兰', 'bq', '599', 1],
		['开曼群岛', 'ky', '1345'],
		['中非共和国', 'cf', '236'],
		['乍得', 'td', '235'],
		['智利', 'cl', '56'],
		['中国', 'cn', '86'],
		['圣诞岛', 'cx', '61', 2],
		['科科斯群岛', 'cc', '61', 1],
		['哥伦比亚', 'co', '57'],
		['科摩罗', 'km', '269'],
		['刚果', 'cd', '243'],
		['刚果', 'cg', '242'],
		['库克群岛', 'ck', '682'],
		['哥斯达黎加', 'cr', '506'],
		['科特迪瓦', 'ci', '225'],
		['克罗地亚', 'hr', '385'],
		['古巴', 'cu', '53'],
		['库拉索', 'cw', '599', 0],
		['塞浦路斯', 'cy', '357'],
		['捷克共和国', 'cz', '420'],
		['丹麦', 'dk', '45'],
		['吉布提', 'dj', '253'],
		['多米尼克', 'dm', '1767'],
		['多明尼加共和国', 'do', '1', 2, ['809', '829', '849']],
		['厄瓜多尔', 'ec', '593'],
		['埃及', 'eg', '20'],
		['萨尔瓦多', 'sv', '503'],
		['赤道几内亚', 'gq', '240'],
		['厄立特里亚', 'er', '291'],
		['爱沙尼亚', 'ee', '372'],
		['埃塞俄比亚', 'et', '251'],
		['福克兰群岛', 'fk', '500'],
		['法罗群岛', 'fo', '298'],
		['斐济', 'fj', '679'],
		['芬兰', 'fi', '358', 0],
		['法国', 'fr', '33'],
		['法属圭亚那', 'gf', '594'],
		['法国波利尼西亚', 'pf', '689'],
		['加蓬', 'ga', '241'],
		['冈比亚', 'gm', '220'],
		['乔治亚州', 'ge', '995'],
		['德国', 'de', '49'],
		['加纳', 'gh', '233'],
		['直布罗陀', 'gi', '350'],
		['希腊', 'gr', '30'],
		['格陵兰', 'gl', '299'],
		['格林纳达', 'gd', '1473'],
		['瓜德罗普', 'gp', '590', 0],
		['关岛', 'gu', '1671'],
		['危地马拉', 'gt', '502'],
		['根西岛', 'gg', '44', 1],
		['几内亚', 'gn', '224'],
		['几内亚比绍', 'gw', '245'],
		['圭亚那', 'gy', '592'],
		['海地', 'ht', '509'],
		['洪都拉斯', 'hn', '504'],
		['香港', 'hk', '852'],
		['匈牙利', 'hu', '36'],
		['冰岛', 'is', '354'],
		['印度', 'in', '91'],
		['印度尼西亚', 'id', '62'],
		['伊朗', 'ir', '98'],
		['伊拉克', 'iq', '964'],
		['爱尔兰', 'ie', '353'],
		['伊斯勒人', 'im', '44', 2],
		['以色列', 'il', '972'],
		['意大利', 'it', '39', 0],
		['牙买加', 'jm', '1876'],
		['日本', 'jp', '81'],
		['球衣', 'je', '44', 3],
		['约旦', 'jo', '962'],
		['哈萨克斯坦', 'kz', '7', 1],
		['肯尼亚', 'ke', '254'],
		['基里巴斯', 'ki', '686'],
		['科索沃', 'xk', '383'],
		['科威特', 'kw', '965'],
		['吉尔吉斯斯坦', 'kg', '996'],
		['老挝', 'la', '856'],
		['拉脱维亚', 'lv', '371'],
		['黎巴嫩', 'lb', '961'],
		['莱索托', 'ls', '266'],
		['利比里亚', 'lr', '231'],
		['利比亚', 'ly', '218'],
		['列支敦士登', 'li', '423'],
		['立陶宛', 'lt', '370'],
		['卢森堡', 'lu', '352'],
		['澳门', 'mo', '853'],
		['马其顿', 'mk', '389'],
		['马达加斯加', 'mg', '261'],
		['马拉维', 'mw', '265'],
		['马来西亚', 'my', '60'],
		['马尔代夫', 'mv', '960'],
		['马里', 'ml', '223'],
		['马耳他', 'mt', '356'],
		['马绍尔群岛', 'mh', '692'],
		['马提尼克岛', 'mq', '596'],
		['毛里塔尼亚', 'mr', '222'],
		['毛里求斯', 'mu', '230'],
		['Mostotte.', 'yt', '262', 1],
		['墨西哥', 'mx', '52'],
		['密克罗尼西亚', 'fm', '691'],
		['摩尔多瓦', 'md', '373'],
		['摩纳哥', 'mc', '377'],
		['蒙古', 'mn', '976'],
		['黑山', 'me', '382'],
		['蒙特塞拉特', 'ms', '1664'],
		['摩洛哥', 'ma', '212', 0],
		['莫桑比克', 'mz', '258'],
		['缅甸', 'mm', '95'],
		['纳米比亚', 'na', '264'],
		['瑙鲁', 'nr', '674'],
		['尼泊尔', 'np', '977'],
		['荷兰', 'nl', '31'],
		['新喀里多尼亚', 'nc', '687'],
		['新西兰', 'nz', '64'],
		['尼加拉瓜', 'ni', '505'],
		['尼日尔', 'ne', '227'],
		['尼日利亚', 'ng', '234'],
		['纽厄斯', 'nu', '683'],
		['诺福克岛', 'nf', '672'],
		['北朝鲜', 'kp', '850'],
		['北马里亚纳群岛', 'mp', '1670'],
		['挪威', 'no', '47', 0],
		['阿曼', 'om', '968'],
		['巴基斯坦', 'pk', '92'],
		['帕劳', 'pw', '680'],
		['巴勒斯坦', 'ps', '970'],
		['巴拿马', 'pa', '507'],
		['巴布亚新几内亚', 'pg', '675'],
		['巴拉圭', 'py', '595'],
		['秘鲁', 'pe', '51'],
		['菲律宾', 'ph', '63'],
		['波兰', 'pl', '48'],
		['葡萄牙', 'pt', '351'],
		['波多黎各', 'pr', '1', 3, ['787', '939']],
		['卡塔尔', 'qa', '974'],
		['团圆', 're', '262', 0],
		['罗马尼亚', 'ro', '40'],
		['俄罗斯', 'ru', '7', 0],
		['卢旺达', 'rw', '250'],
		['圣巴塞齐米', 'bl', '590', 1],
		['圣海伦娜', 'sh', '290'],
		['圣基茨和尼维斯', 'kn', '1869'],
		['圣卢西亚', 'lc', '1758'],
		['圣马丁', 'mf', '590', 2],
		['圣皮埃尔和米利翁', 'pm', '508'],
		['圣文森特和格林纳丁斯', 'vc', '1784'],
		['萨摩亚', 'ws', '685'],
		['圣马力诺', 'sm', '378'],
		['Sãotomé和príncipe', 'st', '239'],
		['沙特阿拉伯', 'sa', '966'],
		['塞内加尔', 'sn', '221'],
		['塞尔维亚', 'rs', '381'],
		['塞舌尔', 'sc', '248'],
		['塞拉利昂', 'sl', '232'],
		['新加坡', 'sg', '65'],
		['Sint Maarten.', 'sx', '1721'],
		['斯洛伐克', 'sk', '421'],
		['斯洛文尼亚', 'si', '386'],
		['所罗门群岛', 'sb', '677'],
		['索马里', 'so', '252'],
		['南非', 'za', '27'],
		['韩国', 'kr', '82'],
		['南苏丹', 'ss', '211'],
		['西班牙', 'es', '34'],
		['斯里兰卡', 'lk', '94'],
		['苏丹', 'sd', '249'],
		['苏里南', 'sr', '597'],
		['Svalbard和Jan Mayen', 'sj', '47', 1],
		['斯威士兰', 'sz', '268'],
		['瑞典', 'se', '46'],
		['瑞士', 'ch', '41'],
		['叙利亚', 'sy', '963'],
		['台湾', 'tw', '886'],
		['塔吉克斯坦', 'tj', '992'],
		['坦桑尼亚', 'tz', '255'],
		['泰国', 'th', '66'],
		['Timor-Leste.', 'tl', '670'],
		['多哥', 'tg', '228'],
		['Tokelau.', 'tk', '690'],
		['汤加', 'to', '676'],
		['特立尼达和多巴哥', 'tt', '1868'],
		['突尼斯', 'tn', '216'],
		['火鸡', 'tr', '90'],
		['土库曼斯坦', 'tm', '993'],
		['特克斯和凯科斯群岛', 'tc', '1649'],
		['图瓦卢', 'tv', '688'],
		['美国维尔京群岛', 'vi', '1340'],
		['乌干达', 'ug', '256'],
		['乌克兰', 'ua', '380'],
		['阿拉伯联合酋长国', 'ae', '971'],
		['英国', 'gb', '44', 0],
		['美国', 'us', '1', 0],
		['乌拉圭', 'uy', '598'],
		['乌兹别克斯坦', 'uz', '998'],
		['瓦努阿图', 'vu', '678'],
		['梵蒂冈城', 'va', '39', 1],
		['委内瑞拉', 've', '58'],
		['越南', 'vn', '84'],
		['瓦利斯和福田', 'wf', '681'],
		['西撒哈拉', 'eh', '212', 1],
		['也门', 'ye', '967'],
		['赞比亚', 'zm', '260'],
		['津巴布韦', 'zw', '263'],
		['Åland群岛', 'ax', '358', 1],
	],
}

export const allCountries = (localCode = 'en') => {
	let allLocaleCountries = countries.en // default countries
	if (countries[localCode] && countries[localCode].length) {
		allLocaleCountries = countries[localCode]
	}
	return allLocaleCountries.map((country) => ({
		name: country[0],
		iso2: country[1].toUpperCase(),
		dialCode: country[2],
		priority: country[3] || 0,
		areaCodes: country[4] || null,
	}))
}
