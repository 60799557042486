<template>
	<div>
		<div class="noti-page container">
			<div class="noti-page__bg"></div>
			<Nuxt />
		</div>
	</div>
</template>
<script>
import LayoutMixins from '@/mixins/LayoutMixins'

export default {
	components: {},
	mixins: [LayoutMixins],
	middleware: ['checkIsMaintenance'],
	computed: {},
	beforeDestroy() {},
}
</script>

<style lang="scss">
.noti-page {
	min-height: calc((var(--vh, 1vh) * 100));
	height: 100%;
	position: relative;
	&__bg {
		background: url('~static/media/bg/icons@3x.png');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: contain;
	}
}
</style>
