import {
	VERITY_EMAIL_ROUTE,
	REDIRECT_URL,
	ROUTE_AVAILABLES,
	ROUTE_DISABLES,
	DEFAULT_HOMEPAGE_URL,
} from '@/configs/Settings'

export default function ({ route, redirect }) {
	// Redirect verify email
	if (
		VERITY_EMAIL_ROUTE &&
		route.name === REDIRECT_URL.AUTH_VERIFY_EMAIL.name
	) {
		return redirect(
			REDIRECT_URL.AUTH_VERIFY_EMAIL.to +
				new URLSearchParams(route.query).toString()
		)
	}

	/**
	 * Check route is not allow
	 */
	if (
		ROUTE_DISABLES &&
		ROUTE_DISABLES.length &&
		ROUTE_DISABLES.includes(route.name)
	) {
		return redirect(DEFAULT_HOMEPAGE_URL)
	}

	/**
	 * Check route is allow
	 */
	if (
		ROUTE_AVAILABLES &&
		ROUTE_AVAILABLES.length &&
		!ROUTE_AVAILABLES.includes(route.name)
	) {
		return redirect(DEFAULT_HOMEPAGE_URL)
	}
}
