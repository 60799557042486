import { SDK_API } from '@/configs/APIs'
const AUTH_APIS = SDK_API.AUTH

export default ($axios) => ({
  /**
   * Check user is logged in or not based on token header
   *
   */
  async checkLogin() {
    const {
      data: { code, data, message },
    } = await $axios.post(AUTH_APIS.CHECK_LOGIN)

    return { code, data, message }
  },

  /**
   * Guest login => get token for auth
   * @param {*} param0
   */
  async guestLogin({ deviceUdid, deviceType }) {
    const {
      data: { code, data, message },
    } = await $axios.post(AUTH_APIS.GUEST_LOGIN, {
      deviceUdid,
      deviceType,
    })
    return { code, data, message }
  },

  /**
   * Register user
   * @param {*} dataParams
   */
  async register(dataParams) {
    if (!dataParams) return
    const { code, codeDetails, message } = await $axios.$post(
      AUTH_APIS.REGISTER,
      dataParams
    )
    return { code, message, codeDetails }
  },

  /**
   * Verify email confirmation after register
   * @param {*} verifyCode
   */
  async verifyEmailConfirmation(verifyCode) {
    const { code } = await $axios.$post(AUTH_APIS.VERIFY_EMAIL_CONFIRMATION, {
      code: verifyCode,
    })
    return { code }
  },

  /**
   * Verify email before reset password
   * @param {*} email
   */
  async verifyEmailReset(email) {
    const { code, codeDetails, message } = await $axios.$post(
      AUTH_APIS.VERIFY_EMAIL_RESET,
      {
        email,
      }
    )
    return { code, codeDetails, message }
  },

  /**
   * Resend reset link
   * @param {*} email
   */
  async resendResetLink(email) {
    const { code, codeDetails, message } = await $axios.$post(
      AUTH_APIS.RESEND_RESET_LINK,
      {
        email,
      }
    )
    return { code, codeDetails, message }
  },

  /**
   * Verify code reset password
   * @param {*} codeReset
   */
  async verifyCodeReset(codeReset) {
    const { code, data, message } = await $axios.$post(
      AUTH_APIS.VERIFY_CODE_RESET,
      {
        code: codeReset,
      }
    )
    return { code, data, message }
  },

  /**
   * Reset password
   * @param { codeReset, password }
   */
  async resetPassword({ codeReset, password }) {
    const { code, data, message } = await $axios.$post(
      AUTH_APIS.RESET_PASSWORD,
      {
        code: codeReset,
        password,
      }
    )
    return { code, data, message }
  },

  /**
   * Login user
   * @param {*} dataParams
   */
  async login(dataParams) {
    if (!dataParams) return
    const {
      code,
      data: userData,
      message,
    } = await $axios.$post(AUTH_APIS.LOGIN, dataParams)
    return { code, data: userData, message }
  },

  /**
   * Resend verify email
   * @param {*} email
   */
  async resendVerifyEmail(email) {
    const { code, codeDetails, message } = await $axios.$post(
      AUTH_APIS.RESEND_VERIFY_EMAIL,
      {
        email,
      }
    )
    return { code, codeDetails, message }
  },
})
