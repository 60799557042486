<template>
	<div>
		<header class="py-3 d-none d-sm-block faq__header">
			<b-navbar toggleable="lg" variant="faded" type="light" class="">
				<b-container class="mx-auto">
					<b-row
						class="text-center w-100 justify-content-center"
						align-v="center"
					>
						<b-col class="pl-sm-0 pl-md-0"
							><b-navbar-brand href="/"
								><b-img
									src="@/static/media/images/logo-hyperlive.svg"
									fluid
									alt="Fluid image"
								></b-img></b-navbar-brand
						></b-col>

						<b-col
							cols="9"
							class="pr-sm-0 pr-md-0 d-flex justify-content-end"
						>
							<b-nav align="center" class="pr-4">
								<b-nav-item
									to="#"
									link-classes="px-sm-3 px-md-4 px-lg-4 regular-text"
									>{{ $t('pages.FAQ.Help') }}</b-nav-item
								>
								<b-nav-item
									class="nav-item__active"
									to="#"
									link-classes="px-sm-3 px-md-4 px-lg-4"
									>{{ $t('pages.FAQ.FAQ') }}</b-nav-item
								>
								<b-dropdown
									v-if="selectedLang"
									id="dropdown-1"
									class="mr-md-4"
									toggle-class="d-flex align-items-center flex-row bg-transparent border text-dark text-uppercase font-weight-bold"
								>
									<template #button-content>
										<span
											class="
												d-flex
												iti-flag
												flag-item
												mr-3
											"
											:class="[selectedLang.class]"
										></span>
										<span class="mr-2">
											{{ selectedLang.shortCode }}
										</span>
									</template>
									<b-dropdown-item
										v-for="lang in availableLocales"
										:key="`lang-${lang.code}`"
										href="javascript:void(0)"
										class="d-flex align-items-center py-2"
										@click.prevent.stop="
											handleSelectedLang(lang)
										"
									>
										<div
											class="
												d-flex
												justify-content-between
											"
										>
											<div
												:class="`iti-flag flag-item mr-1 ${lang.class}  shadow`"
											></div>
											{{ lang.label }}
										</div>
									</b-dropdown-item>
								</b-dropdown>
							</b-nav>
							<b-navbar-brand href="/"
								><b-img
									src="@/static/media/images/icon-home-orange.png"
									fluid
									alt="Fluid image"
								></b-img></b-navbar-brand></b-col
					></b-row>
				</b-container>
			</b-navbar>
		</header>
		<header class="d-block d-sm-none">
			<b-navbar toggleable="lg" variant="faded" type="light" class="p-4">
				<b-navbar-brand href="/"
					><b-img
						src="@/static/media/images/logo-hyperlive.svg"
						fluid
						alt="Fluid image"
						class="w-50"
					></b-img
				></b-navbar-brand>

				<div class="d-flex">
					<b-dropdown
						v-if="selectedLang"
						id="dropdown-1"
						class="mr-4"
						toggle-class="d-flex align-items-center flex-row bg-transparent border text-dark text-uppercase font-weight-bold"
					>
						<template #button-content>
							<span
								class="d-flex iti-flag flag-item mr-3"
								:class="[selectedLang.class]"
							></span>
							<span class="mr-2">
								{{ selectedLang.shortCode }}
							</span>
						</template>
						<b-dropdown-item
							v-for="lang in availableLocales"
							:key="`lang-${lang.code}`"
							href="javascript:void(0)"
							class="d-flex align-items-center py-2"
							@click.prevent.stop="handleSelectedLang(lang)"
						>
							<div class="d-flex justify-content-between">
								<div
									:class="`iti-flag flag-item mr-1 ${lang.class}  shadow`"
								></div>
								{{ lang.label }}
							</div>
						</b-dropdown-item>
					</b-dropdown>
					<b-navbar-brand href="/" class="mr-5"
						><b-img
							src="@/static/media/images/icon-home-orange.png"
							fluid
							alt="Fluid image"
						></b-img
					></b-navbar-brand>
					<b-navbar-toggle
						target="nav-collapse"
						class="p-2"
					></b-navbar-toggle>
				</div>

				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav class="text-right pt-3">
						<b-nav-item to="#">{{
							$t('pages.FAQ.Help')
						}}</b-nav-item>
						<b-nav-item to="#">{{
							$t('pages.FAQ.FAQ')
						}}</b-nav-item>
					</b-navbar-nav>
				</b-collapse>
			</b-navbar>
		</header>
	</div>
</template>

<script>
import i18nMixins from '@/mixins/i18nMixins'
export default {
	mixins: [i18nMixins],
}
</script>

<style lang="scss" scoped>
.faq__header {
	background-color: #eff3f6;
}

header {
	.navbar {
		.nav-link {
			color: #707070;
			font-size: 1.5rem;
			font-weight: 700;
			&.regular-text {
				font-weight: 500;
			}
		}
		.nav-item {
			margin: 0 1rem 0 1rem;
			line-height: 1.7;
			display: flex;
			align-items: center;
			a {
				width: 100%;
				text-align: right;
			}
			&__active {
				padding: 0 1rem 0 1rem;
				border-radius: 7px;
				box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.16);
				border: solid 1px #ff6d6a;
				background-color: #fff;
			}
			.nav-link {
				padding: 0;
			}
		}
		.navbar-brand {
			display: flex;
			align-items: center;
		}
	}
}
</style>
