/* eslint-disable camelcase */
import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
	required,
	confirmed,
	max,
	min,
	alpha_num,
	email,
} from 'vee-validate/dist/rules'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import moment from 'moment'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// Rules
export default function ({ app: { i18n } }) {
	// setInteractionMode("eager")

	extend('required', {
		...required,
		message: (fieldName) => {
			const localeUsing = i18n.locale
			if (
				i18n.te(
					`validations.messages.required.${localeUsing}.${fieldName}`
				)
			) {
				return i18n.t(
					`validations.messages.required.${localeUsing}.${fieldName}`
				)
			} else {
				return i18n.t('validations.messages.required.default', {
					fieldName,
				})
			}
		},
	})

	extend('isCheck', {
		validate(val) {
			return !!val
		},
		message: (fieldName) => {
			return i18n.t('validations.messages.required', { fieldName })
		},
	})

	extend('confirmed', {
		...confirmed,
		message: (fieldName) => {
			const localeUsing = i18n.locale
			if (
				i18n.te(
					`validations.messages.confirmed.${localeUsing}.${fieldName}`
				)
			) {
				return i18n.t(
					`validations.messages.confirmed.${localeUsing}.${fieldName}`,
					{ length }
				)
			} else {
				return i18n.t('validations.messages.confirmed.default', {
					fieldName,
				})
			}
		},
	})

	extend('alpha_num', {
		...alpha_num,
		message: (fieldName) => {
			return i18n.t('validations.messages.alphaNum', { fieldName })
		},
	})

	extend('min', {
		...min,
		message: (fieldName, { length }) => {
			const localeUsing = i18n.locale
			if (
				i18n.te(`validations.messages.min.${localeUsing}.${fieldName}`)
			) {
				return i18n.t(
					`validations.messages.min.${localeUsing}.${fieldName}`,
					{ length }
				)
			} else {
				return i18n.t(`validations.messages.min.default`, {
					fieldName,
					length,
				})
			}
		},
	})

	extend('max', {
		...max,
		message: (fieldName, { length }) => {
			const localeUsing = i18n.locale
			if (
				i18n.te(`validations.messages.max.${localeUsing}.${fieldName}`)
			) {
				return i18n.t(
					`validations.messages.max.${localeUsing}.${fieldName}`,
					{ length }
				)
			} else {
				return i18n.t(`validations.messages.max.default`, {
					fieldName,
					length,
				})
			}
		},
	})

	extend('minmax', {
		validate(value, { min, max }) {
			return value.length >= min && value.length <= max
		},
		message: (fieldName, { min, max }) => {
			return i18n.t(`validations.messages.minmax`, {
				fieldName,
				min,
				max,
			})
		},
		params: ['min', 'max'],
	})

	extend('phone_number', {
		params: ['countryCode'],
		validate(value, { countryCode }) {
			const phoneNumber = parsePhoneNumberFromString(value, countryCode)
			return phoneNumber && phoneNumber.isValid()
		},
		message: () => {
			return i18n.t('validations.messages.phone_number')
		},
	})

	extend('email', {
		...email,
		message: () => {
			return i18n.t('validations.messages.email')
		},
	})

	extend('difference', {
		params: [
			{
				name: 'otherValue',
				isTarget: true,
			},
		],
		validate(value, { otherValue }) {
			return value !== otherValue
		},
		message: () => {
			return i18n.t('validations.messages.newPasswordSame')
		},
	})

	extend('date', {
		validate(val) {
			const date = moment(val, 'DD-MM-YYYY')
			return date.isValid()
		},
		message: (fieldName, { length }) => {
			const localeUsing = i18n.locale
			if (
				i18n.te(`validations.messages.date.${localeUsing}.${fieldName}`)
			) {
				return i18n.t(
					`validations.messages.date.${localeUsing}.${fieldName}`
				)
			} else {
				return i18n.t(`validations.messages.date.default`, {
					fieldName,
				})
			}
		},
	})
}
